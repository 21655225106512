<template>
  <div v-loading="loading">
    <div id="container" hidden></div>
    <div class="header">
      <div style="width: 500px">
        <div style="margin: 0 60px; display: inline-block">
          <img
            src="@/assets/SlnDesign/success.png"
            alt=""
            style="width: 120px"
          />
        </div>
        <div style="display: inline-block">
          <span style="line-height: 24px; display: block">方案生成成功！</span>
          <span style="line-height: 24px; display: block">{{
            orderData.name
          }}</span>
          <span style="line-height: 24px; display: block">{{
            orderData.updateTime
          }}</span>
        </div>
      </div>

      <div style="margin-right: 60px">
        <el-dropdown
          @command="handleCommand($event)"
          style="margin-right: 10px"
        >
          <el-button size="mini" type="primary"
            >下载方案<i class="el-icon-arrow-down el-icon--right"></i
          ></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="PDF">PDF</el-dropdown-item>
            <el-dropdown-item command="Excel">Excel</el-dropdown-item>
            <el-dropdown-item command="PPT">PPT</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button size="mini" @click="pushEdit" icon="el-icon-edit"
          >编辑</el-button
        >
        <el-button size="mini" @click="backList" icon="el-icon-tickets"
          >返回列表</el-button
        >
      </div>
      <!--二维码部分-->
      <template>
        <div style="margin-right: 50px">
          <div @click="DownLoadPage" style="cursor:pointer;">
            <vue-qr
              :text="codeText"
              :size="100"
              :margin="10"
              colorDark="#333"
              :logoSrc="logoImg"
              :logoScale="0.21"
              logoMargin="25px"
              :callback="getCode"
            ></vue-qr>
          </div>
        </div>
      </template>
    </div>
    <div class="text">
      <el-scrollbar style="height: 70vh">
        <template v-if="roomProductList.length > 0">
          <div :key="index" v-for="(item, index) in roomProductList">
            <div :key="index" v-for="(element, index) in item.rooms">
              <p style="font-weight: bold; margin: 2%">
                {{ item.name }} {{ element.label }}
              </p>
              <el-table
                :data="element.children"
                border
                style="width: 96%; margin: 3vh auto"
              >
                <el-table-column label="商品名称" show-header>
                  <template slot-scope="{ row }">
                    <div style="display: flex; align-items: center">
                      <img
                        :src="row.productImage"
                        style="width: 42px; margin-right: 6px"
                        alt=""
                      />
                      <span style="font-size: 12px">{{ row.productName }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="productBrandName"
                  label="品牌"
                  width="150"
                >
                </el-table-column>

                <el-table-column prop="modelName" label="型号" width="150">
                </el-table-column>
                <el-table-column prop="productSpec" label="规格" width="100">
                </el-table-column>
                <el-table-column prop="productPrice" label="价格" width="100">
                </el-table-column>
                <el-table-column prop="productNumber" label="数量" width="100">
                </el-table-column>
                <el-table-column prop="allPrice" label="小计" width="100">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </template>
      </el-scrollbar>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="save_footer">
        <span style="margin-right: 20px">产品总额：{{ allproductPrice }}</span>
      </div>
    </div>
    <el-dialog
      title="点位图设计方案Pdf下载"
      :visible.sync="downLoadPdfDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadPdfDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="downLoadDragOrder"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="点位图设计方案Excel下载"
      :visible.sync="downLoadExcelDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadExcelDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="downLoadDragOrderExcel"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- <el-dialog
      title="点位图设计方案PPT下载"
      :visible.sync="downLoadPPTDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadPPTDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="downLoadDragOrderPPT"
          >确 定</el-button
        >
      </div>
    </el-dialog> -->
    <el-dialog
      title="点位图设计方案PPT下载"
      :visible.sync="downLoadPPTDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p> -->
      <p>请选择PPT模板（未选择则使用默认模板）</p>
      <div 
        style="margin-top:25px;display:flex;flex-wrap: wrap;max-width:520px;" 
        v-if="downLoadPPTDialogVisible" 
        v-loading="loading2">
        <div 
          v-for="(fileData, index) in uploadPPTUrlList" 
          :key="index" 
          @mouseover="hoverPPTIndex = index" 
          @mouseleave="hoverPPTIndex = null" 
          style="text-align:center;margin-left:20px;position:relative;">
          <img 
          v-if="selectedPPTIndex === index" 
          src="@/assets/drage_images/selected.png" 
          style="height:30px;position:absolute;right:0px;">
          <span 
            v-if="hoverPPTIndex === index&&fileData.userId!=0" 
            class="close-button" 
            @click="handleCloseClick(fileData.id)">
            <el-button 
            type="danger" 
            icon="el-icon-close" 
            circle 
            style="font-size:16px;padding:3px;"
            ></el-button>
          </span>
          <div 
            class="uploadlist_box" 
            @click="changeSelectPPT(fileData, index)" 
            :class="{ 'pptdata_changed': selectedPPTIndex === index }">
            <img v-if="index==0" src="@/assets/temp.png" alt="" style="height:140px;">
            <img v-else src="@/assets/ppt.png" alt="" style="height:140px;">
          </div>
          <p style="margin:15px;">{{fileData.pptName}}</p>
          <!-- <el-button type="" style="margin:15px;">预览</el-button> -->
        </div>
        <div class="uploadfile_box" @click="openUploadPPTDialog">
          <i slot="default" class="el-icon-plus" style="font-size:30px"></i>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadPPTDialogVisible = false"
          >取消</el-button
        >
        <!-- <el-button
          type="primary"
          @click="downLoadDragOrderPPT"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        > -->
        <el-button
          type="primary"
          @click="downLoadDragOrderPPT"
          >确 定</el-button
        >
      </div>
      <el-dialog
        width="600px"
        center
        :visible.sync="uploadPPTVisible"
        :append-to-body="true"
        title="上传PPT模板文件">
        <div>
          <span>上传PPT模板文件名：</span>
          <el-input v-model="pptFileName" placeholder="请输入模板文件名" style="width:300px;"></el-input>
        </div>
        

        <div style="margin-top:25px;">
          <!-- <input 
          ref="uploadppt" 
          type="file" 
          :accept="['.ppt', '.pptx'].join(',')"
          /> -->
          <input 
          ref="uploadppt" 
          style="display:none;"
          type="file" 
          :accept="['.pptx'].join(',')"
          @change="handleFileUpload"
          />
          <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="triggerFileInput"
            >上传PPT模板</el-button
          >
          <p v-if="uploadFileName==''">当前未上传文件</p>
          <p v-else>{{uploadFileName}}</p>
        </div>
        <div slot="footer">
          <el-button @click="uploadPPTVisible = false">取消</el-button>
          <el-button type="primary" @click="pptFileUpload">确认</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { Graph } from '@antv/x6'
import { Dnd } from '@antv/x6-plugin-dnd'
import { Transform } from '@antv/x6-plugin-transform'
import { Export } from '@antv/x6-plugin-export'
import {
  getFilterProduct,
  getProductById,
  getProducts,
  getAllBrand,
  getAllType,
} from '@/api/product.js'
import {
  getDragOrderById,
  DownLoadPDF,
  DownLoadExcel,
  DownLoadPPT,
  uploadpptFile,
  getpptFileList,
  delpptDataById,
} from '@/api/DragOrder/index'
export default {
  name: '',
  props: [''],
  data() {
    return {
      // fullscreenLoading: false,
      loading: true,
      orderData: {
        name: '',
        remark: '',
        floors: [],
      },
      floorList: [],
      roomProductList: [],
      proBrandOption: [],
      proTypeOption: [],
      allproductPrice: 0,

      nowSelectDragOrder: {},
      downLoadPdfDialogVisible: false,
      downLoadExcelDialogVisible: false,
      downLoadPPTDialogVisible: false,

      ImgBaseList: [],

      jsonData: null,
      graph: null,
      // dnd: null,
      parentNode: null,
      childNode: [],

      //生成二维码
      codeText: this.codeDownLoad(),
      logoImg: require('@/assets/SlnDesign/xueyi.png'),
      renderDonePromise: null,

      pptFileName: '',
      uploadPPTUrlList: [],
      uploadPPTVisible: false,
      nowSelectPPTData: {},
      selectedPPTIndex: null,
      uploadFileName: '',
      hoverPPTIndex: null,
      loading2: false,
    }
  },
  created() {
    this.getProductTypeList()
    this.getProductBrandList()
    this.getDragOrder()
    this.getpptFileListData()
  },

  components: {
    VueQr,
  },

  computed: {},

  beforeMount() {},

  mounted() {},

  methods: {
    async getDragOrder() {
      let id = this.$route.params.id
      let { data } = await getDragOrderById(id)
      console.log(1, data)
      this.orderData = data
      if (this.orderData.name != '') {
        this.floorList = [...this.orderData.floors]
        const promises = this.floorList.map(async (floor) => {
          const promises = floor.rooms.map(async (room) => {
            let prolist = []
            const promises = room.products.map(async (product) => {
              try {
                // console.log(122222,product)
                let id = product.productId
                let proData = {}
                let { data } = await getProductById(id)
                // console.log(data)
                proData = { ...product }
                // proData.id = parseInt(id)
                proData.productName = data.productName
                proData.modelName = proData.productModel
                proData.productBrandId = data.productBrandId
                proData.productTypeId = data.productTypeId
                proData.productBrandName = data.productBrandName
                proData.productTypeName = data.productTypeName
                // proData.thumbImage = data.thumbImage
                // proData.position = JSON.parse(product.cellId)
                // let pData = await getFilterProduct(data.productName)
                // console.log(145, proData);
                // 修改 item 对象的属性
                prolist.push(proData)
                // prolist.push(data);
              } catch (error) {
                console.error('Error fetching product:', error)
                this.$message.error(error)
                // 可以选择如何处理错误，例如记录日志或者抛出异常
              }
            })
            await Promise.all(promises)
            room.children = prolist
            room.label = room.name
            room.isRoot = true
          })
          await Promise.all(promises)
        })
        await Promise.all(promises)
        console.log(3, this.floorList)
        this.getProductList()
        this.loading = false
      }
    },
    //获取商品类型列表
    async getProductTypeList() {
      let { data } = await getAllType()
      // console.log('type', data)
      this.proTypeOption = data
    },
    //获取商品品牌列表
    async getProductBrandList() {
      let { data } = await getAllBrand()
      // console.log('brand', data)
      this.proBrandOption = data
    },

    getProductList() {
      this.roomProductList = []
      this.roomProductList = JSON.parse(JSON.stringify(this.floorList))
      this.allproductPrice = 0;
      this.roomProductList = this.roomProductList.map(floor=>{
        floor.rooms = floor.rooms.map(room=>{
          let newArrayList = [];
          room.children = room.children.map(child=>{
            let arrayData = {}
            if(newArrayList==[]){
              arrayData = { 
                ...child,
                allPrice: child.productNumber * child.productPrice
              }
              this.allproductPrice += arrayData.allPrice
              newArrayList.push(arrayData)
            } else{
              if(newArrayList.find(p=>p.productId===child.productId&&p.productSpec==child.productSpec)!=undefined){
                arrayData = newArrayList.find(p=>p.productId===child.productId);
                arrayData.productNumber += child.productNumber;
                arrayData.allPrice = arrayData.productNumber * arrayData.productPrice;
                this.allproductPrice += child.productNumber * child.productPrice;
              } else{
                arrayData = { 
                  ...child,
                  allPrice: child.productNumber * child.productPrice
                }
                // arrayData.number = arrayData.productNumber
                // arrayData.allPrice = arrayData.number * arrayData.productPrice
                this.allproductPrice += arrayData.allPrice
                newArrayList.push(arrayData)
              }
              
            }
            return child
          })
          room.children = newArrayList
          return room
        })
        return floor
      })
      console.log('roomProductList', this.roomProductList)
    },

    //选择下载PDF或者Excel
    handleCommand($event) {
      // console.log(scope)
      console.log($event)
      let id = this.$route.params.id
      if ($event == 'PDF') {
        this.openDownLoadPdfDialog(id)
      }
      if ($event == 'Excel') {
        console.log($event)
        this.openDownLoadExcelDialog(id)
      }
      if ($event == 'PPT') {
        console.log($event)
        this.openDownLoadPPTDialog(id)
      }
    },
    //打开确认下载弹出框
    async openDownLoadPdfDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadPdfDialogVisible = true
    },
    async openDownLoadExcelDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadExcelDialogVisible = true
    },
    async openDownLoadPPTDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadPPTDialogVisible = true
    },
    //生成ImgBaseList
    async getImgBaseList() {
      this.ImgBaseList = []
      try {
        if (this.nowSelectDragOrder.name !== '') {
          for (let floor of this.nowSelectDragOrder.floors) {
            this.nowSelectFloor = floor
            this.jsonData = JSON.parse(this.nowSelectFloor.jsonData)
            await this.init() // 确保 init 方法是异步的
            await this.renderDonePromise;
            // await new Promise((resolve) => setTimeout(resolve, 1000))
            const dataUri = await new Promise((resolve) => {
              this.graph.toPNG((dataUri) => {
                resolve(dataUri);
              });
            });
            let fileName =
              this.nowSelectDragOrder.name + '_' + this.nowSelectFloor.name
            console.log(dataUri)

            let data = {
              fileName: fileName,
              fileUrl: dataUri,
            }
            this.ImgBaseList.push(data)
            // this.graph.toSVG((dataUri) => {
            //   let fileName =
            //     this.nowSelectDragOrder.name + '_' + this.nowSelectFloor.name
            //   console.log(dataUri)

            //   let data = {
            //     fileName: fileName,
            //     fileUrl: dataUri,
            //   }
            //   this.ImgBaseList.push(data)
            // })
          }
        }
      } catch (error) {
        console.error('Error in openDownLoadPdfDialog:', error)
        // 可以在这里处理错误或者抛出异常
      }
    },
    //下载当前设计方案 Pdf
    async downLoadDragOrder() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadPDF(this.nowSelectDragOrder.id, this.ImgBaseList)
        .then((res) => {
          // 创建一个隐藏的<a>标签
          console.log(res)
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadPdfDialogVisible = false
    },
    //下载当前设计方案 Excel
    async downLoadDragOrderExcel() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadExcel(this.nowSelectDragOrder.id, this.ImgBaseList)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
          // this.downLoadExcelDialogVisible = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
        loading.close();
        this.downLoadExcelDialogVisible = false
    },
    // 下载当前设计方案 PPT
    async downLoadDragOrderPPT() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadPPT(this.nowSelectDragOrder.id, this.ImgBaseList, this.nowSelectPPTData)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
          // this.downLoadPPTDialogVisible = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadPPTDialogVisible = false
    },
    openUploadPPTDialog(){
      this.pptFileName = '';
      this.uploadPPTVisible = true;
    },
    async pptFileUpload(){
      // const uploadComponent = this.$refs.upload;
      if(this.pptFileName==''){
        this.$message.error("请输入PPT模板文件名")
        return
      }
      const file = this.$refs.uploadppt.files[0];
      if (file && file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('newFileName', this.pptFileName);

        let { data } = await uploadpptFile(formData)
        console.log("上传后返回数据", data)
        if(data==''){
          this.$message.error("上传PPT模板文件失败")
          return
        }
        this.getpptFileListData();
        this.$refs.uploadppt.value = "";
        this.uploadFileName = '';
        this.uploadPPTVisible = false;
        // this.uploadPPTUrl = data;
      } else {
        this.$message.error("请上传pptx文件！")
      }
    },
    async getpptFileListData(){
      let { data } = await getpptFileList();
      console.log('获取当前用户上传的文件数据', data)
      this.uploadPPTUrlList = data;
      this.nowSelectPPTData = this.uploadPPTUrlList[0];
      this.selectedPPTIndex = 0;
    },
    changeSelectPPT(pptData, index){
      console.log("当前选中pptData", pptData.id)
      console.log("当前nowSelectPPTData", this.nowSelectPPTData.id)
      if(this.nowSelectPPTData.id == pptData.id){
        this.nowSelectPPTData = {};
        this.selectedPPTIndex = null;
        return
      }
      this.nowSelectPPTData = pptData;
      console.log("nowSelectPPTData", this.nowSelectPPTData)
      this.selectedPPTIndex = index;
    },
    triggerFileInput() {
      this.$refs.uploadppt.click();
    },
    handleFileUpload(){
      const selectedFile = this.$refs.uploadppt.files[0]
      this.uploadFileName = selectedFile.name
      console.log("Selected File:", this.uploadFileName);
    },
    handleCloseClick(id) {
      // 在这里处理点击关闭按钮时的事件
      console.log("Close button clicked", id);
      this.$confirm('当前操作将会删除当前PPT模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        this.loading2 = true
        await delpptDataById(id)
        this.$message({
          type: 'success',
          message: '删除成功!',
        })
        this.getpptFileListData()
        this.loading2 = false
      })
      .catch((error) => {
        this.$message({
          type: 'info',
          message: '删除失败',
        })
        this.loading2 = false
      })
    },
    //二维码
    getCode(codeImg) {
      console.log('二维码图片', codeImg)
    },
    async init() {
      this.graph = new Graph({
        container: document.getElementById('container'),
        autoResize: true,
        panning: true,
        mousewheel: true,
        background: {
          color: '#F2F7FA',
        },
        grid: {
          visible: true,
          type: 'doubleMesh',
          args: [
            {
              color: '#eee', // 主网格线颜色
              thickness: 1, // 主网格线宽度
            },
            {
              color: '#ddd', // 次网格线颜色
              thickness: 1, // 次网格线宽度
              factor: 4, // 主次网格线间隔
            },
          ],
        },
        translating: {
          restrict(view) {
            if (view) {
              const cell = view.cell
              if (cell.isNode()) {
                const parent = cell.getParent()
                if (parent) {
                  return parent.getBBox()
                }
              }
            }
          },
        },
      })

      const resizingOptions = {
        enabled: true,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      const rotatingOptions = {
        enabled: false,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      this.graph
      .use(
        new Transform({
          resizing: resizingOptions,
          rotating: rotatingOptions,
        })
      )
      // 导出插件
      .use(new Export())

      var img = new Image()
      img.src = this.nowSelectFloor.fileUrl

      await new Promise((resovle, reject) => {
        img.onload = () => {
          resovle()
        }
      })

      if (this.jsonData != {} && this.jsonData.cells != undefined) {
        this.graph.clearCells()
        this.graph.off('render:done'); // 移除旧的监听器
        this.graph.fromJSON(this.jsonData)
        this.renderDonePromise = new Promise((resolve, reject) => {
          this.graph.on('render:done', () => {
            // 当事件触发时，调用 resolve
            console.log('渲染完成');
            resolve(true);
          });
        });

        this.parentNode = this.graph.getNodes()[0]
        // console.log('1212parentNode', this.graph.toJSON())
        let childCells = []
        let cellList = this.graph.toJSON()
        cellList.cells.forEach((cell) => {
          if (cell.zIndex != 1) {
            childCells.push(cell)
          }
        })
        this.childNode = childCells
      }
      // 获取所有节点
      const nodes = this.graph.getNodes()

      // 遍历所有节点，移除工具
      nodes.forEach((node) => {
        node.removeTools() // 移除所有工具
      })
    },
    // 前往编辑页面
    pushEdit() {
      let id = this.$route.params.id
      // this.$router.push({ path: '/Solution/dragOrderEdit/' + id })
      this.$router.push({ path: '/dragOrderEdit/' + id })
    },
    backList() {
      this.$router.push({ path: '/Solution/DesignIndex' })
    },
    codeDownLoad() {
      let id = this.$route.params.id
      let nowRouter = process.env.VUE_APP_Code_URL
      console.log("nowRouter", nowRouter)
      let codeRouter = nowRouter + '/#/QrCode/' + id
      // let codeRouter = nowRouter + '/#/QrCode';
      // console.log("codeRouter", codeRouter)
      return codeRouter
    },
    DownLoadPage(){
      let id = this.$route.params.id
      this.$router.push({ path: '/QrCode/' + id })
    }
  },

  watch: {},
}
</script>
<style lang="scss" scoped>
@import '@/styles/dragorder.scss';
.header {
  background-color: white;
  width: 80%;
  height: 20vh;
  margin-bottom: 3vh;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close-button{
  position:absolute;
  top: -10px;
  right: -10px;
}
.uploadlist_box{
  width:140px;
  height:140px;
  border: 1px solid #DDDDDD;
  border-radius:10px;
  display:flex;
  align-items:center;
  overflow: hidden;
  &:hover{
    cursor:pointer;
    border-color: #8c50ff;
  }
}
.pptdata_changed {
  /* 点击后的样式 */
  border: 2px solid #8c50ff;
}
.uploadfile_box{
  width:140px;
  height:140px;
  margin-left: 20px;
  border: 1px dashed #DDDDDD;
  border-radius:10px;
  display:flex;
  justify-content:center;
  align-items:center;
  &:hover{
    cursor:pointer;
    border-color: #8c50ff;
  }
}
.text {
  background-color: white;
  width: 80%;
  height: 70vh;
  border-radius: 10px;
}
.save_footer {
  width: 77%;
  margin-top: 2vh;
  margin-bottom: 1vh;
  border-radius: 15px;
  background: #ffffff;
  padding: 16px;
  // padding-right:32px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
