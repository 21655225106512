<template>
  <el-menu
    :collapse="!fold"
    :default-active="activeMenu"
    :background-color="variables.menuBg"
    :text-color="variables.menuText"
    :active-text-color="variables.menuActiveText"
    :unique-opened="true"
    :collapse-transition="false"
    mode="vertical"
  >
    <sidebar-item
      v-for="route in routes"
      :key="route.path"
      :item="route"
      :base-path="route.path"
    />
  </el-menu>
</template>
<script>
import { staticRoutes } from '@/router'
import variables from '@/styles/variables.scss'
import SidebarItem from './SidebarItem'
import { mapGetters } from 'vuex'

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(['routes', 'fold']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables() {
      return variables
    },
  },
}
</script>
<style lang="scss" scoped>
/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none !important;
}

::v-deep .el-menu--vertical .sub-el-icon {
  margin-right: 5px !important;
  margin-left:0px !important;
  font-size: 21px !important;
}
</style>
