<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []

    if (icon) {
      if (icon.includes('el-icon')) {
        vnodes.push(<i class={[icon, 'sub-el-icon']} />)
      } else {
        vnodes.push(<svg-icon icon-class={icon} />)
      }
    }

    if (title) {
      vnodes.push(
        <span slot="title" v-show="!fold">
          {title}
        </span>
      )
    }
    return vnodes
  },
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  /* width: 1em; */
  height: 1em;
  margin-right: 15px !important;
  margin-left:10px !important;
  width:24px !important;
  font-size: 21px !important;
}

.el-menu--collapse .sub-el-icon {
  margin-right: 5px !important;
  margin-left:0px !important;
  width:21px !important;
}

/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
/deep/ .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none !important;
}
</style>
