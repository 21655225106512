import request from '@/utils/request'

export function getWorkList (data) {
  return request({
    url: '/api/workManage/WorkList',
    method: 'post',
    data
  })
}

export function getWorkListCount () {
  return request({
    url: '/api/workManage/AllWorkListCount',
    method: 'get'
  })
}

export function getWorkDataById (id) {
  return request({
    url: '/api/workManage/GetWorkById',
    method: 'get',
    params: {
      id
    }
  })
}

export function getWorkProjectList () {
  return request({
    url: '/api/workManage/WorkProjectList',
    method: 'get'
  })
}

export function createNewWork (data) {
  return request({
    url: '/api/workManage/CreateWork',
    method: 'post',
    data
  })
}

export function createNewProject (data) {
  console.log(data.projectName)
    return request({
      url: '/api/workManage/CreateProject',
      method: 'post',
      data
    })
  }

export function EditWork (data) {
  return request({
    url: '/api/workManage/EditWork',
    method: 'post',
    data
  })
}

export function DeleteWork (id) {
  return request({
    url: '/api/workManage/DeleteWorkById',
    method: 'get',
    params: {
      id
    }
  })
}

export function GetProcessData (id) {
  return request({
    url: '/api/workManage/GetProcessById',
    method: 'get',
    params: {
      id
    }
  })
}

export function AddProcess (data) {
  return request({
    url: '/api/workManage/CreateProcess',
    method: 'post',
    data
  })
}

export function GetReviewData (id) {
  return request({
    url: '/api/workManage/GetReviewById',
    method: 'get',
    params: {
      id
    }
  })
}

export function AddReview (data) {
  return request({
    url: '/api/workManage/CreateReview',
    method: 'post',
    data
  })
}

export function addWorkManageLog (data) {
  return request({
    url: '/api/workManage/CreateWorkManageLog',
    method: 'post',
    data
  })
}

export function getWorkLogList (id) {
  return request({
    url: '/api/workManage/GetWorkLogListById',
    method: 'get',
    params: {
      id
    }
  })
}

