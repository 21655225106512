import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from './utils/auth'
import store from './store'
import { Message } from 'element-ui'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', 'QrCode', 'UserReturnInfo']

router.beforeEach(async (to, from, next) => {
  // console.log(to.path)
  NProgress.start()

  document.title = '雪意-' + to.meta.title

  const hasToken = getToken()

  if (hasToken) {
    // 如果token存在并且数据正确则直接跳转到主页
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasRoles = store.getters.name && store.getters.roles

      if (hasRoles) {
        next()
      } else {
        try {
          // 如果用户信息不存在则获取用户信息
          const data = await store.dispatch('user/getInfo')

          // console.log(data)

          // 获取用户权限表
          const addRoutes = await store.dispatch(
            'permission/generateRoutes',
            data
          )

          console.log("addRoutes", addRoutes)

          router.addRoutes(addRoutes)
          //let routes = store.getters.routes
          next({ ...to, replace: true })
        } catch (error) {
          // 如果获取失败则清除
          await store.dispatch('user/resetToken')

          Message.error(error || 'Has Error')

          next(`/login?redirect=${to.path}`)

          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || whiteList.indexOf(to.name) != -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
