<template>
  <div>
    <div class="header">
      <el-form size="mini" style="width:120vh;height:30px;">
        <el-row>
          <el-col :span="6">
            <el-form-item label="所属项目">
              <el-select v-model="projectValue" disabled placeholder="请选择" style="width:16vh;" size="mini">
                <div style="width:100%;display:flex;align-items:center;justify-content:center;margin-bottom:5px;">
                  <el-button icon="el-icon-plus" @click="openCreateProjectDialog">新建项目</el-button>
                </div>
                <el-option
                  v-for="item in projectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="处理人">
              <template>
                <el-select
                  v-model="puserValue"
                  filterable
                  disabled
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="premoteMethod"
                  :loading="loading"
                  style="width:16vh;" size="mini">
                  <el-option
                    v-for="item in puserOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="优先级">
              <el-select v-model="levelValue" disabled placeholder="请选择" style="width:16vh;" size="mini">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核人">
              <template>
                <el-select
                  v-model="userValue"
                  filterable
                  disabled
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  style="width:16vh;" size="mini">
                  <el-option
                    v-for="item in userOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
          
        </el-row>
      </el-form>
      <div>
        <el-button class="rev_btn" @click="openReviewDialog">审核任务</el-button>
        <el-button class="del_btn" @click="deleteWorkManage">删除任务</el-button>
      </div>
      <!-- <el-button type="primary" @click="returnListPage">完成任务</el-button> -->
    </div>
    <div class="main_box">
      <div class="edit_box">
        <QuillEditor 
          ref="quillEditor" 
          @textChange="editorTextChange" 
          @htmlChange="editorHtmlChange" 
          :oldContent="jsonData" 
          style="min-height:460px;"/>
      </div>
      <div class="right_box">
        <!--工作任务进度-->
        <!-- <div></div> -->
        <!--审核详情-->
        <div class="top_box">
          <el-scrollbar style="height:100%">
            <div class="pro_box" v-for="message in messageDataList" :key="message.id">
              <div class="rev_box" v-if="message.userId==workData.userId" style="display:flex;justify-content:space-between">
                <el-tag type="warning" size="small">审核评论</el-tag>
                <span>{{workData.userName}}</span>
              </div>
              <div v-else-if="message.userId==workData.puserId">
                <span>{{workData.puserName}}</span>
              </div>
              <div v-else>
                <span>{{message.userId}}</span>
              </div>
              <div class="pv_box">{{message.text}}</div>
            </div>
          </el-scrollbar>
        </div>
        <!--发送评论-->
        <div class="pro_box bot_box">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="textarea"
            >
          </el-input>
          <el-button type="primary" size="mini" style="margin-top:2vh" @click="addReviewText">发送评论</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="新建项目"
      :visible.sync="createProjectState"
      width="30%">
      <el-input v-model="newProjectName" placeholder="新建项目名"></el-input>
      <el-checkbox v-model="isChecked">创建后自动选择</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createProjectState = false">取 消</el-button>
        <el-button type="primary" @click="addNewProject()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="审核任务"
      :visible.sync="reviewDialogVisible"
      width="30%">
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入内容"
        v-model="reviewText"
        >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="del_btn" @click="addWorkReviewLog(false)">未通过</el-button>
        <el-button style="border-radius: 6px;" type="primary" @click="addWorkReviewLog(true)">通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import QuillEditor from '@/components/Editor/index.vue'
  import { 
    getWorkProjectList, 
    getWorkDataById, 
    createNewProject,
    GetProcessData, 
    GetReviewData,
    AddReview,
    addWorkManageLog,
  } from '../../api/workManage'
  import { getUserOptions } from '../../api/user'
  export default {
    name:'',
    props:[''],
    data () {
      return {
        loading: false,
        newWorkForm: {
          jsonData: '',
          workLevel: 0,
          projectId: null,
          textData: '',
          userId: 0,
          puserId: 0,
        },
        levelOptions: [{
          value: 1,
          label: '低'
        }, {
          value: 2,
          label: '中'
        }, {
          value: 3,
          label: '高'
        }],
        workData: {},
        jsonData: '',

        projectOptions: [],
        puserOptions: [],
        userOptions: [],

        levelValue: '',
        userValue: '',
        puserValue: '',
        projectValue: '',

        newProjectName: '',
        isChecked: false,
        createProjectState: false,

        editorHtml: null,
        editorText: null,

        textarea: '',

        reviewDialogVisible: false,
        reviewText: '',
        processDataList: [],
        reviewDataList: [],
        messageDataList: [],
      };
    },

    components: {
      QuillEditor
    },

    created(){
      
    },

    computed: {},

    beforeMount() {},

    mounted() {
      this.getWorkData();
      this.getWorkProjectOptions();
      this.getMessageList()
      this.$refs.quillEditor.toggleEdit();
    },

    methods: {
      async getWorkData(){
        let id = this.$route.params.id
        let { data } = await getWorkDataById(id)
        console.log(data)
        this.workData = data;
        this.jsonData = this.workData.jsonData;
        console.log(this.jsonData)
        this.projectValue = this.workData.ProjectId;
        if(this.workData.puserId!=null&&this.workData.puserName!=null){
          let puserData = {};
          puserData.value = this.workData.puserId;
          puserData.label = this.workData.puserName;
          this.puserOptions.push(puserData)
        }
        if(this.workData.userId!=null&&this.workData.userName!=null){
          let userData = {};
          userData.value = this.workData.userId;
          userData.label = this.workData.userName;
          this.userOptions.push(userData)
        }
        this.projectValue = this.workData.projectId;
        this.levelValue = this.workData.workLevel;
        this.userValue = this.workData.userId;
        this.puserValue = this.workData.puserId;
      },
      // 获取工作项目列表
      async getWorkProjectOptions(){
        this.projectOptions = [];
        let { data } = await getWorkProjectList();
        // console.log(data)
        data.forEach(element => {
          let projectData = {};
          projectData.value = element.id;
          projectData.label = element.projectName;
          this.projectOptions.push(projectData);
        });
        // console.log('this.projectOptions', this.projectOptions)
        // console.log(this.isChecked)
        let pvalue = sessionStorage.getItem("projectValue");
        if(pvalue!=null){
          this.projectValue = parseInt(pvalue)
        }
        if(this.isChecked){
          this.projectValue = this.projectOptions[this.projectOptions.length-1].value;
          // console.log(this.projectValue)
        }
      },
      async getMessageList(){
        this.messageDataList = [];
        let id = this.$route.params.id;

        this.processDataList = [];
        this.reviewDataList = [];

        let { data: processData } = await GetProcessData(id);
        console.log("getProcessList", processData)
        this.processDataList = processData;
        let { data: reviewData } = await GetReviewData(id);
        console.log("getReviewList", reviewData)
        this.reviewDataList = reviewData;
        let combinedDataList = processData.map(item=>{
          return {
            id: item.id,
            userId: item.userId,
            text: item.processText,
            createTime: item.createTime,
          }
        }).concat(reviewData.map(item=>{
          return {
            id: item.id,
            userId: item.userId,
            text: item.reviewText,
            createTime: item.createTime,
          }
        }));
        
        this.messageDataList = combinedDataList.sort((a ,b)=>new Date(a.createTime)-new Date(b.createTime));
        console.log("this.messageDataList", this.messageDataList)
      },
      // // 获取处理评论列表
      // async getProcessList(){
      //   this.processDataList = [];
      //   let id = this.$route.params.id;
      //   let { data } = await GetProcessData(id);
      //   console.log("getProcessList", data)
      //   this.processDataList = data;
      // },
      // // 获取审核评论列表
      // async getReviewList(){
      //   this.reviewDataList = [];
      //   let id = this.$route.params.id;
      //   let { data } = await GetReviewData(id);
      //   console.log("getReviewList", data)
      //   this.reviewDataList = data;
      // },
      editorTextChange(data){
        console.log('editorTextChange',data)
        this.editorText = data;
        // this.editorText = JSON.stringify(data);
        console.log('editorText',this.editorText)

      },
      editorHtmlChange(data){
        console.log('editorHtmlChange',data)
        this.editorHtml = data;
        // this.editorHtml = JSON.stringify(data);
        console.log('editorHtml',this.editorHtml)
      },

      // 获取到查询的处理人列表
      async getpOptions(query){
        console.log(111111111)
        this.pUserOptions = [];
        // let key = '';
        let key = query;
        let { data } = await getUserOptions(key);
        console.log('getpOptions', data)
        data.forEach(element => {
          let pUserData = {};
          pUserData.value = element.id;
          pUserData.label = element.userName;
          this.puserOptions.push(pUserData);
        });
        console.log('this.puserOptions', this.puserOptions)
      },
      // 获取到查询的审核人列表
      async getOptions(query){
        console.log(111111111)
        this.userOptions = [];
        // let key = '';
        let key = query;
        console.log('key', key)
        let { data } = await getUserOptions(key);
        console.log('getOptions', data)
        data.forEach(element => {
          let userData = {};
          userData.value = element.id;
          userData.label = element.userName;
          this.userOptions.push(userData);
        });
        console.log('this.userOptions', this.userOptions)
      },
      // 查询处理人
      premoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          this.getpOptions(query).then(()=>{
            this.loading = false;
            this.options = this.puserOptions.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          })
        } else {
          this.options = [];
        }
      },
      // 查询审核人
      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          this.getOptions(query).then(()=>{
            this.loading = false;
            this.options = this.userOptions.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          })
        } else {
          this.options = [];
        }
      },
      // 添加新项目
      async addNewProject(){
        console.log('创建新项目', this.newProjectName)
        if(this.newProjectName==''){
          this.$message.error('新项目的名字不能为空')
          return
        }
        let data = {
          projectName: this.newProjectName
        }
        await createNewProject(data)
        this.getWorkProjectOptions();
        this.createProjectState = false;
      },
      // 打开添加新项目弹框
      openCreateProjectDialog(){
        this.isChecked = false;
        this.newProjectName = '';
        this.createProjectState = true;
      },
      // 打开审核任务弹框
      openReviewDialog(){
        this.reviewDialogVisible = true;
        
      },
      // 添加审核信息
      async addWorkReviewLog(state){
        console.log(state)
        console.log(this.reviewText)
        let tempReviewData = {
          workManageId: this.$route.params.id,
          ReviewText: this.reviewText
        }
        let tempWorkState = 2;
        if(state){
          tempWorkState = 4;
        }
        let data = {
          workManageId: this.$route.params.id,
          WorkState: tempWorkState,
          ReviewData: tempReviewData
        }
        await addWorkManageLog(data)
        this.reviewDialogVisible = false;
        this.$router.push({path: '/workManageMent/index'})
      },
      // 返回工作任务列表页
      returnListPage(){
        this.$router.push({path: '/workManageMent/index'})
      },
      //添加审核评论
      async addReviewText(){
        try{
          console.log(this.textarea)
          let data = {
            workManageId: this.$route.params.id,
            reviewText: this.textarea
          }
          console.log(data);
          await AddReview(data);
          await this.getMessageList();
          this.textarea = '';
          this.$message.success('评论成功!')
        } catch(error) {
          console.error("发生错误:", error);
          this.$message.error('评论失败');
        }
        
      },
      deleteWorkManage(){
        // console.log(id)
        // await DeleteWork(id)
        let id = this.$route.params.id;
        this.$confirm('是否确认删除当前工作任务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        })
        .then(async () => {
          await DeleteWork(id);
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.$router.push({path: '/workManageMent/index'})
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
      },
    },

    watch: {}

  }

</script>
<style lang='scss' scoped>
@import '@/styles/dragorder.scss';
.header{
  background-color:#ffffff;
  height:10vh;
  border-radius:10px;
  margin-bottom:20px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rev_btn{
    background-color: orange !important;
    color: #ffffff;
    border-color: rgb(230, 154, 15);
    border-radius: 6px;
  }
}
.del_btn{
  background-color: #FF3B3B !important;
  color: #ffffff;
  border-color: #ee2e2e;
  border-radius: 6px;
}
.main_box{
  width:100%;
  min-height: 72vh;
  display: flex;
  justify-content: space-between;
  // height:72vh;
  .edit_box{
    width:78.5%;
    background-color:#ffffff;
    border-radius:10px;
  }
  .right_box{
    width:20%;
    border-radius:10px;
    background-color:#ffffff;
    position: relative;
    .rev_box{
      display: flex;
      justify-content: space-between;
    }
    .pv_box{
      // width:84%;
      margin: 2vh 0;
      min-height:10vh;
      background-color:#F8F8F8;
      border: 1px solid #DDDDDD;
      padding: 8px;
      border-radius: 10px;
    }
    .pro_box{
      width:84%;
      // display: flex;
      margin: 0vh auto;
      margin-top: 3vh;
    }
    .top_box{
      position: absolute;
      top: 2vh;
      height: 42vh;
      width: 100%;
    }
    .bot_box{
      position: absolute;
      bottom: 3vh;
      left: 50%;
      transform: translate(-50%,0);
    }
  }
}
</style>