<template>
  <div>
    <div id="container" hidden></div>
    <div
      style="
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      "
      v-if="!downLoadExcelDialogVisible"
    >
      <div style="display: inline-block; text-align: center">
        <img
          src="@/assets/SlnDesign/success.png"
          alt=""
          style="width: 120px; margin-bottom: 3vh"
        />
        <span style="line-height: 5vh; display: block">方案下载成功！</span>
        <span style="line-height: 5vh; display: block">{{
          nowSelectDragOrder.name
        }}</span>
        <span style="line-height: 5vh; display: block; margin-bottom: 30vh">{{
          nowSelectDragOrder.updateTime
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Graph } from '@antv/x6'
import { Transform } from '@antv/x6-plugin-transform'
import { Export } from '@antv/x6-plugin-export'
import { codeGetDragOrderById, DownLoadExcel } from '@/api/DragOrder/index'
export default {
  name: '',
  props: [''],
  data() {
    return {
      downLoadExcelDialogVisible: true,
      nowSelectDragOrder: {},

      ImgBaseList: [],

      jsonData: null,
      graph: null,
      parentNode: null,
      childNode: [],
      renderDonePromise: null,
    }
  },
  mounted() {
    this.init()
    this.getDragOrder().then(() => {
      this.downLoadDragOrderExcel()
    })
  },
  methods: {
    async getDragOrder() {
      let id = this.$route.params.id
      let { data } = await codeGetDragOrderById(id)
      console.log(1234, data)
      this.nowSelectDragOrder = data
      this.downLoadExcelDialogVisible = true
    },
    //下载当前设计方案 Excel
    async downLoadDragOrderExcel() {
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)',
      })
      let id = this.$route.params.id
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadExcel(id, this.ImgBaseList)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      loading.close()
      this.downLoadExcelDialogVisible = false
    },
    //生成ImgBaseList
    async getImgBaseList() {
      this.ImgBaseList = []
      try {
        if (this.nowSelectDragOrder.name !== '') {
          for (let floor of this.nowSelectDragOrder.floors) {
            this.nowSelectFloor = floor
            this.jsonData = JSON.parse(this.nowSelectFloor.jsonData)
            this.graph.clearCells()
            this.graph.off('render:done') // 移除旧的监听器
            let isLoaded = false

            this.graph.on('render:done', () => {
              console.log('渲染完成')
              isLoaded = true
            })

            let imageLoadingPromise = this.jsonData.cells.map((cell) => {
              if (cell.shape === 'image') {
                return new Promise((resolve) => {
                  const img = new Image()
                  img.onload = () => {
                    resolve()
                  }
                  img.src = cell.attrs.image['xlink:href']
                })
              } else {
                return Promise.resolve()
              }
            })

            await Promise.all(imageLoadingPromise)

            console.log("图片预加载完成")


            this.graph.fromJSON(this.jsonData)

            while (!isLoaded) {
              console.log(isLoaded)
              await new Promise((resolve) => {
                setTimeout(() => {
                  resolve()
                }, 1000)
              })
            }
            let dataUri = await new Promise((resolve) => {
              this.graph.toPNG((dataUri) => {
                resolve(dataUri)
              })
            })
            let fileName =
              this.nowSelectDragOrder.name + '_' + this.nowSelectFloor.name
            // console.log(dataUri)

            let data = {
              fileName: fileName,
              fileUrl: dataUri,
            }
            this.ImgBaseList.push(data)
          }
        }
      } catch (error) {
        console.error('Error in openDownLoadPdfDialog:', error)
        // 可以在这里处理错误或者抛出异常
      }
    },
    async init() {
      if (this.graph) {
        this.graph.dispose()
        this.graph = null
      }
      this.graph = new Graph({
        container: document.getElementById('container'),
        autoResize: true,
        panning: true,
        mousewheel: true,
        background: {
          color: '#F2F7FA',
        },
        grid: {
          visible: true,
          type: 'doubleMesh',
          args: [
            {
              color: '#eee', // 主网格线颜色
              thickness: 1, // 主网格线宽度
            },
            {
              color: '#ddd', // 次网格线颜色
              thickness: 1, // 次网格线宽度
              factor: 4, // 主次网格线间隔
            },
          ],
        },
        translating: {
          restrict(view) {
            if (view) {
              const cell = view.cell
              if (cell.isNode()) {
                const parent = cell.getParent()
                if (parent) {
                  return parent.getBBox()
                }
              }
            }
          },
        },
      })

      const resizingOptions = {
        enabled: true,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      const rotatingOptions = {
        enabled: false,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      this.graph
        .use(
          new Transform({
            resizing: resizingOptions,
            rotating: rotatingOptions,
          })
        )
        .use(new Export())
    },
  },

  watch: {},
}
</script>
<style lang="" scoped></style>
