<template>
  <el-container class="index_box">
    <el-aside :width="fold ? '200px' : '64px'">
      <template :v-show="fold">
        <router-link to="/Home" class="brand_logo" :style="fold ? 'font-size: 24px;' : 'font-size: 14px;'"
          >Snowyi</router-link
        >
      </template>
      <el-scrollbar style="height: calc(100% - 80px)">
        <sidebar />
      </el-scrollbar>
    </el-aside>
    <el-container class="router_box">
      <el-header>
        <el-button
          icon="el-icon-s-fold"
          @click="handleToggle"
          type="text"
          style="margin-left: -20px; font-size: 20px; color: black"
        >
        </el-button>
        <!--         
        <el-input
          placeholder="搜索关键字"
          prefix-icon="el-icon-search"
          v-model="searchInput"
          style="width: 437px"
        >
        </el-input> -->
        <div class="user_icon_box">
          <el-avatar
            :size="38"
            style="margin-right: 20px"
            :src="headImage"
          ></el-avatar>
          <el-dropdown trigger="hover">
            <span class="el-dropdown-link">
              {{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>个人信息</el-dropdown-item>
              <el-dropdown-item>修改密码</el-dropdown-item>
              <el-dropdown-item>修改头像</el-dropdown-item> -->
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <router-view ref="child_router_view" />
      </el-main>
    </el-container>
    <!-- 在线聊天对话框 -->
    <ChatButton v-show="false" @OtherEvent="chartDeleget" />
    <!-- <el-main>
      <router-view />
    </el-main> -->
  </el-container>
</template>

<script>
import { default as Sidebar } from './components/Sidebar/index.vue'
import { default as ChatButton } from '@/components/Chat/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { Sidebar, ChatButton },
  data() {
    return {
      searchInput: '',
    }
  },
  methods: {
    getUserInfo() {},
    chartDeleget() {
      this.$refs['child_router_view'].getTableData()
    },
    handleToggle() {
      this.$store.dispatch('user/toggleFold')
    },
  },
  computed: {
    ...mapGetters(['fold']),
    name() {
      return this.$store.getters.name
    },
    headImage() {
      return 'data:image/gif;base64,' + this.$store.getters.headImage
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.index_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.user_icon_box {
  display: flex;
  align-items: center;
}

.router_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //width: 100%;
}

.brand_logo {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-style: oblique;
  color: #ffffff;
  line-height: 30px;
  margin-top: 25px;
  margin-bottom: 5px;
  cursor: pointer;
}

.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eff3fb;
  text-align: center;
  padding: 0 40px;
  box-sizing: border-box;
}

.el-aside {
  background-color: #191132;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eff3fb;
  color: #333;
  //text-align: center;
  // line-height: 160px;
}

/*
菜单部分
*/
.el-dropdown-link {
  cursor: pointer;
  color: #4d4d4d;
  text-align: center;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.el-dropdown-menu__item {
  text-align: center;
}

.el-menu-item {
  font-size: 16px;
  font-weight: 400;
  color: #c1bebe;
  // line-height: 24px;
  border-right: none;
  i {
    font-size: 23px;
  }
  span {
    padding-left: 10px;
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none !important;
}
</style>
