import request from '@/utils/request'

/**
 * 获取软件版本信息
 * @param {Object} data
 * @returns
 */
export function getAll (data) {
  return request({
    url: '/api/SoftVersion/GetSoftList',
    method: 'post',
    data,
  })
}

/**
 * 更新软件信息
 * @param {Object} data
 * @returns
 */
export function AddNew (data) {
  return request({
    url: '/api/SoftVersion/AddNew',
    method: 'post',
    data,
  })
}

/**
 * 获取版本更新情况
 * @param {String} versionCode
 * @returns
 */
export function GetInfo (versionCode) {
  return request({
    url: '/api/SoftVersion/GetInfo',
    method: 'get',
    params: { versionCode },
  })
}

/**
 * 获取奶茶版本信息
 * @returns
 */
export function getMilkTeaVersion () {
  return request({
    url: '/api/SoftVersion/GetMilKTea',
    method: 'get',
  })
}

/**
 * 获取咖啡机版本信息
 * @returns
 */
export function getCoffeeVersion () {
  return request({
    url: '/api/SoftVersion/GetCoffee',
    method: 'get',
  })
}

/**
 * 获取电视机软件版本信息
 */
export function getSnowyiTvVersion () {
  return request({
    url: '/api/SoftVersion/GetTv',
    method: 'get'
  })
}

export function getLmeriTvVersion () {
  return request({
    url: '/api/SoftVersion/GetLmeriTv',
    method: 'get'
  })
}

export function getLmeriTvSEVersion () {
  return request({
    url: '/api/SoftVersion/GetLmeriTvSE',
    method: 'get'
  })
}

export function getLimerHomeVersion () {
  return request({
    url: '/api/SoftVersion/GetLimerHome',
    method: 'get'
  })
}

export function getLimerHomeIOSVersion () {
  return request({
    url: '/api/SoftVersion/GetLimerHomeIOS',
    method: 'get'
  })
}

export function getEsenVersion () {
  return request({
    url: '/api/SoftVersion/GetEsen',
    method: 'get'
  })
}

export function getLimerRuiHomeVersion () {
  return request({
    url: '/api/SoftVersion/GetLimerRuiHome',
    method: 'get'
  })
}


/**
 * 获取智美OA版本信息
 * @returns
 */
export function getOAVersion () {
  return request({
    url: '/api/SoftVersion/GetOA',
    method: 'get',
  })
}

/**
 * 获取用户习惯分组信息
 */
export function getUserHabitsLog () {
  return request({
    url: 'api/UserHabitsLog/GetPageList',
    method: 'get',
  })
}
