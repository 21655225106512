<template>
  <div>
    <el-card>
      <el-form ref="addform" size="mini" :inline="true" :model="infoData" :rules="formRules">
        <el-form-item label="订单类型" prop="type">
          <el-select v-model="infoData.type" placeholder="请选择订单类型">
            <el-option
              v-for="item in typeOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联订单号">
          <el-input
            v-model="infoData.associatedOrderNumber"
            placeholder="请输入关联的订单号"
          />
        </el-form-item>
        <el-form-item label="用户" prop="owerUser">
          <el-select
            v-model="infoData.owinUserName"
            filterable
            @focus="onFocus"
            remote
            clearable
            placeholder="请输入商城用户名"
            :remote-method="userRemoteFun"
            @change="userIdSelectChange"
          >
            <el-option
              v-for="item in userOption"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货人">
          <el-autocomplete
            clearable
            class="inline-input"
            v-model="infoData.addressee"
            :fetch-suggestions="userAddressQuery"
            placeholder="请输入收货人姓名"
            @select="userAddressSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            clearable
            v-model="infoData.phone"
            style="width: 200px"
            placeholder="请输入收货人联系方式"
          />
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            clearable
            v-model="infoData.address"
            style="width: 200px"
            placeholder="请输入收货地址"
          />
        </el-form-item>
        <el-form-item label="订单备注">
          <el-input
            clearable
            v-model="infoData.remark"
            style="width: 200px"
            placeholder="请输入订单备注内容"
          />
        </el-form-item>
        <el-form-item>
          <el-radio v-model="infoData.payType" :label="false">寄付</el-radio>
          <el-radio v-model="infoData.payType" :label="true">到付</el-radio>
        </el-form-item>
        <!-- <el-form-item label="是否已返还">
          <el-switch v-model="infoData.isReturn"></el-switch>
        </el-form-item>
        <el-form-item v-if="infoData.isReturn" label="返还快递单号">
          <el-input
            clearable
            v-model="infoData.returnTrackingNumber"
            style="width: 200px"
            placeholder="请输入返还快递单号"
          />
        </el-form-item> -->
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <el-table
        :key="toggle"
        :data="produceList"
        height="calc(100vh - 60px - 80px - 220px)"
      >
        <el-table-column
          align="center"
          type="index"
          label="#"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productName"
          label="商品名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="modelName"
          label="商品型号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productSpecs"
          label="商品规格"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column align="center" prop="productAmount" label="数量">
          <template slot-scope="scope">
            <el-input-number
              size="mini"
              :precision="0"
              v-model="scope.row.productAmount"
              :min="1"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              @click="editProduct(scope.row)"
              type="text"
              icon="el-icon-edit"
              >编辑</el-button
            >
            <el-button
              @click="deleteProduct(scope.row)"
              type="text"
              style="color: red"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px; text-align: right">
        <!-- <el-button size="mini" type="primary" @click="goOutStorage"
          >去出库</el-button> -->
        <el-button size="mini" type="danger" @click="goReplacePage"
          >放弃订单</el-button
        >
        <el-button size="mini" type="success" @click="openAddFormDialog"
          >新增商品</el-button
        >
        <el-button size="mini" type="primary" @click="saveOrder"
          >保存订单</el-button
        >
      </div>
    </el-card>

    <!-- 新增商品对话框 -->
    <el-dialog title="新增商品" :visible.sync="addFormDialogVisabled">
      <el-form :model="addForm">
        <el-form-item label="商品名称">
          <el-select
            style="width: 100%"
            v-model="addForm.produceId"
            filterable
            @focus="onFocus"
            remote
            reserve-keyword
            placeholder="请输入商品名称"
            :remote-method="addFormProductQuerySearch"
            @change="addFormProductSelectChange"
          >
            <el-option
              v-for="item in addFormProductOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品型号">
          <el-select
            style="width: 100%"
            v-model="addForm.modelName"
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in addFormModelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品规格">
          <el-select
            style="width: 100%"
            v-model="addForm.productSpecs"
            placeholder="请输入关键词"
            @change="addFormSpecsChange"
          >
            <el-option
              v-for="item in addFormSpecsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="count" label="商品数量">
          <el-input-number
            :precision="0"
            v-model="addForm.productAmount"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input-number
            v-model="addForm.productPrice"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            type="textarea"
            :rows="2"
            v-model="addForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addFormDialogVisabled = false"> 取消 </el-button>
        <el-button type="primary" @click="addFormPushProduct">确认</el-button>
      </div>
    </el-dialog>
    <!-- 编辑商品对话框 -->
    <el-dialog title="新增商品" :visible.sync="editFormDialogVisabled">
      <el-form :model="editProductData">
        <el-form-item label="商品名称">
          <el-select
            style="width: 100%"
            v-model="editProductData.produceId"
            filterable
            @focus="onFocus"
            remote
            reserve-keyword
            placeholder="请输入商品名称"
            :remote-method="addFormProductQuerySearch"
            @change="editFormProductSelectChange"
          >
            <el-option
              v-for="item in addFormProductOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品型号">
          <el-select
            style="width: 100%"
            v-model="editProductData.modelName"
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in addFormModelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品规格">
          <el-select
            style="width: 100%"
            v-model="editProductData.productSpecs"
            placeholder="请输入关键词"
            @change="editFormSpecsChange"
          >
            <el-option
              v-for="item in addFormSpecsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="count" label="商品数量">
          <el-input-number
            :precision="0"
            v-model="editProductData.productAmount"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input-number
            v-model="editProductData.productPrice"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            type="textarea"
            :rows="2"
            v-model="editProductData.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editFormDialogVisabled = false"> 取消 </el-button>
        <el-button type="primary" @click="editFormPushProduct">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserOptions, getUserAddressList } from '@/api/user.js'
import { getTypeOptions, edit, getById } from '@/api/replace'
import { getFilterProduct } from '@/api/product.js'

import { iosOnFocus } from '@/utils/iosPlatform.js'

export default {
  data() {
    return {
      infoData: {},
      toggle: false,
      form: {
        id: 0,
        type: null,
        associateOrderNumber: '',
        owerUser: null,
        owinUserName: '',
        isReturn: false,
        returnTrackingNumber: '',
        addressee: '',
        phone: '',
        address: '',
        remark: '',
        payType: false,
      },
      formRules: {
        type: [
          { required: true, message: "请选择订单类型", trigger: "change" },
        ],
        owerUser: [
          { required: true, message: "请输入商城用户", trigger: "change" },
        ]
      },
      typeOptions: [],
      produceList: [],
      // 用户名选项框
      userOption: [],
      // 用户地址信息
      userAddressList: [],
      addFormDialogVisabled: false,
      editFormDialogVisabled: false,
      addForm: {
        produceId: null,
        productName: '',
        modelName: '',
        productSpecs: '',
        productAmount: 1,
        productPrice: '',
        remark: '',
      },
      editProductData: {},
      addFormProductOptions: [],
      addFormModelOptions: [],
      addFormSpecsOptions: [],
    }
  },
  created() {
    this.getTypes()
    this.getById()

  },
  methods: {

    async getById() {
      this.loading = true;
      this.form = [];
      let id = this.$route.params.id;
    //   console.log(id)
      let { data } = await getById(id)
      console.log(data)
      this.infoData = data;
      this.produceList = this.infoData.details;
      // this.form.type = this.infoData.type;
      // this.form.associateOrderNumber = this.infoData.associatedOrderNumber;
      // this.form.owerUser = this.infoData.owerUser;
      // this.form.owinUserName = this.infoData.owinUserName;
      // this.form.isReturn = this.infoData.isReturn;
      // this.form.returnTrackingNumber = this.infoData.returnTrackingNumber;
      // this.form.addressee = this.infoData.addressee;
      // this.form.phone = this.infoData.phone;
      // this.form.address = this.infoData.address;
      // this.form.remark = this.infoData.remark;
      // console.log(this.form)
      this.loading = false
    },

    async getTypes() {
      let { data } = await getTypeOptions()
      this.typeOptions = data
      // this.form.type = data[0].value
    },
    // 用户远程搜索
    async userRemoteFun(key) {
      if (key !== '') {
        let { data } = await getUserOptions(key)
        this.userOption = data
      }
    },
    // 用户名选中更改
    async userIdSelectChange(dataId) {
      let entity = this.userOption.filter((item) => {
        return item.id == dataId
      })[0]

      console.log(entity)

      // this.form.owinUserName = entity.nickName

      // this.form.addressee = ''
      // this.form.phone = ''
      // this.form.address = ''

      this.infoData.owinUserName = entity.nickName

      this.infoData.addressee = ''
      this.infoData.phone = ''
      this.infoData.address = ''

      let { data } = await getUserAddressList(dataId)
      this.userAddressList = data
    },
    // 用户快捷地址选择更改
    userAddressSelectChange(dataId) {
      let entity = this.userAddressList.filter((item) => {
        return item.id == dataId
      })[0]

      // this.form.addressee = entity.receiverName
      // this.form.phone = entity.receiverPhone
      // this.form.address = entity.receiverAddress

      this.infoData.addressee = entity.receiverName
      this.infoData.phone = entity.receiverPhone
      this.infoData.address = entity.receiverAddress
    },
    // 用户联系方式
    userAddressQuery(keyworld, cb) {
      var restaurants = [...this.userAddressList]
      var results = keyworld
        ? restaurants.filter((item) => {
            return item.receiverName.indexOf(keyworld) === 0
          })
        : restaurants

      let resultData = results.map((item) => {
        return { ...item, value: item.receiverName }
      })

      // 调用 callback 返回建议列表的数据
      cb(resultData)
    },
    // 用户联系方式快捷选择
    userAddressSelect(data) {
      // this.form.addressee = data.receiverName
      // this.form.phone = data.receiverPhone
      // this.form.address = data.receiverAddress

      this.infoData.addressee = data.receiverName
      this.infoData.phone = data.receiverPhone
      this.infoData.address = data.receiverAddress
    },

    // 放弃订单
    goReplacePage() {
      this.$confirm('是否放弃订单修改?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.push('/Replace')
      })
    },

    // 打开新增商品
    openAddFormDialog() {
      this.addForm = {
        produceId: null,
        productName: '',
        modelName: '',
        productSpecs: '',
        productAmount: 1,
        productPrice: '',
        remark: '',
      }
      this.addFormDialogVisabled = true
    },
    // 新增房间对话框房间筛选
    async addFormProductQuerySearch(queryString) {
      if (queryString !== '') {
        let { data } = await getFilterProduct(queryString)
        this.addFormProductOptions = data
      } else {
        this.addFormProductOptions = []
      }
    },
    addFormProductSelectChange(id) {
      console.log('id', id)

      let item = this.addFormProductOptions.find((item) => {
        return item.id == id
      })
      console.log('item', item)
      this.addForm.productName = item.productName
      this.addForm.productPrice = item.price

      if (item.modelOptions && item.modelOptions.length > 0) {
        this.addFormModelOptions = item.modelOptions
        this.addForm.modelName = item.modelOptions[0].value
      }

      if (item.attribute && item.attribute.length > 0) {
        this.addFormSpecsOptions = item.attribute
        this.addForm.productSpecs = item.attribute[0].value
        this.addForm.productPrice = item.attribute[0].price
      }
    },
        addFormSpecsChange(value) {
      if (this.addFormSpecsOptions.some((u) => u.value === value)) {
        let item = this.addFormSpecsOptions.find((u) => u.value === value)

        this.addForm.productSpecs = item.value
        this.addForm.productPrice = item.price
      }
    },
    addFormPushProduct() {
      let data = this.addForm

      if (
        !data.produceId ||
        !data.productName ||
        !data.modelName ||
        !data.productSpecs
      ) {
        return this.$message.error('请输入完整的商品信息')
      }

      if (
        this.produceList.some(
          (u) =>
            u.produceId == data.produceId &&
            u.modelName == data.modelName &&
            u.productSpecs == data.productSpecs
        )
      ) {
        let productData = this.produceList.find(
          (u) =>
            u.produceId == data.produceId &&
            u.modelName == data.modelName &&
            u.productSpecs == data.productSpecs
        )

        productData.productAmount += data.productAmount

        productData.remark += data.remark
      } else {
        this.produceList.push(JSON.parse(JSON.stringify(data)))
      }

      this.addFormDialogVisabled = false
    },
    editFormProductSelectChange(id) {
      console.log('id', id)

      let item = this.addFormProductOptions.find((item) => {
        return item.id == id
      })
      console.log('item', item)
      this.editProductData.productName = item.productName
      this.editProductData.productPrice = item.price

      if (item.modelOptions && item.modelOptions.length > 0) {
        this.addFormModelOptions = item.modelOptions
        this.editProductData.modelName = item.modelOptions[0].value
      }

      if (item.attribute && item.attribute.length > 0) {
        this.addFormSpecsOptions = item.attribute
        this.editProductData.productSpecs = item.attribute[0].value
        this.editProductData.productPrice = item.attribute[0].price
      }
    },
    editFormSpecsChange(value) {
      if (this.addFormSpecsOptions.some((u) => u.value === value)) {
        let item = this.addFormSpecsOptions.find((u) => u.value === value)

        this.editProductData.productSpecs = item.value
        this.editProductData.productPrice = item.price
      }
    },
    editFormPushProduct() {
      let data = this.editProductData

      if (
        !data.produceId ||
        !data.productName ||
        !data.modelName ||
        !data.productSpecs
      ) {
        return this.$message.error('请输入完整的商品信息')
      }

      const existingProductIndex = this.produceList.findIndex((u) => u.id == data.id);
      // console.log(existingProductIndex)

      if (existingProductIndex !== -1) {
        // 用新的数据替换已有的数据
        this.$set(this.produceList, existingProductIndex, JSON.parse(JSON.stringify(data)));
      } else {
        this.produceList.push(JSON.parse(JSON.stringify(data)))
      }

      this.editFormDialogVisabled = false
    },
    editProduct(row){
      this.editProductData = row;
      this.addFormProductQuerySearch(this.editProductData.productName)
      this.editFormDialogVisabled = true
    },
    deleteProduct(row) {
      console.log(row)
      this.$confirm(
        `是否删除商品[${row.productName}(${row.modelName})(${row.productSpecs})]`,
        '提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then((_) => {
          this.produceList = this.produceList.filter((u) => {
            return !(
              u.produceId == row.produceId &&
              u.modelName == row.modelName &&
              u.productSpecs == row.productSpecs
            )
          })
        })
        .catch(() => {})
    },
    async saveOrder() {
      this.form.id = this.infoData.id;
      this.form.type = this.infoData.type;
      this.form.associateOrderNumber = this.infoData.associatedOrderNumber;
      this.form.owerUser = this.infoData.owerUser;
      this.form.owinUserName = this.infoData.owinUserName;
      this.form.isReturn = this.infoData.isReturn;
      this.form.returnTrackingNumber = this.infoData.returnTrackingNumber;
      this.form.addressee = this.infoData.addressee;
      this.form.phone = this.infoData.phone;
      this.form.address = this.infoData.address;
      this.form.remark = this.infoData.remark;
      this.form.payType = this.infoData.payType;
      // console.log(this.form)

      this.$refs.addform.validate(async (valid) => {
        if (valid) {
          if (
            !this.form.type ||
            !this.form.owerUser
            // !this.form.associateOrderNumber ||
            // !this.form.owerUser ||
            // !this.form.owinUserName ||
            // !this.form.addressee ||
            // !this.form.phone ||
            // !this.form.address
          ) {
            return this.$message.error('请输入完整的订单信息')
          }

          if (this.form.isReturn && !this.form.returnTrackingNumber) {
            return this.$message.error('请输入返还商品的快递单号')
          }

          if (!this.produceList.some((u) => true)) {
            return this.$message.error('替换货的商品数量不能为空')
          }

          let data = {
            ...this.form,
            products: this.produceList,
          }

          // console.log(data)
          await edit(data)
          this.$router.push('/Replace');
        }
      })
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
}
</script>

<style lang="scss" scoped></style>
