<template>
  <div style="height:72vh;">
    <el-scrollbar style="height:72vh;">
      <quill-editor 
        v-model="content" 
        ref="myQuillEditor" 
        :options="editorOption"
        @blur="onEditorBlur($event)" 
        @focus="onEditorFocus($event)"
        @change="onEditorChange($event)"
        @ready="onEditorReady($event)">
      </quill-editor>
    </el-scrollbar>
    <!-- <button @click="captureScreenshot">截图并复制到粘贴板</button> -->
    <!-- <input type="file" id="upload" style="display:none;" @change="onFileChange" /> -->
  </div>
</template>

<script>
  // 引入样式
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import { quillEditor } from 'vue-quill-editor'

// import { html2canvas } from 'html2canvas'

  export default {
    name:'Editor',
    props:{
      // onSendData:{
      //   type: Function,
      //   required: true
      // }
      oldContent: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        // tooltips: {
        //   'bold': '加粗',
        //   'italic': '斜体',
        //   'underline': '下划线',
        //   'strike': '删除线',
        //   'blockquote': '引用',
        //   'code-block': '代码块',
        //   'header': '标题',
        //   'indent': '缩进',
        //   'size': '字体大小',
        //   'color': '字体颜色',
        //   'background': '背景颜色',
        //   'align': '对齐方式',
        //   'clean': '清除格式'
        // },
        editorOption: {
          placeholder: '请输入标题及内容...',
          theme: "snow",
          modules: {
            // toolbar: false
            toolbar:{
              container: [
                ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                ['blockquote', 'code-block'], // 引用  代码块
                [{ header: 1 }, { header: 2 }], // 1、2 级标题
                [{ indent: '-1' }, { indent: '+1' }], // 缩进
                [{'size': ['small', false, 'large', 'huge']}],
                [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                [{ align: [] }], // 对齐方式
                ['clean'], // 清除文本格式
              ],
              // container: [
              //   ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              //   ['blockquote', 'code-block'], // 引用  代码块
              //   [{ header: 1 }, { header: 2 }], // 1、2 级标题
              //   // [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              //   // [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              //   [{ indent: '-1' }, { indent: '+1' }], // 缩进
              //   // [{ direction: 'rtl' }], // 文本方向
              //   // [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
              //   [{'size': ['small', false, 'large', 'huge']}],
              //   // [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              //   [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              //   // [{ font: ['songti'] }], // 字体种类
              //   [{ align: [] }], // 对齐方式
              //   ['clean'], // 清除文本格式
              //   // ['image'] // 链接、图片，需要视频的可以加上video
              // ],
              // handlers: {   //此处是图片上传时候需要使用到的
              //   'image': function (value) {
              //     console.log(value)
              //     if (value) {  // 点击进行图片上传
              //       document.querySelector('#upload').click()
              //     }
              //   }
              // }
            },
          },
          imageDrop: true,   // 图片拖拽
          imageResize: {     // 图片放大缩小
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white"
            },
            modules: ["Resize", "DisplaySize", "Toolbar"]
          }
        },
        content: ``,
      };
    },

    components: {
      quillEditor
    },
    created(){
      
    },

    computed: {},

    beforeMount() {},

    mounted() {
      console.log(this.oldContent)
      this.content = this.oldContent;
      // this.toggleEdit()
    },

    methods: {
      // // 失去焦点事件
      // onEditorBlur(quill) {
      //   console.log('editor blur!', quill)
      //   quill.enable(false);
      //   this.editorOption.modules.toolbar = false;
      // },
      // 获得焦点事件
      // onEditorFocus(quill) {
      //   console.log('editor focus!', quill)
      //   quill.enable(false);
      //   this.editorOption.modules.toolbar = false;
      // },
      // 准备富文本编辑器
      onEditorReady(quill) {
        console.log('editor ready!', quill)
        // if(this.content)
        // quill.enable(false);
      },
      // 内容改变事件，只需要这一个方法就够了
      onEditorChange({ quill, html, text }) {
        console.log('editor change!', quill, html, text)
        this.content = html
        this.$emit('htmlChange', html)
        this.$emit('textChange', text)
      },
      bindPasteEvent(quill) {
        quill.root.addEventListener('paste', (e) => {
          const items = e.clipboardData.items;

          for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
              const file = items[i].getAsFile();
              const reader = new FileReader();

              reader.onload = (event) => {
                const range = quill.getSelection();
                quill.insertEmbed(range.index, 'image', event.target.result);
              };

              reader.readAsDataURL(file);
            }
          }
        });
      },
      clear(){
        this.content = '';
      },
      toggleEdit() {
        this.quill.enable(false);
        this.editorOption.modules.toolbar = false;
      }
      // async captureScreenshot() {
      //   console.log("点击截图")
      //   const element = this.$refs.myQuillEditor.quill.root; // 获取 Quill 编辑器中的 DOM 元素
      //   const canvas = await html2canvas(element); // 使用 html2canvas 生成截图
      //   console.log("已截图")
      //   canvas.toBlob(async (blob) => {
      //     const item = new ClipboardItem({
      //       [blob.type]: blob
      //     });

      //     try {
      //       await navigator.clipboard.write([item]); // 将 blob 写入剪贴板
      //       alert('截图已复制到剪贴板！');
      //     } catch (err) {
      //       console.error('无法复制到剪贴板：', err);
      //     }
      //   });
      // },
      // onFileChange(event) {
      //   const file = event.target.files[0];
      //   const reader = new FileReader();
  
      //   reader.onload = () => {
      //     const quill = this.$refs.myQuillEditor.quill;
      //     const range = quill.getSelection();
      //     quill.insertEmbed(range.index, 'image', reader.result);
      //   };
  
      //   if (file) {
      //     reader.readAsDataURL(file);
      //   }
      // },
      // // 文件上传限制
      // handleExceed(files, fileList) {
      //   this.$message.warning(`当前限制一次性上传最多 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
      // },
    },

    watch: {
      oldContent(newValue,oldValue){
        console.log("newValue", newValue)
        if(newValue!=null&&newValue!=''){
          this.content = newValue;
          // this.quill.enable(false);
          // this.editorOption = {};
          // toolbar:{
          //     container: [
          //       ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
          //       ['blockquote', 'code-block'], // 引用  代码块
          //       [{ header: 1 }, { header: 2 }], // 1、2 级标题
          //       [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
          //       [{ script: 'sub' }, { script: 'super' }], // 上标/下标
          //       [{ indent: '-1' }, { indent: '+1' }], // 缩进
          //       // [{ direction: 'rtl' }], // 文本方向
          //       // [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
          //       [{'size': ['small', false, 'large', 'huge']}],
          //       [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
          //       [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
          //       // [{ font: ['songti'] }], // 字体种类
          //       [{ align: [] }], // 对齐方式
          //       ['clean'], // 清除文本格式
          //       ['image'] // 链接、图片，需要视频的可以加上video
          //     ],
          //     handlers: {   //此处是图片上传时候需要使用到的
          //       'image': function (value) {
          //         console.log(value)
          //         if (value) {  // 点击图片
          //           document.querySelector('#upload').click()
          //         }
          //       }
          //     }
          //   },
        }
      }
    }

  }

</script>
<style lang="scss" scoped>
::v-deep .ql-toolbar.ql-snow {
  border: none !important;
  // border-bottom: 1px solid #ccc !important;
}

::v-deep .ql-container.ql-snow {
  border: none !important;
}

/* 自定义 Quill 编辑器的 placeholder 样式 */
::v-deep .gl-editor.gl-blank::before {
    color: red !important; /* 修改字体颜色 */
    font-style: italic; /* 修改字体样式 */
    content: '请输入内容...'; /* 修改默认提示内容（可选） */
}
</style>