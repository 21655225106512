import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/Layout'

Vue.use(Router)

// 固定路由
export const constantRoutes = [{
  path: '/login',
  name: 'Login',
  component: () => import('@/views/Login/index.vue'),
  meta: {
    title: '登录',
    icon: 'el-icon-data-line',
  },
  hidden: true,
},
{
  path: '/UserReturnInfo/:id',
  name: 'UserReturnInfo',
  component: () => import('@/views/user-return-info/index.vue'),
  meta: {
    title: '替换货信息',
    icon: 'el-icon-data-line',
  },
  hidden: true,
},
{
  path: '/',
  component: Layout,
  redirect: '/welcome',
  meta: {
    title: '主页',
  },
  hidden: true,
  children: [{
    path: '/welcome',
    component: () => import('@/views/welcome.vue'),
    meta: {
      title: '欢迎',
    },
  },],
},
{
  path: '/Home',
  component: Layout,
  redirect: '/welcome',
  meta: {
    title: '首页',
    icon: 'el-icon-school',
    color: '#E8523F',
  },
  hidden: false,
  // children: [
  //   {
  //     path: '/HomeInfo',
  //     component: () => import('@/views/Home/HomeInfo.vue'),
  //     meta: {
  //       title: '首页详情',
  //       image: 'home_icon.png',
  //       icon: 'el-icon-user',
  //     },
  //     hidden: false,
  //   },
  // ],
},
{
  path: '/QrCode/:id',
  name: 'QrCode',
  component: () => import('@/views/SystemDesign/SlnDesign/DownLoadCode.vue'),
  meta: {
    title: '二维码',
  },
  hidden: true,
},
{
  path: '/404',
  component: () => import('@/views/error-page/404'),
  meta: {
    title: '没有找到该页面',
  },
  hidden: true,
},
{
  path: '/401',
  component: () => import('@/views/error-page/401'),
  meta: {
    title: '没有权限访问当前',
  },
  hidden: true,
},
]

// 基本信息路由
const basicInformation = [{
  path: '/Basic',
  component: Layout,
  redirect: '/Supplier',
  meta: {
    title: '基本信息',
    icon: 'el-icon-user'
  },
  hidden: false,
  children: [{
    path: '/Supplier',
    component: () => import('@/views/Basic/Supplier/index.vue'),
    meta: {
      title: '供应商管理',
    },
    hidden: false,
  },
  {
    path: '/Customer',
    component: () => import('@/views/Basic/Customer/index.vue'),
    meta: {
      title: '客户管理',
    },
    hidden: false,
  },
  {
    path: '/Dealer',
    component: () => import('@/views/Basic/Dealer/index.vue'),
    meta: {
      title: '经销商管理',
    },
    hidden: false,
  }, {
    path: '/SystemTask',
    component: () => import('@/views/Basic/SystemTask/index.vue'),
    meta: {
      title: '系统任务',
    },
    hidden: false,
  },
    // {
    //   path: 'Unit',
    //   component: () => import('@/views/Basic/index.vue'),
    //   meta: {
    //     title: '计量单位',
    //     image: 'el-icon-user',
    //   },
    //   hidden: false,
    // },
    // {
    //   path: 'Category',
    //   component: () => import('@/views/Basic/index.vue'),
    //   meta: {
    //     title: '产品分类',
    //     image: 'el-icon-user',
    //   },
    //   hidden: false,
    // },
    // {
    //   path: 'Goods',
    //   component: () => import('@/views/Basic/index.vue'),
    //   meta: {
    //     title: '产品管理',
    //     image: 'el-icon-user',
    //   },
    //   hidden: false,
    // },
  ],
},]

// 管理员路由信息
const adminRoutes = [{
  path: '/Admin',
  component: Layout,
  redirect: '/Admin/Index',
  meta: {
    title: '后台管理',
    icon: 'el-icon-user'
  },
  hidden: false,
  children: [{
    path: 'Index',
    component: () => import('@/views/Admin/index.vue'),
    meta: {
      title: '人员管理',
    },
    hidden: false,
  },
  {
    path: 'Roles',
    component: () => import('@/views/Admin/Roles/index.vue'),
    meta: {
      title: '角色管理',
    },
    hidden: false,
  },
  {
    path: 'Permission',
    component: () => import('@/views/Admin/Permission/index.vue'),
    meta: {
      title: '权限菜单',
    },
    hidden: false,
  },
  ],
},]
// 仓库路由
const storageRoutes = [
  // 仓库作业
  {
    path: '/Storage',
    component: Layout,
    redirect: '/InStorage',
    meta: {
      title: '仓库作业',
      icon: 'el-icon-truck',
      color: '#6672FB',
    },
    children: [{
      path: '/Procurement',
      component: () => import('@/views/Storage/Procurement/index.vue'),
      meta: {
        title: '采购管理',
      },
      hidden: false,
    },
    {
      path: '/AddProcurement',
      component: () => import('@/views/Storage/Procurement/add.vue'),
      meta: {
        title: '新增采购单',
      },
      hidden: true,
    },
    {
      path: '/EditProcurement/:id',
      component: () => import('@/views/Storage/Procurement/edit.vue'),
      meta: {
        title: '编辑采购单',
      },
      hidden: true,
    },
    {
      path: '/OnStorage',
      component: () => import('@/views/Storage/OnStorage/index.vue'),
      meta: {
        title: '库存管理',
      },
      hidden: false,
    },
    {
      path: '/OutStorage',
      component: () => import('@/views/Storage/OutStorage/index.vue'),
      meta: {
        title: '出库管理',
      },
      hidden: false,
    },
    {
      path: '/AddOutStorage',
      component: () => import('@/views/Storage/OutStorage/add.vue'),
      meta: {
        title: '新增出库单',
      },
      hidden: true,
    },
    {
      path: '/EditOutStorage/:id',
      component: () => import('@/views/Storage/OutStorage/edit.vue'),
      meta: {
        title: '编辑出库单',
      },
      hidden: true,
    },
    {
      path: '/Replace',
      component: () => import('@/views/Storage/Replace/index.vue'),
      meta: {
        title: '替换货',
      },
      hidden: false,
    },
    {
      path: '/AddReplace',
      component: () => import('@/views/Storage/Replace/add.vue'),
      meta: {
        title: '新增替换货',
      },
      hidden: true,
    },
    {
      path: '/EditReplace/:id',
      component: () => import('@/views/Storage/Replace/edit.vue'),
      meta: {
        title: '编辑替换货',
      },
      hidden: true,
    },
    ],
  },
]

// 我的订单
const myOrder = [{
  path: '/MyOrder',
  component: Layout,
  redirect: '/MyOrder',
  meta: {
    title: '我的订单',
    icon: 'el-icon-s-order',
    color: '#6672FB',
  },
  children: [{
    path: '/MyProcurementOrder',
    component: () => import('@/views/MyOrder/Procurement/index.vue'),
    meta: {
      title: '采购订单',
    },
    hidden: false,
  },
  {
    path: '/MyLettringOrder',
    component: () => import('@/views/MyOrder/Lettring/index.vue'),
    meta: {
      title: '刻字订单',
    },
    hidden: false,
  },
  ],
},]

// 我的年度报表
const myReport = [{
  path: '/MyReport',
  component: Layout,
  redirect: '/MyReport/Purchase',
  meta: {
    title: '我的报表',
    icon: 'el-icon-data-line',
    color: '#6672FB',
  },
  children: [{
    path: 'Purchase',
    component: () => import('@/views/MyReport/Purchase/index.vue'),
    meta: {
      title: '采购报表',
    },
    hidden: false,
  }]
}]

// 统计报表
const cashier = [{
  path: '/Cashier',
  component: Layout,
  redirect: '/Cashier/Index',
  meta: {
    title: '出纳统计',
    icon: 'el-icon-notebook-1',
    color: '#6672FB',
  },
  children: [{
    path: 'Index',
    component: () => import('@/views/Cashier/index.vue'),
    meta: {
      title: '出纳管理',
    },
    hidden: false,
  }]
}]

// 软件管理
const softVersionRoute = [{
  path: '/SoftInfo',
  component: Layout,
  redirect: '/SoftAndroid',
  meta: {
    title: '软件管理',
    icon: 'el-icon-truck',
    color: '#6672FB',
  },
  children: [{
    path: '/SoftAndroid',
    component: () => import('@/views/SoftVersion/Android/index.vue'),
    meta: {
      title: '雪意App版本管理',
    },
    hidden: false,
  },
  {
    path: '/MilkTea',
    component: () => import('@/views/SoftVersion/milkTea/index.vue'),
    meta: {
      title: '奶茶机版本管理',
    },
    hidden: false,
  },
  {
    path: '/Coffee',
    component: () => import('@/views/SoftVersion/coffee/index.vue'),
    meta: {
      title: '咖啡机版本管理',
    },
    hidden: false,
  },
  {
    path: '/SnowyiOA',
    component: () => import('@/views/SoftVersion/SnowyiOA/index.vue'),
    meta: {
      title: '智美OA版本管理',
    },
    hidden: false,
  },
  {
    path: '/SnowyiTv',
    component: () => import('@/views/SoftVersion/SnowyiTv/index.vue'),
    meta: {
      title: 'SnowyiTv',
    },
    hidden: false,
  },
  {
    path: '/LmeriTv',
    component: () => import('@/views/SoftVersion/LmeriTv/index.vue'),
    meta: {
      title: 'LmeriTv',
    },
    hidden: false,
  },
  {
    path: '/LmeriTvSE',
    component: () => import('@/views/SoftVersion/LmeriTvSE/index.vue'),
    meta: {
      title: 'LmeriTvSE',
    },
    hidden: false,
  },
  {
    path: '/LimerHome',
    component: () => import('@/views/SoftVersion/LimerHome/index.vue'),
    meta: {
      title: 'LimerHome',
    },
    hidden: false,
  },
  {
    path: '/LimerHomeIOS',
    component: () => import('@/views/SoftVersion/LimerHomeIOS/index.vue'),
    meta: {
      title: 'LimerHomeIOS',
    },
    hidden: false,
  },
  {
    path: '/Esen',
    component: () => import('@/views/SoftVersion/Esen/index.vue'),
    meta: {
      title: 'Esen',
    },
    hidden: false,
  },
  {
    path: '/LimerRuiHome',
    component: () => import('@/views/SoftVersion/LimerRuiHome/index.vue'),
    meta: {
      title: '艾美睿家',
    },
    hidden: false,
  },
  {
    path: '/UserHabitsLog',
    component: () => import('@/views/SoftVersion/UserHabitsLog/index.vue'),
    meta: {
      title: '用户使用习惯日志',
    },
    hidden: false,
  },
  {
    path: '/ExceptionLog',
    component: () => import('@/views/SoftVersion/Logs/index.vue'),
    meta: {
      title: '软件异常日志',
    },
    hidden: false,
  },
  ],
},]

// 报表管理
const report = [{
  path: '/Report',
  component: Layout,
  redirect: '/ReportInstorage',
  meta: {
    title: '报表统计',
    icon: 'el-icon-truck',
    color: '#6672FB',
  },
  children: [{
    path: '/ReportInstorage',
    component: () => import('@/views/Report/InStorage/index.vue'),
    meta: {
      title: '入库单报表统计',
    },
    hidden: true,
  },
  {
    path: '/ReportOutstorage',
    component: () => import('@/views/Report/OutStorage/index.vue'),
    meta: {
      title: '出库单报表统计',
    },
    hidden: false,
  },
  {
    path: '/ReportProcurement',
    component: () => import('@/views/Report/Procurement/index.vue'),
    meta: {
      title: '采购单报表统计',
    },
    hidden: false,
  },
  ],
},]

const contract = [{
  path: '/contract',
  component: Layout,
  redirect: '/contract/index',
  meta: {
    title: '合同管理',
    icon: 'el-icon-coordinate',
    color: '#6672FB',
  },
  children: [{
    path: 'index',
    component: () => import('@/views/contract/index.vue'),
    meta: {
      title: '合同列表',
    },
    hidden: false,
  }]
}]

const drageOrder = [{
  path: '/drageOrder',
  component: () => import('@/views/drageOrder/index.vue'),
  meta: {
    title: '拖拽订单',
    icon: 'el-icon-s-order',
    color: '#6672FB',
  },
  hidden: true,
}, {
  path: '/dragOrderEdit/:id',
  component: () => import('@/views/drageOrder/index.vue'),
  meta: {
    title: '编辑',
    icon: 'el-icon-s-order',
    color: '#6672FB',
  },
  hidden: true,
}]

const workManageMent = [{
  path: '/workManageMent',
  component: Layout,
  redirect: '/workManageMent/index',
  meta: {
    title: '工作管理',
    icon: 'el-icon-s-cooperation',
    color: '#6672FB',
  },
  children: [{
    path: 'index',
    component: () => import('@/views/workManageMent/index.vue'),
    meta: {
      title: '工作列表',
    },
    hidden: false,
  }, {
    path: 'addNewWork',
    component: () => import('@/views/workManageMent/addNewWork.vue'),
    meta: {
      title: '创建任务',
    },
    hidden: false,
  }, {
    path: 'reviewWork/:id',
    component: () => import('@/views/workManageMent/reviewWork.vue'),
    meta: {
      title: '审核任务',
    },
    hidden: true,
  }, {
    path: 'processWork/:id',
    component: () => import('@/views/workManageMent/processWork.vue'),
    meta: {
      title: '处理任务',
    },
    hidden: true,
  }]

}]

// 异步路由
export const asyncRoutes = [
  ...basicInformation,
  ...myOrder,
  ...myReport,
  // 销售统计
  {
    path: '/Sale',
    component: Layout,
    redirect: '/SaleOrder',
    meta: {
      title: '销售管理',
      icon: 'el-icon-data-line',
      color: '#6672FB',
    },
    hidden: false,
    children: [{
      path: '/SaleOrder',
      component: () => import('@/views/Sale/SaleOrder.vue'),
      meta: {
        title: '销售订单',
      },
      hidden: false,
    },
    {
      path: '/MackSaleOrder',
      component: () => import('@/views/Sale/MackOrder.vue'),
      meta: {
        title: '制作订单',
      },
      hidden: false,
    },
    {
      path: '/LettringOrder',
      component: () => import('@/views/Sale/Lettering/index.vue'),
      meta: {
        title: '刻字订单',
      },
      hidden: false,
    },
    {
      path: 'MackOrderAdd',
      component: () => import('@/views/Sale/MackOrderAdd/index.vue'),
      meta: {
        title: '新增人工订单',
      },
      hidden: true,
    },
    {
      path: '/MackOrderEdit/:id',
      component: () => import('@/views/Sale/MackOrderAdd/eidt.vue'),
      meta: {
        title: '编辑制作单',
      },
      hidden: true,
    },
    {
      name: 'orderInfo',
      path: 'orderInfo/:orderNumber',
      component: () => import('@/views/Sale/OrderInfo/index.vue'),
      meta: {
        title: '订单详情',
      },
      hidden: true,
    },
      /*
      {
        path: '/SaleSendOut',
        component: () => import('@/views/Sale/SaleSendOut.vue'),
        meta: {
          title: '发货单',
          image: 'sale_send_out_icon.png',
        },
        hidden: false,
      },
      {
        path: '/SaleShipments',
        component: () => import('@/views/Sale/SaleShipments.vue'),
        meta: {
          title: '发货统计',
          image: 'sale_shipments_icon.png',
        },
        hidden: false,
      },
      {
        path: '/SaleStatistics',
        component: () => import('@/views/Sale/SaleStatistics.vue'),
        meta: {
          title: '销售统计',
          image: 'sale_statistics_icon.png',
        },
        hidden: false,
      },
      */
    ],
  },
  // 场景解决方案
  {
    path: '/Solution',
    component: Layout,
    redirect: '/Solution/index',
    meta: {
      title: '解决方案',
      icon: 'el-icon-data-line',
      color: '#6672FB',
    },
    children: [{
      path: 'index',
      component: () => import('@/views/SystemDesign/index.vue'),
      meta: {
        title: '场景解决方案管理',
      },
      hidden: false,
    },
    {
      path: 'SlnDesign',
      component: () => import('@/views/SystemDesign/SlnDesign/index.vue'),
      meta: {
        title: '设计点位图方案',
      },
      hidden: true,
    },
    {
      path: 'SlnDesignSuccess/:id',
      component: () => import('@/views/SystemDesign/SlnDesign/successIndex.vue'),
      meta: {
        title: '设计点位图方案',
      },
      hidden: true,
    },

    {
      path: 'dragOrderEdit/:id',
      component: () => import('@/views/SystemDesign/dragOrderDesign/edit.vue'),
      meta: {
        title: '编辑场景方案',
      },
      hidden: true,
    },
    {
      path: 'DesignIndex',
      component: () => import('@/views/SystemDesign/dragOrderDesign/index.vue'),
      meta: {
        title: '点位图方案管理',
      },
      hidden: false,
    },
    {
      path: 'mySln',
      component: () => import('@/views/SystemDesign/MySln/index.vue'),
      meta: {
        title: '我的解决方案',
      },
      hidden: false,
    },
    {
      path: 'addMySln',
      component: () => import('@/views/SystemDesign/MySln/add/index.vue'),
      meta: {
        title: '新增场景方案',
      },
      hidden: true,
    },
    {
      path: 'add',
      component: () => import('@/views/SystemDesign/Add/index.vue'),
      meta: {
        title: '新增场景方案',
      },
      hidden: true,
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/SystemDesign/Edit/index.vue'),
      meta: {
        title: '编辑场景方案',
      },
      hidden: true,
    },
    {
      path: 'myEdit/:id',
      component: () => import('@/views/SystemDesign/MySln/edit/index.vue'),
      meta: {
        title: '编辑场景方案',
      },
      hidden: true,
    },
    ],
  },
  // 采购统计
  {
    path: '/Purchase',
    component: Layout,
    redirect: '/PurchaseOrder',
    meta: {
      title: '采购管理',
      icon: 'el-icon-sell',
      color: '#E8523F',
    },
    hidden: true,
    children: [{
      path: '/PurchaseOrder',
      component: () => import('@/views/Purchase/PurchaseOrder.vue'),
      meta: {
        title: '采购订单',
        image: 'purchase_order_icon.png',
        icon: 'el-icon-user',
      },
      hidden: false,
    },
    {
      path: 'AddPurchaseOrder',
      component: () => import('@/views/Purchase/AddOrder/index.vue'),
      meta: {
        title: '新增采购订单',
        image: 'purchase_order_icon.png',
        icon: 'el-icon-user',
      },
      hidden: true,
    },
    ],
  },
  ...contract,
  ...report,
  ...cashier,
  ...storageRoutes,
  ...drageOrder,
  ...workManageMent,
  ...softVersionRoute,
  ...adminRoutes,
  {
    path: '/ChatTest',
    component: () => import('@/views/Chat/index.vue'),
    hidden: true,
    meta: {
      title: 'chart测试',
    },
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      title: '没有找到页面',
    },
    hidden: true,
  },
]

const createRouter = () =>
  new Router({
    routes: constantRoutes,
  })

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router