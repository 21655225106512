import request from '@/utils/request'
import { expr } from 'jquery'

const URL = {
  getProduct: '/api/product/GetProduct',
  getProductBrand: '/api/product/GetAllBrand',
  getProductType: '/api/product/GetAllType',
  getProductById: '/api/product/GetProductById',
  getFilterProduct: '/api/product/FilterProduct',
}

export function getProductById (productId) {
  return request({
    url: URL.getProductById,
    method: 'get',
    params: { productId },
  })
}

export function stratProduct (productId) {
  return request({
    url: "/api/product/StartProduct",
    method: 'post',
    data: { productId },
  })
}

export function cancelStartProduct (productId) {
  return request({
    url: "/api/product/CancelStartProduct",
    method: 'post',
    data: { productId },
  })
}

// 获取订单信息
export function getProduct (brandId, typeId) {
  return request({
    url: URL.getProduct,
    method: 'get',
    params: { brandId, typeId },
  })
}

// 获取订单状态列表
export function getProductBrand () {
  return request({
    url: URL.getProductBrand,
    method: 'get',
  })
}

// 获取具体订单心情
export function getProductType () {
  return request({
    url: URL.getProductType,
    method: 'get',
  })
}

// 获取筛选商品
export function getFilterProduct (keyWorld) {
  return request({
    url: URL.getFilterProduct,
    method: 'get',
    params: { keyWorld },
  })
}

export function getFilterOnlineProduct (keyWorld) {
  return request({
    url: '/api/product/FilterOnlineProduct',
    method: 'get',
    params: { keyWorld },
  })
}

export function getSupplierFilterProduct (supplierId, keyWorld) {
  return request({
    url: '/api/product/FilterSupplierIdProduct',
    method: 'get',
    params: { supplierId, keyWorld },
  })
}

/**
 * 根据条件筛选商品
 * @param {Object} data
 * @returns
 */
export function getProducts (data) {
  return request({
    url: '/api/Product/GetProducts',
    method: 'post',
    data,
  })
}

/**
 * 获取所有商品品牌
 * @returns
 */
export function getAllBrand () {
  return request({
    url: '/api/Product/getAllBrand',
    method: 'get',
  })
}

/**
 * 获取所有商品类型
 * @returns
 */
export function getAllType () {
  return request({
    url: '/api/Product/GetAllType',
    method: 'get',
  })
}

export function getDrageOrderProduct (data) {
  return request({
    url: '/api/Product/DrageOrderProduct',
    method: 'post',
    data,
  })
}
