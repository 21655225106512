<template>
  <div class="app-context">
    <div class="header">
      <div class="title">待返还商品信息</div>
    </div>
    <div class="content">
      <el-table height="100%" :data="tableData" stripe style="width: 100%">
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="名称" prop="productName"> </el-table-column>
        <el-table-column label="型号">
          <template slot-scope="scope">
            {{ scope.row.modelName || scope.row.productModel || '无' }}
          </template>
        </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpecs || '无' }}
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="waitReturnAmout"> </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div></div>
      <div>商品总数:{{ productNumber }}</div>
    </div>
    <div style="background-color: #409eff">
      <div class="info">
        <a type="_blank" href=" http://beian.miit.gov.cn">
          备案号:浙ICP备15026216号-2
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductListByUser } from '@/api/replace.js'

export default {
  data() {
    return {
      userId: null,
      tableData: [],
    }
  },
  methods: {
    async getProductList(id) {
      let { data } = await getProductListByUser(id)
      this.tableData = data
    },
  },
  computed: {
    productNumber() {
      return this.tableData.reduce((pre, cur) => {
        return pre + cur.waitReturnAmout
      }, 0)
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        console.log(route)

        const query = route.params
        if (query) {
          this.userId = query.id
          this.getProductList(this.userId)
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-context {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #409eff;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }
  .content {
    flex: 1;
    overflow: hidden;
  }
  .footer {
    padding:10px 20px;
    display: flex;
    color: white;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    background-color: gray;
  }

  .info {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 12px;
    background-color: #409eff;
    padding: 10px;
    a {
      color: white;
    }
  }
}
</style>
