<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
          type="text"
          v-model="queryFilter.keyWorlds"
          placeholder="请输入日志筛选相关"
          clearable
        />
      </el-col>
      <el-col :span="6">
        <el-select
          v-model="queryFilter.status"
          clearable
          placeholder="请选择日志处理状态"
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-button type="primary" @click="falshTable">查询日志</el-button>
      <el-button type="danger" @click="piceDelete">清空日志</el-button>
      <el-button type="warning" @click="batchDelete">批量删除</el-button>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table :data="tableData" border :max-height="700" @selection-change="handleSelectionChange">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="margin: 20px">
              <el-form label-position="left" class="demo-table-expand">
                <el-form-item label="异常信息:">
                  <span>{{ props.row.exceptionMessage }}</span>
                </el-form-item>
                <el-form-item label="堆栈信息:">
                  <span>{{ props.row.stackTrace }}</span>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column label="#" width="40" type="index"></el-table-column>
        <el-table-column label="软件名称" prop="softName" width="110"></el-table-column>
        <el-table-column label="主机地址" prop="hostMac" width="120"></el-table-column>
        <el-table-column label="用户名" prop="userName">
          <template slot-scope="scope">
            {{scope.row.userName ? scope.row.userName : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="上报时间" prop="uploadTime"></el-table-column>
        <el-table-column label="国家地区" prop="nation" width="80">
          <template slot-scope="scope">
            {{scope.row.nation ? scope.row.nation : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="地区" prop="area" width="90">
          <template slot-scope="scope">
            {{scope.row.area ? scope.row.area : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="城市" prop="city" width="90">
          <template slot-scope="scope">
            {{scope.row.city ? scope.row.city : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="版本号" prop="versionNo" width="110">
          <template slot-scope="scope">
            {{scope.row.versionNo ? scope.row.versionNo : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="文件" prop="filePath" width="80">
          <template slot-scope="scope">
            <el-link v-if="scope.row.filePath"
              download
              target="_blank"
              v-for="(path, index) in filePathsToFilePath(scope.row.filePath)"
              :key="index"
              :href="path"
              >文件下载</el-link
            >
          </template>
        </el-table-column>
        <el-table-column label="是否解决" prop="remark" width="70">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isCheck"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="switchLogsState(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="70">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              style="color:red;"
              icon="el-icon-delete"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin-top: 20px; text-align: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryFilter.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryFilter.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="queryFilter.total"
      >
      </el-pagination>
    </el-row>
  </el-card>
</template>

<script>
import {
  getList,
  uploadLog,
  checkLog,
  getSoftExceptionOption,
  piceDelete,
  deleteById,
  deleteLogList,
  // getListAllow
} from '@/api/softException'
export default {
  data() {
    return {
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
        // appId: '2xlQ29kZSI6IkFkbWluX0',
        // appKey: 'MjowMDowMCIsIm5iZiI6MCwiaXAiOm51',
      },
      tableData: [],
      stateOptions: [],
      multipleSelection: [],
      logIdList: [],
    }
  },
  created() {
    this.getTableData()
    this.getStateOptions()
  },
  methods: {
    piceDelete() {
      this.$confirm('此操作将清空所有日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await piceDelete()
          this.getTableData()
        })
        .catch(() => {})
    },
    handleSelectionChange(val) {
      console.log("multipleSelection", val)
      this.logIdList = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item=>{
        this.logIdList.push(item.id)
      })
      console.log("LogIdList", this.logIdList)
    },
    batchDelete(){
      this.$confirm('此操作将永久删除选中部分的日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteLogList(this.logIdList)
          this.getTableData()
          this.$message.success("批量删除日志成功")
        })
        .catch(() => {})
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteById(row.id)
          this.getTableData()
        })
        .catch(() => {})
    },
    filePathsToFilePath(filePaths) {
      try {
        return filePaths.split(',')
      } catch (error) {
        return []
      }
    },
    async getStateOptions() {
      let { data } = await getSoftExceptionOption()
      this.stateOptions = data
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getTableData()
    },
    falshTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    async getTableData() {
      let { data } = await getList(this.queryFilter)
      // let { data } = await getListAllow(this.queryFilter)
      console.log("tableData", data)
      this.tableData = data.data

      this.queryFilter.total = data.total
    },
    async switchLogsState(row) {
      let { code } = await checkLog({
        id: row.id,
        isCheck: row.isCheck,
      })

      if (code != 200) {
        row.isCheck = !row.isCheck
        return this.$message.error('日志修改失败')
      }

      return this.$message.success('日志修改成功')
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
