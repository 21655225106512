<template>
  <el-card shadow="hover">
    <div slot="header">
      <span>点位图设计方案表</span>
    </div>
    <!-- <div id="container"></div> -->
    <div id="container" hidden></div>
    <el-row :gutter="20">
      <el-col :span="6"
        ><el-input
          v-model="queryFilter.keyWorlds"
          placeholder="请输入点位图方案相关信息进行筛选"
          clearable
      /></el-col>
      <el-button type="primary" @click="getSolution">查询方案</el-button>
      <el-button type="primary" style="float: right" @click="pushAddPage"
        >新增方案</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        v-loading="loading2"
        stripe
        style="width: 100%"
        border
        @expand-change="expandChange"
      >
        <!-- 方案里的楼层信息 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand_box">
              <el-table
                ref="systemSolutionDetailsTableRef"
                :data="scope.row.floors"
                v-loading="loading"
                border
                :summary-method="getSystemDetialSummaries"
                :show-summary="true"
              >
                <!-- 楼层里的房间信息 -->
                <el-table-column type="expand">
                  <template slot-scope="cscope">
                    <div class="expand_box">
                      <el-table :data="cscope.row.rooms" border>
                        <!-- 房间里的产品信息 -->
                        <el-table-column type="expand">
                          <template slot-scope="pscope">
                            <div class="expand_box">
                              <el-table :data="pscope.row.products" border>
                                <el-table-column
                                  type="index"
                                  width="80"
                                  label="#"
                                ></el-table-column>
                                <el-table-column
                                  prop="productName"
                                  show-overflow-tooltip
                                  label="商品名称"
                                >
                                </el-table-column>
                                <el-table-column label="商品品牌">
                                  <template slot-scope="pscope">
                                    {{ pscope.row.productBrandName }}
                                  </template>
                                </el-table-column>

                                <el-table-column prop="number" label="数量">
                                </el-table-column>
                                <el-table-column
                                  prop="productPrice"
                                  label="价格"
                                >
                                </el-table-column>
                                <el-table-column prop="allPrice" label="总价格">
                                </el-table-column>
                                <el-table-column label="型号规格">
                                  <template slot-scope="pscope">
                                    {{
                                      pscope.row.productModel +
                                      '/' +
                                      pscope.row.productSpec
                                    }}
                                  </template>
                                </el-table-column>
                                <el-table-column prop="remark" label="备注">
                                </el-table-column>
                              </el-table>
                            </div>
                          </template>
                        </el-table-column>

                        <el-table-column
                          type="index"
                          width="80"
                          label="#"
                        ></el-table-column>
                        <el-table-column
                          prop="name"
                          show-overflow-tooltip
                          label="房间名称"
                        >
                        </el-table-column>
                        <el-table-column label="产品个数">
                          <template slot-scope="cscope">
                            {{ cscope.row.productNumber }}
                          </template>
                        </el-table-column>

                        <el-table-column prop="totalPrice" label="总价格">
                        </el-table-column>
                      </el-table>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  type="index"
                  width="80"
                  label="#"
                ></el-table-column>
                <el-table-column
                  prop="name"
                  show-overflow-tooltip
                  label="楼层名称"
                >
                </el-table-column>
                <el-table-column label="房间个数">
                  <template slot-scope="scope">
                    {{ scope.row.roomLength }}
                  </template>
                </el-table-column>
                <el-table-column prop="allProductNum" label="产品个数">
                </el-table-column>
                <el-table-column prop="totalPrice" label="总报价">
                </el-table-column>
                <el-table-column prop="remark" label="备注"> </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>

        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="name" label="点位图设计方案名称">
        </el-table-column>
        <el-table-column prop="createTime" label="制表时间"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column width="380" label="编辑">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              type="primary"
              @click="openDownLoadPdfDialog(scope.row.id)"
              >下载方案<i class="el-icon-arrow-down el-icon--right"></i></el-button
            > -->
            <el-dropdown
              @command="handleCommand(scope, $event)"
              style="margin-right: 10px"
            >
              <el-button size="mini" type="primary"
                >下载方案<i class="el-icon-arrow-down el-icon--right"></i
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="PDF">PDF</el-dropdown-item>
                <el-dropdown-item command="Excel">Excel</el-dropdown-item>
                <el-dropdown-item command="PPT">PPT</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-table-column label="操作">
              <template slot-scope="scope">
                
              </template>
            </el-table-column> -->
            <el-button
              v-if="isAdmin"
              type="warning"
              size="mini"
              icon="el-icon-share"
              @click="openSharePage(scope.row.id)"
              >分享</el-button
            >
            <el-button
              size="mini"
              @click="pushEdit(scope.row.id)"
              icon="el-icon-edit"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deleteNowSln(scope.row.id)"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分享界面 -->
    <el-dialog width="64%" title="场景解决方案分享" :visible.sync="showShareDialogVisible">
      <template>
        <el-card>
        <div slot="header">
          <span>客户管理</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input
              v-model="userQueryFilter.keyWorlds"
              placeholder="请输入用户相关信息"
              clearable
            />
          </el-col>
          <el-button type="primary" @click="userflashTable">查询用户</el-button>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-table
            :data="userListData"
            stripe
            style="width: 100%">
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column prop="userName" label="用户名"> </el-table-column>
            <el-table-column  prop="customerName" label="客户备注名称">
            </el-table-column>
            <el-table-column prop="supplierOwinName" label="所属供应商">
            </el-table-column>
            <el-table-column prop="email" label="邮箱"> </el-table-column>
            <el-table-column prop="phone" label="电话"> </el-table-column>
            <el-table-column prop="company" label="公司"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link
                  icon="el-icon-share"
                  @click="shareNowDrag(scope.row)"
                  >分享</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-pagination
          style="margin-top: 20px; text-align: right"
          @size-change="userHandleSizeChange"
          @current-change="userHandleCurrentChange"
          :current-page="userQueryFilter.pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="userQueryFilter.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="userQueryFilter.total"
        >
        </el-pagination>
      </el-card>
      </template>

      <div slot="footer">
        <el-button type="primary" @click="showShareDialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>
    <el-dialog
      title="点位图设计方案Pdf下载"
      :visible.sync="downLoadPdfDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadPdfDialogVisible = false"
          >取消</el-button
        >
        <!-- <el-button
          type="primary"
          @click="downLoadDragOrder"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        > -->
        <el-button
          type="primary"
          @click="downLoadDragOrder"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="点位图设计方案Excel下载"
      :visible.sync="downLoadExcelDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadExcelDialogVisible = false"
          >取消</el-button
        >
        <!-- <el-button
          type="primary"
          @click="downLoadDragOrderExcel"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        > -->
        <el-button
          type="primary"
          @click="downLoadDragOrderExcel"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="点位图设计方案PPT下载"
      :visible.sync="downLoadPPTDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p> -->
      <p>请选择PPT模板（未选择则使用默认模板）</p>
      <div 
        style="margin-top:25px;display:flex;flex-wrap: wrap;max-width:520px;" 
        v-if="downLoadPPTDialogVisible" 
        v-loading="loading3">
        <div 
          v-for="(fileData, index) in uploadPPTUrlList" 
          :key="index" 
          @mouseover="hoverPPTIndex = index" 
          @mouseleave="hoverPPTIndex = null" 
          style="text-align:center;margin-left:20px;position:relative;">
          <img 
          v-if="selectedPPTIndex === index" 
          src="@/assets/drage_images/selected.png" 
          style="height:30px;position:absolute;right:0px;">
          <span 
            v-if="hoverPPTIndex === index&&fileData.userId!=0" 
            class="close-button" 
            @click="handleCloseClick(fileData.id)">
            <el-button 
            type="danger" 
            icon="el-icon-close" 
            circle 
            style="font-size:16px;padding:3px;"
            ></el-button>
          </span>
          <div 
            class="uploadlist_box" 
            @click="changeSelectPPT(fileData, index)" 
            :class="{ 'pptdata_changed': selectedPPTIndex === index }">
            <img v-if="index==0" src="@/assets/temp.png" alt="" style="height:140px;">
            <img v-else src="@/assets/ppt.png" alt="" style="height:140px;">
          </div>
          <p style="margin:15px;">{{fileData.pptName}}</p>
          <!-- <el-button type="" style="margin:15px;">预览</el-button> -->
        </div>
        <div class="uploadfile_box" @click="openUploadPPTDialog">
          <i slot="default" class="el-icon-plus" style="font-size:30px"></i>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadPPTDialogVisible = false"
          >取消</el-button
        >
        <!-- <el-button
          type="primary"
          @click="downLoadDragOrderPPT"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        > -->
        <el-button
          type="primary"
          @click="downLoadDragOrderPPT"
          >确 定</el-button
        >
      </div>
      <el-dialog
        width="600px"
        center
        :visible.sync="uploadPPTVisible"
        :append-to-body="true"
        title="上传PPT模板文件">
        <div>
          <span>上传PPT模板文件名：</span>
          <el-input v-model="pptFileName" placeholder="请输入模板文件名" style="width:300px;"></el-input>
        </div>
        

        <div style="margin-top:25px;">
          <!-- <input 
          ref="uploadppt" 
          type="file" 
          :accept="['.ppt', '.pptx'].join(',')"
          /> -->
          <input 
          ref="uploadppt" 
          style="display:none;"
          type="file" 
          :accept="['.pptx'].join(',')"
          @change="handleFileUpload"
          />
          <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="triggerFileInput"
            >上传PPT模板</el-button
          >
          <p v-if="uploadFileName==''">当前未上传文件</p>
          <p v-else>{{uploadFileName}}</p>
        </div>
        <div slot="footer">
          <el-button @click="uploadPPTVisible = false">取消</el-button>
          <el-button type="primary" @click="pptFileUpload">确认</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </el-card>
</template>
<script>
import clip from '@/utils/clipboard'
import {
  GetdragOrderList,
  getDragOrderById,
  dragOrderDelete,
  uploadpptFile,
  getpptFileList,
  delpptDataById,
  DownLoadPDF,
  DownLoadExcel,
  DownLoadPPT,
  CheckUserRole,
  ShareOrder,
} from '@/api/DragOrder/index'
import { getUserList } from '@/api/user.js'
import { getProductById } from '../../../api/product'

import { Graph } from '@antv/x6'
import { Dnd } from '@antv/x6-plugin-dnd'
import { Transform } from '@antv/x6-plugin-transform'
import { Export } from '@antv/x6-plugin-export'

import JSZip from 'jszip'
import saveAs from 'file-saver'

export default {
  data() {
    return {
      isAdmin: false,
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      // 表格数据
      tableData: [],
      //分享
      showShareDialogVisible: false,
      //用户列表
      userListData: [],
      // 用户筛选条件
      userQueryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      //当前方案Id
      nowDragOrderId: 0,
      loading: false,
      loading2: false,
      // fullscreenLoading: false,

      //查看方案图纸
      orderData: {},
      floorList: [],
      graph: null,
      dnd: null,
      parentNode: null,
      childNode: [],
      jsonData: null,
      nowSelectFloor: {},
      nowSelectDragOrder: {},

      // 确认下载弹出框是否可见
      //Pdf
      downLoadPdfDialogVisible: false,
      //Excel
      downLoadExcelDialogVisible: false,
      //PPT
      downLoadPPTDialogVisible: false,
      ImgBaseList: [],
      
      pptFileName: '',
      uploadPPTUrlList: [],
      uploadPPTVisible: false,
      nowSelectPPTData: {},
      selectedPPTIndex: null,
      uploadFileName: '',
      hoverPPTIndex: null,
      loading3: false,
    }
  },
  created() {
    this.GetdragOrder()
    this.getpptFileListData()
  },
  mounted() {
    this.init();
    this.checkUser();
  },
  methods: {
    async init() {
      if (this.graph) {
        this.graph.dispose()
        this.graph = null
      }
      this.graph = new Graph({
        container: document.getElementById('container'),
        autoResize: true,
        panning: true,
        mousewheel: true,
        background: {
          color: '#F2F7FA',
        },
        grid: {
          visible: true,
          type: 'doubleMesh',
          args: [
            {
              color: '#eee', // 主网格线颜色
              thickness: 1, // 主网格线宽度
            },
            {
              color: '#ddd', // 次网格线颜色
              thickness: 1, // 次网格线宽度
              factor: 4, // 主次网格线间隔
            },
          ],
        },
        translating: {
          restrict(view) {
            if (view) {
              const cell = view.cell
              if (cell.isNode()) {
                const parent = cell.getParent()
                if (parent) {
                  return parent.getBBox()
                }
              }
            }
          },
        },
      })

      const resizingOptions = {
        enabled: true,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      const rotatingOptions = {
        enabled: false,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      this.graph
      .use(
        new Transform({
          resizing: resizingOptions,
          rotating: rotatingOptions,
        })
      )
      // 导出插件
      .use(new Export())

      // if (this.jsonData != {} && this.jsonData.cells != undefined) {
      //   this.graph.clearCells()
      //   this.graph.off('render:done'); // 移除旧的监听器
      //   this.graph.fromJSON(this.jsonData)
      //   this.renderDonePromise = new Promise((resolve, reject) => {
      //     this.graph.on('render:done', () => {
      //       // 当事件触发时，调用 resolve
      //       console.log('渲染完成');
      //       resolve(true);
      //     });
      //   });

      //   this.parentNode = this.graph.getNodes()[0]
      //   // console.log('1212parentNode', this.graph.toJSON())
      //   let childCells = []
      //   let cellList = this.graph.toJSON()
      //   cellList.cells.forEach((cell) => {
      //     if (cell.zIndex != 1) {
      //       childCells.push(cell)
      //     }
      //   })
      //   this.childNode = childCells
      // }
    },
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    //获取点位图设计方案列表
    async GetdragOrder() {
      let { data } = await GetdragOrderList(this.queryFilter)
      console.log(data)
      this.tableData = data.data
      this.queryFilter.total = data.total
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getSolution()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getSolution()
    },
    //设计方案条件查询
    getSolution() {
      this.GetdragOrder()
    },
    async getUserListData() {
      let { data } = await getUserList(this.userQueryFilter);
      this.userListData = data.data;
      this.userQueryFilter.total = data.total;
      // this.queryFilter.pageIndex = data.pageIndex
      // this.queryFilter.pageSize = data.pageSize
    },
    // 条件查询用户
    userflashTable() {
      this.userQueryFilter.pageIndex = 1
      this.getUserListData()
    },
    // 分页大小更改
    userHandleSizeChange(value) {
      this.userQueryFilter.pageSize = value
      this.getUserListData()
    },
    // 当前索引更改
    userHandleCurrentChange(val) {
      this.userQueryFilter.pageIndex = val
      this.getUserListData()
    },
    //选择下载PDF或者Excel
    handleCommand(scope, $event) {
      // console.log(scope)
      // console.log($event)
      if ($event == 'PDF') {
        this.openDownLoadPdfDialog(scope.row.id)
      }
      if ($event == 'Excel') {
        console.log($event)
        this.openDownLoadExcelDialog(scope.row.id)
      }
      if ($event == 'PPT') {
        console.log($event)
        this.openDownLoadPPTDialog(scope.row.id)
      }
    },
    // 前往新增页面
    pushAddPage() {
      // this.$router.push({ path: '/Solution/SlnDesign' })
      this.$router.push({ path: '/drageOrder' })
    },
    // 获取拼接之后的数据
    async expandChange(row, expandedRows) {
      this.loading = true
      let { data } = await getDragOrderById(row.id)

      const promises = this.tableData.map(async (item, index, arr) => {
        if (item.id == row.id) {

          const promises = data.floors.map(async (floor) => {

            floor.roomLength = floor.rooms.length

            let allProductNum = 0

            const promises = floor.rooms.map(async (room) => {

              let arrayList = []

              let productNumber = 0

              const promises = room.products.map(async (product) => {
                try {
                  let id = product.productId

                  let { data } = await getProductById(id)

                  product.productName = data.productName
                  product.productBrandName = data.productBrandName

                  let arrayData = {}

                  arrayData = { ...product }

                  if (arrayList.length > 0) {

                    if (
                      arrayList.some(
                        (item) =>
                          item.productId === arrayData.productId &&
                          arrayData.productModel === item.productModel &&
                          item.productSpec === arrayData.productSpec
                      )
                    ) {
                      let adata = arrayList.find(
                        (item) =>
                          item.productId === arrayData.productId &&
                          arrayData.productModel === item.productModel &&
                          item.productSpec === arrayData.productSpec
                      )
                      adata.number += arrayData.productNumber
                      productNumber += arrayData.productNumber
                      adata.allPrice = adata.number * adata.productPrice
                    } else {
                      arrayData.number = arrayData.productNumber
                      productNumber += arrayData.productNumber

                      arrayData.allPrice =
                        arrayData.number * arrayData.productPrice
                      arrayList.push(arrayData)
                    }
                  } else {
                    arrayData.number = arrayData.productNumber

                    productNumber = arrayData.productNumber

                    arrayData.allPrice =
                      arrayData.number * arrayData.productPrice
                    arrayList.push(arrayData)
                  }
                } catch (error) {
                  console.error('Error fetching product:', error)
                  this.$message.error(error)
                  // 可以选择如何处理错误，例如记录日志或者抛出异常
                }
              })

              await Promise.all(promises)

              room.products = arrayList
              let allPrice = 0

              arrayList.forEach((item) => {
                allPrice += item.allPrice
              })

              room.productNumber = productNumber

              allProductNum += productNumber

              room.totalPrice = allPrice
            })
            await Promise.all(promises)

            let allPrice = 0

            floor.rooms.forEach((room) => {
              allPrice += room.totalPrice
            })

            floor.allProductNum = allProductNum
            floor.totalPrice = allPrice
          })
          await Promise.all(promises)

          arr[index].floors = data.floors
        }
      })
      await Promise.all(promises)

      console.log('this.tableData', this.tableData)

      this.loading = false
    },
    // 获取系统详情统计
    getSystemDetialSummaries(param) {
      console.log(param)
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '统计'
          return
        }

        if (index === 4 || index === 5) {
          if (!data) {
            sums[index] = 'N/A'
          } else {
            const values = data.map((item) => {
              console.log(item)
              console.log(column)
              console.log(column.property)
              console.log(item[column.property])
              return Number(item[column.property])
            })

            if (!values.every((value) => isNaN(value))) {
              let val = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)

              sums[index] = index == 4 ? val : val.toFixed(2)
              sums[index] += index == 4 ? '件' : '元'
            } else {
              sums[index] = 'N/A'
            }
          }
          return
        }

        sums[index] = ''
      })

      this.$nextTick((_) => {
        this.$refs['systemSolutionDetailsTableRef'].doLayout()
      })

      return sums
    },
    //打开确认下载弹出框
    async openDownLoadPdfDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadPdfDialogVisible = true
    },
    async openDownLoadExcelDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadExcelDialogVisible = true
    },
    async openDownLoadPPTDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadPPTDialogVisible = true
    },
    //生成ImgBaseList
    async getImgBaseList() {
      this.ImgBaseList = []
      try {
        if (this.nowSelectDragOrder.name !== '') {
          for (let floor of this.nowSelectDragOrder.floors) {
            this.nowSelectFloor = floor
            this.jsonData = JSON.parse(this.nowSelectFloor.jsonData)
            this.graph.clearCells()
            this.graph.off('render:done'); // 移除旧的监听器
            let isLoaded=false;
            
            this.graph.on('render:done', () => {
              console.log('渲染完成');
              isLoaded=true;
            });
            let imageLoadingPromise = this.jsonData.cells.map((cell) => {
              if (cell.shape === 'image') {
                return new Promise((resolve) => {
                  const img = new Image()
                  img.onload = () => {
                    resolve()
                  }
                  img.src = cell.attrs.image['xlink:href']
                })
              } else {
                return Promise.resolve()
              }
            })
            await Promise.all(imageLoadingPromise)
            console.log("图片预加载完成")

            this.graph.fromJSON(this.jsonData);
            while(!isLoaded){
              console.log(isLoaded)
              await new Promise((resolve)=>{
                setTimeout(() => {
                  resolve()
                }, 1000);
              })
            }
            // await this.init() // 确保 init 方法是异步的
            // await this.renderDonePromise;

            const dataUri = await new Promise((resolve) => {
              this.graph.toPNG((dataUri) => {
                resolve(dataUri);
              });
            });
            let fileName =
              this.nowSelectDragOrder.name + '_' + this.nowSelectFloor.name
            console.log(dataUri)

            let data = {
              fileName: fileName,
              fileUrl: dataUri,
            }
            this.ImgBaseList.push(data)
            // 转换为 PNG
            // this.graph.toPNG((dataUri) => {
            //   // 处理数据 URI，例如创建一个下载链接
            //   const link = document.createElement('a');
            //   link.href = dataUri;
            //   link.download = 'myDiagram.png';
            //   console.log(link);
            //   link.click();
            // }, {
            //   background: '#ffffff', // 可选背景色
            //   padding: 10, // 可选边距
            // });
          }
        }
      } catch (error) {
        console.error('Error in openDownLoadPdfDialog:', error)
        // 可以在这里处理错误或者抛出异常
      }
    },
    //下载当前设计方案 Pdf
    async downLoadDragOrder() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      // let ImgBaseList = [];
      // try {
      //   if (this.nowSelectDragOrder.name !== "") {
      //     for (let floor of this.nowSelectDragOrder.floors) {
      //       this.nowSelectFloor = floor;
      //       this.jsonData = JSON.parse(this.nowSelectFloor.jsonData);
      //       await this.init(); // 确保 init 方法是异步的
      //       await new Promise(resolve => setTimeout(resolve, 1000));
      //       // this.graph.exportSVG('测试');
      //       this.graph.toSVG((dataUri) => {
      //         // 创建一个新的 Image 元素来展示 PNG 图片
      //         // const img = new Image();
      //         // img.src = dataUri;
      //         let fileName = this.nowSelectDragOrder.name+ "_" + this.nowSelectFloor.name;
      //         console.log(dataUri)

      //         let data = {
      //           fileName: fileName,
      //           fileUrl: dataUri
      //         }
      //         ImgBaseList.push(data)
      //         // document.body.appendChild(img);
      //       });
      //       // this.graph.toPNG((dataUri) => {
      //       //   // 创建一个新的 Image 元素来展示 PNG 图片
      //       //   const img = new Image();
      //       //   img.src = dataUri;
      //       //   console.log(img.src)
      //       //   document.body.appendChild(img);
      //       // });
      //     }
      //   }
      // } catch (error) {
      //     console.error("Error in openDownLoadPdfDialog:", error);
      //     // 可以在这里处理错误或者抛出异常
      // }
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      // setTimeout(() => {
      //   this.fullscreenLoading = false;
      //   this.downLoadPdfDialogVisible = false;
      // }, 1000);

      // this.fullscreenLoading = false;

      await DownLoadPDF(this.nowSelectDragOrder.id, this.ImgBaseList)
        .then((res) => {
          // 创建一个隐藏的<a>标签
          console.log(res)
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadPdfDialogVisible = false
    },
    //下载当前设计方案 Excel
    async downLoadDragOrderExcel() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadExcel(this.nowSelectDragOrder.id, this.ImgBaseList)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
          // this.downLoadExcelDialogVisible = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadExcelDialogVisible = false
    },
    // 下载当前设计方案 PPT
    async downLoadDragOrderPPT() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadPPT(this.nowSelectDragOrder.id, this.ImgBaseList, this.nowSelectPPTData)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
          // this.downLoadPPTDialogVisible = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadPPTDialogVisible = false
    },
    openUploadPPTDialog(){
      this.pptFileName = '';
      this.uploadPPTVisible = true;
    },
    async pptFileUpload(){
      // const uploadComponent = this.$refs.upload;
      if(this.pptFileName==''){
        this.$message.error("请输入PPT模板文件名")
        return
      }
      const file = this.$refs.uploadppt.files[0];
      if (file && file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('newFileName', this.pptFileName);

        let { data } = await uploadpptFile(formData)
        console.log("上传后返回数据", data)
        if(data==''){
          this.$message.error("上传PPT模板文件失败")
          return
        }
        this.getpptFileListData();
        this.$refs.uploadppt.value = "";
        this.uploadFileName = '';
        this.uploadPPTVisible = false;
        // this.uploadPPTUrl = data;
      } else {
        this.$message.error("请上传pptx文件！")
      }
    },
    async getpptFileListData(){
      let { data } = await getpptFileList();
      console.log('获取当前用户上传的文件数据', data)
      this.uploadPPTUrlList = data;
      this.nowSelectPPTData = this.uploadPPTUrlList[0];
      this.selectedPPTIndex = 0;
    },
    changeSelectPPT(pptData, index){
      console.log("当前选中pptData", pptData.id)
      console.log("当前nowSelectPPTData", this.nowSelectPPTData.id)
      if(this.nowSelectPPTData.id == pptData.id){
        this.nowSelectPPTData = {};
        this.selectedPPTIndex = null;
        return
      }
      this.nowSelectPPTData = pptData;
      console.log("nowSelectPPTData", this.nowSelectPPTData)
      this.selectedPPTIndex = index;
    },
    triggerFileInput() {
      this.$refs.uploadppt.click();
    },
    handleFileUpload(){
      const selectedFile = this.$refs.uploadppt.files[0]
      this.uploadFileName = selectedFile.name
      console.log("Selected File:", this.uploadFileName);
    },
    handleCloseClick(id) {
      // 在这里处理点击关闭按钮时的事件
      console.log("Close button clicked", id);
      this.$confirm('当前操作将会删除当前PPT模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        this.loading3 = true
        await delpptDataById(id)
        this.$message({
          type: 'success',
          message: '删除成功!',
        })
        this.getpptFileListData()
        this.loading3 = false
      })
      .catch((error) => {
        this.$message({
          type: 'info',
          message: '删除失败',
        })
        this.loading3 = false
      })
    },
    // 下载当前方案的图纸
    async downLoadAntV(id) {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      let { data } = await getDragOrderById(id)
      this.nowSelectDragOrder = data

      try {
        if (this.nowSelectDragOrder.name !== '') {
          const zip = new JSZip()
          for (let floor of this.nowSelectDragOrder.floors) {
            this.nowSelectFloor = floor
            this.jsonData = JSON.parse(this.nowSelectFloor.jsonData)
            await this.init() // 确保 init 方法是异步的
            await new Promise((resolve) => setTimeout(resolve, 1000))
            let fileName =
              this.nowSelectDragOrder.name +
              '-' +
              this.nowSelectFloor.name +
              '.svg'
            // this.graph.exportSVG(fileName);
            this.graph.toSVG((dataUri) => {
              zip.file(fileName, dataUri)
            })
          }
          let zipName = this.nowSelectDragOrder.name + '.zip'
          zip
            .generateAsync({ type: 'blob' })
            .then((blob) => {
              saveAs(blob, zipName)
            })
            .catch((err) => {
              console.log('Failed to generate ZIP file:', err)
            })
        }
      } catch (error) {
        console.error('Error in downLoadAntV:', error)
        // 可以在这里处理错误或者抛出异常
      }
      console.log('下载成功！')

      setTimeout(() => {
        // this.fullscreenLoading = false
        loading.close();
      }, 1000)
    },

    //删除当前设计方案
    deleteNowSln(id) {
      this.$confirm('当前操作将会删除当前解决方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.loading2 = true
          await dragOrderDelete(id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.GetdragOrder()
          this.loading2 = false
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
    },
    // 检查角色是否有分享的权限
    async checkUser(){
      let { data } = await CheckUserRole();
      this.isAdmin = data;
      console.log('是否是管理员', this.isAdmin)
      if(this.isAdmin){
        this.getUserListData()
      }
    },
    openSharePage(id){
      console.log(id);
      this.nowDragOrderId = 0;
      this.nowDragOrderId = id;
      this.showShareDialogVisible = true;
    },
    shareNowDrag(data){
      console.log(data)
      this.$confirm('是否对当前方案进行分享?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
      .then(async () => {
        let userId = data.id;
        // let userName = data.userName;
        let orderId = this.nowDragOrderId;
        await ShareOrder(userId,orderId)
        this.showShareDialogVisible = false;
        this.$message({
          type: 'success',
          message: '分享成功!',
        })
        this.GetdragOrder()
      })
      .catch((error) => {
        this.$message({
          type: 'info',
          message: '分享失败',
        })
      })
    },
    // 前往编辑页面
    pushEdit(id) {
      // this.$router.push({ path: '/Solution/dragOrderEdit/' + id })
      this.$router.push({path: '/dragOrderEdit/' + id })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/dragorder.scss';
.expand_box {
  padding: 10px 20px;
  box-sizing: border-box;
}
#container {
  width: 100%;
  height: 100%;
}
.close-button{
  position:absolute;
  top: -10px;
  right: -10px;
}
.uploadlist_box{
  width:140px;
  height:140px;
  border: 1px solid #DDDDDD;
  border-radius:10px;
  display:flex;
  align-items:center;
  overflow: hidden;
  &:hover{
    cursor:pointer;
    border-color: #8c50ff;
  }
}
.pptdata_changed {
  /* 点击后的样式 */
  border: 2px solid #8c50ff;
}
.uploadfile_box{
  width:140px;
  height:140px;
  margin-left: 20px;
  border: 1px dashed #DDDDDD;
  border-radius:10px;
  display:flex;
  justify-content:center;
  align-items:center;
  &:hover{
    cursor:pointer;
    border-color: #8c50ff;
  }
}
</style>
