<template>
  <div>
    <div class="header">
      <h3>创建任务</h3>
      <el-form size="mini" style="width:120vh;height:30px;margin-left:20px;">
        <el-row>
          <el-col :span="6">
            <el-form-item label="所属项目">
              <el-select v-model="projectValue" clearable placeholder="请选择" style="width:16vh;" size="mini">
                <div style="width:100%;display:flex;align-items:center;justify-content:center;margin-bottom:5px;">
                  <el-button icon="el-icon-plus" @click="openCreateProjectDialog">新建项目</el-button>
                </div>
                <el-option
                  v-for="item in projectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="处理人">
              <template>
                <el-select
                  v-model="puserValue"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="premoteMethod"
                  :loading="loading"
                  style="width:16vh;" size="mini">
                  <!-- style="width:16vh;" size="mini" @change="puserSelectChange"> -->
                  <el-option
                    v-for="item in puserOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="优先级">
              <el-select v-model="levelValue" clearable placeholder="请选择" style="width:16vh;" size="mini">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核人">
              <template>
                <el-select
                  v-model="userValue"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  style="width:16vh;" size="mini">
                  <el-option
                    v-for="item in userOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
          
        </el-row>
      </el-form>
      <el-button type="primary" @click="returnListPage">取消</el-button>
    </div>
    <div class="main_box">
      <div>
        <QuillEditor 
          ref="quillEditor" 
          @textChange="editorTextChange" 
          @htmlChange="editorHtmlChange" 
          :oldContent="newWorkForm.jsonData" 
          style="min-height:460px;"/>
        <div style="display:flex;justify-content:right;padding: 20px;">
          <el-button class="save_btn" @click="saveWorkText">保存并继续创建</el-button>
          <el-button type="primary" class="finish_btn" @click="saveNewWork">完成</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="新建项目"
      :visible.sync="createProjectState"
      width="30%">
      <el-input v-model="newProjectName" placeholder="新建项目名"></el-input>
      <el-checkbox v-model="isChecked">创建后自动选择</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createProjectState = false">取 消</el-button>
        <el-button type="primary" @click="addNewProject()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import QuillEditor from '@/components/Editor/index.vue'
  import { getWorkProjectList, createNewProject, createNewWork } from '../../api/workManage'
  import { getUserOptions } from '../../api/user'
  export default {
    name:'',
    props:[''],
    data () {
      return {
        loading: false,
        newWorkForm: {
          jsonData: '',
          workLevel: 0,
          projectId: null,
          textData: '',
          userId: 0,
          puserId: 0,
        },
        levelOptions: [{
          value: 1,
          label: '低'
        }, {
          value: 2,
          label: '中'
        }, {
          value: 3,
          label: '高'
        }],
        projectOptions: [],
        puserOptions: [],
        userOptions: [],
        levelValue: '',
        userValue: '',
        puserValue: '',
        projectValue: '',
        newProjectName: '',
        isChecked: false,
        createProjectState: false,
        editorHtml: null,
        editorText: null,
      };
    },

    components: {
      QuillEditor
    },

    created(){
      this.getWorkProjectOptions();
      this.getoldpuserOptions();
    },

    computed: {},

    beforeMount() {},

    mounted() {},

    methods: {
      editorTextChange(data){
        console.log('editorTextChange',data)
        this.editorText = data;
        // this.editorText = JSON.stringify(data);
        console.log('editorText',this.editorText)

      },
      editorHtmlChange(data){
        console.log('editorHtmlChange',data)
        this.editorHtml = data;
        // this.editorHtml = JSON.stringify(data);
        console.log('editorHtml',this.editorHtml)
      },

      // 获取工作项目列表
      async getWorkProjectOptions(){
        this.projectOptions = [];
        let { data } = await getWorkProjectList();
        // console.log(data)
        data.forEach(element => {
          let projectData = {};
          projectData.value = element.id;
          projectData.label = element.projectName;
          this.projectOptions.push(projectData);
        });
        // console.log('this.projectOptions', this.projectOptions)
        // console.log(this.isChecked)
        let pvalue = sessionStorage.getItem("projectValue");
        console.log(pvalue)
        if(pvalue!=null&&pvalue!=""){
          this.projectValue = parseInt(pvalue)
        }
        if(this.isChecked){
          this.projectValue = this.projectOptions[this.projectOptions.length-1].value;
          // console.log(this.projectValue)
        }
      },
      // 获取上个页面选择的处理人
      getoldpuserOptions(){
        let puserLabel = sessionStorage.getItem("puserLabel")
        let puserValue = sessionStorage.getItem("puserValue")
        // this.puserValue = puserValue;
        if(puserLabel!=null&&puserValue!=null&&puserLabel!=""&&puserValue!=""){
          this.puserValue = puserValue;
          let puserData = {};
          puserData.value = puserValue;
          puserData.label = puserLabel;
          this.puserOptions.push(puserData)
        }
      },
      // 获取到查询的处理人列表
      async getpOptions(query){
        console.log(111111111)
        this.puserOptions = [];
        // let key = '';
        let key = query;
        let { data } = await getUserOptions(key);
        console.log('getpOptions', data)
        data.forEach(element => {
          let pUserData = {};
          pUserData.value = element.id;
          pUserData.label = element.userName;
          this.puserOptions.push(pUserData);
        });
        console.log('this.puserOptions', this.puserOptions)
      },
      // 获取到查询的审核人列表
      async getOptions(query){
        console.log(111111111)
        this.userOptions = [];
        // let key = '';
        let key = query;
        console.log('key', key)
        let { data } = await getUserOptions(key);
        console.log('getOptions', data)
        data.forEach(element => {
          let userData = {};
          userData.value = element.id;
          userData.label = element.userName;
          this.userOptions.push(userData);
        });
        console.log('this.userOptions', this.userOptions)
      },
      puserSelectChange(value){
        console.log('selectedItem', value)
        const selectedItem = this.puserOptions.find(option => option.value === value);
        if (selectedItem) {
            console.log('Selected Label:', selectedItem.label);
            sessionStorage.setItem("puserLabel", selectedItem.label);
        } else{
          sessionStorage.setItem("puserLabel", "");
        }
      },
      // 查询处理人
      premoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          this.getpOptions(query).then(()=>{
            this.loading = false;
            this.options = this.puserOptions.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          })
        } else {
          this.options = [];
        }
      },
      // 查询审核人
      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          this.getOptions(query).then(()=>{
            this.loading = false;
            this.options = this.userOptions.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          })
        } else {
          this.options = [];
        }
      },
      // 添加新项目
      async addNewProject(){
        console.log('创建新项目', this.newProjectName)
        if(this.newProjectName==''){
          this.$message.error('新项目的名字不能为空')
          return
        }
        let data = {
          projectName: this.newProjectName
        }
        await createNewProject(data)
        this.getWorkProjectOptions();
        this.createProjectState = false;
      },
      // 打开添加新项目弹框
      openCreateProjectDialog(){
        this.isChecked = false;
        this.newProjectName = '';
        this.createProjectState = true;
      },
      // 返回工作任务列表页
      returnListPage(){
        this.$router.push({path: '/workManageMent/index'})
      },
      // 完成工作任务的创建
      async saveNewWork(){
        await this.saveWorkText();
        sessionStorage.setItem("projectValue", '')
        sessionStorage.setItem("puserValue", '')
        sessionStorage.setItem("puserLabel", '')
        this.$router.push({path: '/workManageMent/index'})
      },
      // 创建新的工作任务 保存并继续创建
      async saveWorkText(){
        if(this.editorHtml==null||this.editorHtml==''){
          this.$message.error("任务内容不能为空！")
          return
        }
        if(this.projectValue==null||this.projectValue==''){
          this.$message.error("未选择所属项目，请选择后重试")
          return
        }
        if(this.puserValue==null||this.puserValue==''){
          this.$message.error("未选择处理人，请选择后重试")
          return
        }
        if(this.levelValue==null||this.levelValue==''){
          this.$message.error("未选择优先级，请选择后重试")
          return
        }
        if(this.userValue==null||this.userValue==''){
          this.$message.error("未选择审核人，请选择后重试")
          return
        }
        
        this.newWorkForm.jsonData = this.editorHtml;
        this.newWorkForm.textData = this.editorText;
        this.newWorkForm.workLevel = this.levelValue;
        this.newWorkForm.projectId = this.projectValue;
        this.newWorkForm.userId = this.userValue;
        this.newWorkForm.puserId = this.puserValue;
        console.log("创建新的工作任务", this.newWorkForm)
        console.log(this.newWorkForm.jsonData)
        await createNewWork(this.newWorkForm)
        this.$refs.quillEditor.clear();
      },
    },

    watch: {
      projectValue(newValue,oldValue){
        // console.log('newValue', newValue)
        if(newValue!=null){
          sessionStorage.setItem("projectValue", newValue)
        }
      },
      puserValue(newValue,oldValue){
        // console.log('newValue', newValue)
        sessionStorage.setItem("puserValue", newValue);
        const selectedItem = this.puserOptions.find(option => option.value === newValue);
        if (selectedItem) {
            console.log('Selected Label:', selectedItem.label);
            sessionStorage.setItem("puserLabel", selectedItem.label);
        } else{
          sessionStorage.setItem("puserLabel", "");
        }
      },
    }

  }

</script>
<style lang='scss' scoped>
@import '@/styles/dragorder.scss';
.header{
  width:100%;
  background-color:#ffffff;
  height:10vh;
  border-radius:10px;
  margin-bottom:20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.main_box{
  width:100%;
  background-color:#ffffff;
  // height:72vh;
  border-radius:10px;
}
.save_btn{
  background-color: orange !important;
  color: #ffffff;
  border-color: rgb(230, 154, 15);
}
</style>