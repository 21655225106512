<template>
  <div class="app-content flex-box" @click="showScaleOptions=false">
    <div class="header">
      <div class="title w280">解决方案管理/{{ title }}</div>
      <div class="tools">
        <div class="tools_box">
          <span class="name">
            <el-button
              type="text"
              icon="el-icon-edit"
              style="color: #8c50ff"
              @click="openRenamePageNameDialog"
            ></el-button>
            {{ pageData.name }}
          </span>
          <span class="tools_btn" disabled v-if="!canUndoState">
            <img
              width="20"
              height="18"
              fit="contain"
              :src="require('@/assets/drage_images/back0.png')"
            />
            <div>后退</div>
          </span>
          <span class="tools_btn" @click="pageUndo" v-if="canUndoState">
            <img
              width="20"
              height="18"
              fit="contain"
              :src="require('@/assets/drage_images/back.png')"
            />
            <div>后退</div>
          </span>
          <span class="tools_btn" disabled v-if="!canRedoState">
            <img
              width="20"
              height="18"
              fit="contain"
              :src="require('@/assets/drage_images/go0.png')"
            />
            <div>前进</div>
          </span>
          <span class="tools_btn" @click="pageRedo" v-if="canRedoState">
            <img
              width="20"
              height="18"
              fit="contain"
              :src="require('@/assets/drage_images/go.png')"
            />
            <div>前进</div>
          </span>
          <span class="tools_btn" @click="addRectNode">
            <img
              width="20"
              height="18"
              fit="contain"
              :src="require('@/assets/drage_images/shap.png')"
            />
            <div>形状</div>
          </span>
          <span class="tools_btn" @click="showTextDialog = true">
            <img
              width="20"
              height="18"
              fit="contain"
              :src="require('@/assets/drage_images/fontfamely.png')"
            />
            <div>字符</div>
          </span>

          <span class="tools_btn" @click="getGraphData" v-if="false">
            <img
              width="20"
              height="18"
              fit="contain"
              :src="require('@/assets/drage_images/fontfamely.png')"
            />
            <div>打印JSON</div>
          </span>
        </div>

        <div class="tools_box">
          <span class="tools_btn" @click="saveHandler">
            <img
              width="20"
              height="20"
              :src="require('@/assets/drage_images/save.png')"
            />
            <div>保存</div>
          </span>
          <span class="tools_btn" @click="createHandler">
            <img
              width="20"
              height="20"
              :src="require('@/assets/drage_images/generate.png')"
            />
            <div>生成</div>
          </span>
          <span class="tools_btn" @click="exitPage">
            <img
              width="20"
              height="20"
              :src="require('@/assets/drage_images/goout.png')"
            />
            <div>退出</div>
          </span>
        </div>
      </div>
      <div class="w280"></div>
    </div>
    <div class="full_box page_box">
      <div v-show="showFloor" class="w280 clo_flex_box">
        <div class="floor_box br4">
          <div class="floor_title">
            <span class="title">楼层图纸</span>
            <span class="add_floor_btn" @click="addFloorDialog = true"
              >+新建楼层</span
            >
          </div>
          <div class="floor_items">
            <el-scrollbar style="height: 100%">
              <div v-if="floorList.length <= 0" class="floor_empty">
                <span>暂无数据</span>
              </div>
              <div
                class="item"
                v-for="i in floorList"
                :key="i.id"
                :class="{ active: i.isChecked }"
                @click="changeFloor(i.id)"
              >
                <img width="20" height="20" :src="i.fileUrl" alt="" />
                <span class="name" :title="i">{{ i.name }}</span>
                <span class="delete_btn">
                  <!-- openRenameFloorNameDialog -->
                  <el-button
                    type="text"
                    style="color: #8c50ff"
                    icon="el-icon-edit"
                    size="mini"
                    @click.stop="openRenameFloorNameDialog(i)"
                  ></el-button>
                  <el-button
                    type="text"
                    style="color: #8c50ff"
                    icon="el-icon-delete"
                    size="mini"
                    @click.stop="deleteFloor(i.id)"
                  ></el-button>
                </span>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="room_box br4">
          <div class="room_title">
            <span class="title">图纸清单</span>
            <span
              @click="openAddRoomDialog"
              class="add_room_btn"
              :class="{ disabled: !canCreateRoom }"
              >+创建房间</span
            >
          </div>

          <div class="room_items">
            <el-scrollbar style="height: 100%">
              <div v-if="roomList.length <= 0" class="room_empty">
                <span>暂无数据</span>
              </div>
              <div
                class="item"
                v-for="i in roomList"
                :key="i.id"
                @click="changeRoom(i.id)"
              >
                <div class="room_name" :class="{ active: i.isChecked }">
                  <i
                    style="cursor: pointer"
                    class="hover_show"
                    :class="[
                      i.isFolded
                        ? 'el-icon-caret-bottom'
                        : 'el-icon-caret-right',
                    ]"
                    @click.stop="i.isFolded = !i.isFolded"
                  ></i>
                  <span class="name" :title="i.name"
                    >{{ i.name }}({{ sumRoomProductCount(i) }})</span
                  >
                  <div class="hover_show delete_btn">
                    <!--openEditRoomDialog-->
                    <el-button
                      type="text"
                      style="color: #8c50ff"
                      icon="el-icon-edit"
                      size="mini"
                      @click.stop="openEditRoomDialog(i)"
                    ></el-button>
                    <el-button
                      type="text"
                      style="color: #8c50ff"
                      icon="el-icon-delete"
                      size="mini"
                      @click.stop="deleteRoom(i.id)"
                    ></el-button>
                  </div>
                </div>
                <div class="room_list" v-show="i.isFolded">
                  <div
                    class="product_item"
                    v-for="j in i.products"
                    :key="j.cellId"
                  >
                    <img width="20" height="20" :src="j.productImage" />
                    <span
                      class="product_name"
                      :title="`${j.productName}(${j.productModel}/${j.productSpec})x${j.productNumber}`"
                      >{{ j.productName }}({{ j.productModel }}/{{
                        j.productSpec
                      }})x{{ j.productNumber }}</span
                    >
                    <span>x{{ j.productNumber }}</span>
                    <span class="hover_show">
                      <el-button
                        type="text"
                        style="color: #8c50ff"
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteRoomProduct(j.cellId)"
                      ></el-button>
                    </span>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>

      <div class="page">
        <div ref="pageRef" class="page_content">
          <SketchRule
            v-show="sketchRulerOptions.show"
            :lang="sketchRulerOptions.lang"
            :thick="sketchRulerOptions.thick"
            :scale="sketchRulerOptions.scale"
            :width="sketchRulerOptions.width"
            :height="sketchRulerOptions.height"
            :startX="sketchRulerOptions.startX"
            :startY="sketchRulerOptions.startY"
            :shadow="sketchRulerOptions.shadow"
            :horLineArr="sketchRulerOptions.horLineArr"
            :verLineArr="sketchRulerOptions.verLineArr"
            :cornerActive="sketchRulerOptions.cornerActive"
            :palette="sketchRulerOptions.palette"
          >
          </SketchRule>
          <div id="container"></div>

          <div class="add_floor_box" v-if="floorList.length === 0">
            <img
              :draggable="false"
              :src="require('@/assets/SlnDesign/empty.png')"
              width="200"
              style="margin: 20px; user-select: none"
            />
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="addFloorDialog = true"
              >新增楼层</el-button
            >
          </div>
        </div>
        <div class="page_tools">
          <el-tooltip
            class="item"
            effect="dark"
            content="显示/隐藏楼层房间列表"
            placement="top-start"
          >
            <div
              class="item"
              style="margin-right: auto; margin-left: 0"
              @click="showFloor = !showFloor"
            >
              <i :class="showFloor ? 'el-icon-s-fold' : 'el-icon-s-unfold'" />
              <!-- <img
                :src="require('@/assets/drage_images/map.png')"
                width="20"
                height="20"
              /> -->
            </div>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="楼层结构预览"
            placement="top-start"
          >
            <div class="item" @click="floorNodeDialogVisible = true">
              <img
                :src="require('@/assets/drage_images/map.png')"
                width="20"
                height="20"
              />
            </div>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            :content="showGrid ? '隐藏网格' : '显示网格'"
            placement="top-start"
          >
            <div class="item" @click="toggleGrid">
              <img
                :src="require('@/assets/drage_images/grid.png')"
                width="20"
                height="20"
              />
            </div>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            :content="sketchRulerOptions.show ? '显示标尺' : '隐藏标尺'"
            placement="top-start"
          >
            <div
              class="item"
              @click="sketchRulerOptions.show = !sketchRulerOptions.show"
            >
              <img
                :src="require('@/assets/drage_images/ruler.png')"
                width="20"
                height="20"
              />
            </div>
          </el-tooltip>

          <div class="item">
            <div class="presuppose_box" v-show="showScaleOptions">
              <div
                class="presuppose_item"
                v-for="(val, index) in scaleOptions"
                :key="index"
                @click="changeGraphScaleHandler(val.value)"
              >
                {{ val.label }}
              </div>
            </div>
            <el-button
              type="text"
              style="color: #333"
              icon="el-icon-minus"
              @click="stepGraphScallHandler(-0.1)"
            ></el-button>
            <span
              class="scale_value"
              @click.stop="showScaleOptions = !showScaleOptions"
              >{{ grapValue }}</span
            >
            <el-button
              type="text"
              style="color: #333"
              icon="el-icon-plus"
              @click="stepGraphScallHandler(0.1)"
            ></el-button>
          </div>

          <el-tooltip
            class="item"
            effect="dark"
            content="显示/隐藏商品列表"
            placement="top-start"
          >
            <div class="item" @click="showProduct = !showProduct">
              <i :class="showProduct ? 'el-icon-s-unfold' : 'el-icon-s-fold'" />
              <!-- <img
                :src="require('@/assets/drage_images/map.png')"
                width="20"
                height="20"
              /> -->
            </div>
          </el-tooltip>
        </div>
      </div>
      <!--商品列表-->
      <div v-show="showProduct" class="w280 product_box br4">
        <el-form size="mini">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item>
                <el-select
                  v-if="false"
                  clearable
                  v-model="productForm.brand"
                  placeholder="全部品牌"
                  @change="searchProduct"
                >
                  <el-option
                    v-for="(brand, index) in productBrandOptions"
                    :label="brand.label"
                    :value="brand.value"
                    :key="'brand' + index"
                  ></el-option>
                </el-select>

                <el-select
                  clearable
                  v-model="productForm.brand"
                  placeholder="全部品牌"
                  @change="searchProduct"
                  class="custom-select"
                  :popper-append-to-body="false"
                >
                  <div class="alphabet-column">
                    <div
                      :class="{ cu_disabled: letter.disabled }"
                      v-for="letter in pinyinGroupNames"
                      :key="letter.label"
                      @click="scrollToProvince(letter.label)"
                    >
                      {{ letter.label }}
                    </div>
                  </div>

                  <el-option-group
                    v-for="item in productGroupBrandOptions"
                    :key="item.groupName"
                    :label="item.groupName"
                  >
                    <el-option
                      v-for="brand in item.items"
                      :key="brand.value"
                      :label="brand.label"
                      :value="brand.value"
                      :ref="`brand${brand.value}Ref`"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-select
                  clearable
                  v-model="productForm.type"
                  placeholder="所有类型"
                  @change="searchProduct"
                >
                  <el-option
                    v-for="(type, index) in productTypeOptions"
                    :label="type.label"
                    :value="type.value"
                    :key="'type' + index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item style="position: relative">
              <el-input
                style="width: 84%"
                v-model="productForm.queryString"
                placeholder="产品名称/型号"
                clearable
                @keydown.enter.native="searchProduct"
              >
              <el-button slot="append" icon="el-icon-search" @click="searchProduct"></el-button>
              </el-input>

              <el-tooltip
                class="item"
                effect="dark"
                content="前往添加自有产品"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-first-aid-kit"
                  @click="openNewPage"
                  style="
                    font-size: 18px;
                    padding: 4px 6px;
                    position: absolute;
                    right: 0;
                  "
                ></el-button>
              </el-tooltip>

              <!-- <el-button><i class="el-icon-first-aid-kit" style="font-size:20px;"></i></el-button> -->
            </el-form-item>
          </el-row>

          <el-form-item>
            <el-checkbox
              @change="searchProduct"
              style="text-align: right"
              v-model="productForm.isStart"
              >只显示收藏的商品</el-checkbox
            >
          </el-form-item>
        </el-form>

        <div class="product_items" v-loading="loading">
          <el-scrollbar style="height: 100%">
            <div
              class="item product_item"
              v-for="(i, index) in productList"
              :key="i.produceId"
            >
              <div class="info">
                <img
                  @mousedown="(e) => startDrag(e, i)"
                  :title="
                    `${i.productName}(` + i.productModel + `)/${i.productSpec}`
                  "
                  :src="i.productImage"
                  width="50"
                  height="50"
                />
                <span class="price">¥ {{ i.productPrice }}</span>
              </div>
              <div class="info oper_box">
                <div
                  class="name"
                  :title="
                    `${i.productName}(` + i.productModel + `)/${i.productSpec}`
                  "
                >
                  {{ i.productName }}
                </div>
                <div class="model">
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-select
                        v-model="i.productModel"
                        size="mini"
                        placeholder="型号"
                      >
                        <el-option
                          v-for="(model, index) in i.modelOptions"
                          :label="model.label"
                          :value="model.value"
                          :key="'model' + index"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        v-model="i.productSpec"
                        size="mini"
                        placeholder="规格"
                        @change="(val) => changeSpecHandel(val, i)"
                      >
                        <el-option
                          v-for="(item, index) in i.attributeOptions"
                          :label="item.value"
                          :value="item.value"
                          :key="'attr' + index"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </div>
                <div class="number">
                  <el-input-number
                    v-model="productList[index].productNumber"
                    :min="1"
                    :precision="0"
                    style="width: 100px"
                    size="mini"
                  />

                  <img
                    v-if="i.isStart"
                    title="取消收藏"
                    @click="cancelStartProduct(i)"
                    style="
                      float: right;
                      vertical-align: middle;
                      margin-top: 4px;
                      cursor: pointer;
                    "
                    :src="require('@/assets/start.png')"
                    width="20"
                  />

                  <img
                    v-else
                    title="收藏"
                    @click="startProduct(i)"
                    style="
                      float: right;
                      vertical-align: middle;
                      margin-top: 4px;
                      cursor: pointer;
                    "
                    :src="require('@/assets/unstart.png')"
                    width="20"
                  />
                </div>
              </div>
            </div>

            <div class="more_btn" @click="loadingMoreProduct">
              {{
                productForm.total > productList.length
                  ? `加载更多(${this.productList.length}/${this.productForm.total})`
                  : '没有更多数据了'
              }}
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <!-- 方案更名 -->
    <el-dialog
      width="600px"
      :visible.sync="renamePageNameDialog"
      title="更改方案名称"
      center
    >
      <el-form v-model="renamePageNameForm" label-width="100px">
        <el-form-item label="方案名称">
          <el-input
            v-model="renamePageNameForm.name"
            placeholder="请输入方案名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="renamePageNameDialog = false">取消</el-button>
        <el-button type="primary" @click="renamePageNameSubmit">确定</el-button>
      </div>
    </el-dialog>

    <!-- 楼层更名 -->
    <el-dialog width="600px" :visible.sync="renameFloorNameDialog" center>
      <el-form v-model="renameFloorForm" label-width="100px">
        <el-form-item label="楼层名称">
          <el-input
            v-model="renameFloorForm.name"
            placeholder="请输入楼层名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="renameFloorNameDialog = false">取消</el-button>
        <el-button type="primary" @click="renameFloorNameSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 新建/编辑楼层 -->
    <el-dialog
      width="600px"
      :visible.sync="addFloorDialog"
      title="新建楼层"
      center
    >
      <el-form v-model="addFloorForm" label-width="100px">
        <el-form-item label="楼层名称">
          <el-input
            v-model="addFloorForm.name"
            placeholder="请输入楼层名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="楼层图纸">
          <input
            ref="fileInputRef"
            style="display: none"
            type="file"
            :accept="
              ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'].join(
                ','
              )
            "
            @change="fileInputHandle"
          />
          <el-image
            v-if="addFloorForm.image"
            fit="contain"
            :preview-src-list="[addFloorForm.image]"
            style="width: 100px; height: 100px"
            :src="addFloorForm.image"
          />
          <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="openFileDialog"
            >{{ !addFloorForm.image ? '上传图纸' : '更换图纸' }}</el-button
          >
        </el-form-item>
        <el-form-item label="支持格式">
          jpg、jpeg、png、svg (推荐使用svg格式的矢量图片)</el-form-item
        >
        <el-form-item label="图纸尺寸">
          <template class="size_select">
            <el-radio v-model="sizeRadioSelect" label="1">A4横板</el-radio>
            <el-radio v-model="sizeRadioSelect" label="2">A4竖版</el-radio>
            <el-radio v-model="sizeRadioSelect" label="3">原图尺寸</el-radio>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addFloorDialog = false">取消</el-button>
        <el-button type="primary" @click="addFloorSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 新建/编辑房间 -->
    <el-dialog
      width="600px"
      center
      :visible.sync="createRoomDialog"
      title="创建房间"
    >
      <el-form v-model="createRoomForm" label-width="100px">
        <el-form-item label="房间名称">
          <el-input
            v-model="createRoomForm.name"
            placeholder="请输入房间名称"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="createRoomDialog = false">取消</el-button>
        <el-button type="primary" @click="createRoomSubmit">确认</el-button>
      </div>
    </el-dialog>

    <!-- 插入文字 -->
    <el-dialog
      width="600px"
      center
      :visible.sync="showTextDialog"
      title="插入文字内容"
    >
      <el-form v-model="txtForm" label-width="100px">
        <el-form-item label="文字内容">
          <el-input v-model="txtForm.text" placeholder="请输入文字内容" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="showTextDialog = false">取消</el-button>
        <el-button type="primary" @click="addTextSubmit">确认</el-button>
      </div>
    </el-dialog>
    <!--生成文件-->
    <el-dialog
      width="600px"
      center
      :visible.sync="generateDialog"
      title="生成文件"
    >
      <!-- <el-form v-model="txtForm" label-width="100px">
        <el-form-item label="文字内容">
          <el-input v-model="txtForm.text" placeholder="请输入文字内容" />
        </el-form-item>
      </el-form> -->
      <span>选择生成的文件格式：</span>
      <el-select v-model="fileFormat" placeholder="请选择">
        <el-option
          v-for="item in formatOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <div
        style="
          margin-top: 25px;
          display: flex;
          flex-wrap: wrap;
          max-width: 520px;
        "
        v-if="fileFormat == 'PPT'"
        v-loading="loading2"
      >
        <div
          v-for="(fileData, index) in uploadPPTUrlList"
          :key="index"
          @mouseover="hoverPPTIndex = index"
          @mouseleave="hoverPPTIndex = null"
          style="text-align: center; margin-left: 20px; position: relative"
        >
          <!-- <i class="el-icon-close"></i> -->
          <img
            v-if="selectedPPTIndex === index"
            src="@/assets/drage_images/selected.png"
            style="height: 30px; position: absolute; right: 0px"
          />
          <span
            v-if="hoverPPTIndex === index && fileData.userId != 0"
            class="close-button"
            @click="handleCloseClick(fileData.id)"
          >
            <el-button
              type="danger"
              icon="el-icon-close"
              circle
              style="font-size: 16px; padding: 3px"
            ></el-button>
          </span>
          <div
            class="uploadlist_box"
            @click="changeSelectPPT(fileData, index)"
            :class="{ pptdata_changed: selectedPPTIndex === index }"
          >
            <img
              v-if="index == 0"
              src="@/assets/temp.png"
              alt=""
              style="height: 150px"
            />
            <img v-else src="@/assets/ppt.png" alt="" style="height: 150px" />
          </div>
          <p style="margin: 15px">{{ fileData.pptName }}</p>
          <!-- <el-button type="" style="margin:15px;">预览</el-button> -->
        </div>
        <div class="uploadfile_box" @click="openUploadPPTDialog">
          <i slot="default" class="el-icon-plus" style="font-size: 30px"></i>
        </div>
      </div>

      <div slot="footer">
        <el-button @click="generateDialog = false">取消</el-button>
        <!-- <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="fileGenerate">确认</el-button> -->
        <el-button type="primary" @click="fileGenerate">确认</el-button>
      </div>
      <el-dialog
        width="600px"
        center
        :visible.sync="uploadPPTVisible"
        :append-to-body="true"
        title="上传PPT模板文件"
      >
        <div>
          <span>上传PPT模板文件名：</span>
          <el-input
            v-model="pptFileName"
            placeholder="请输入模板文件名"
            style="width: 300px"
          ></el-input>
        </div>

        <div style="margin-top: 25px">
          <!-- <label for="file" style="position: absolute;">
            <el-button
              type="primary"
              icon="el-icon-upload2"
              @click="triggerFileInput"
              >上传PPT模板</el-button
            >
          </label> -->
          <input
            ref="uploadppt"
            style="display: none"
            type="file"
            :accept="['.pptx'].join(',')"
            @change="handleFileUpload"
          />
          <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="triggerFileInput"
            >上传PPT模板</el-button
          >
          <p v-if="uploadFileName == ''">当前未上传文件</p>
          <p v-else>{{ uploadFileName }}</p>
        </div>
        <div slot="footer">
          <el-button @click="uploadPPTVisible = false">取消</el-button>
          <el-button type="primary" @click="pptFileUpload">确认</el-button>
        </div>
      </el-dialog>
    </el-dialog>

    <!-- 保存表格数据 -->
    <el-dialog
      width="1240px"
      top="50px"
      title=""
      :visible.sync="saveDataDialog"
    >
      <div class="save_data_box">
        <span class="title">{{ pageData.name }}</span>
        <div class="list_box">
          <div class="tabs_title">
            <div
              @click="saveDataForm.type = 'room'"
              class="item"
              :class="{ active: saveDataForm.type === 'room' }"
            >
              房间
            </div>
            <div
              @click="saveDataForm.type = 'type'"
              class="item"
              :class="{ active: saveDataForm.type === 'type' }"
            >
              种类
            </div>
          </div>
          <!-- 房间分类 -->

          <div v-if="saveDataForm.type === 'room'" class="table_box">
            <div class="table_header_box">
              <div style="width: 250px">商品名称</div>
              <div style="width: 100px">品牌</div>
              <div style="width: 100px">型号</div>
              <div style="width: 100px">规格</div>
              <div style="width: 100px">价格</div>
              <div style="width: 150px">数量</div>
              <div style="width: 100px">小计</div>
              <div style="width: 100px">备注</div>
              <div style="width: 100px">操作</div>
            </div>

            <div class="full_box">
              <el-scrollbar style="height: 100%">
                <div
                  v-for="(room, index) in saveDataForm.roomData"
                  :key="index"
                >
                  <div class="room_label">{{ room.label }}</div>
                  <div
                    class="table_body_box"
                    v-for="(product, indey) in room.products"
                    :key="indey"
                  >
                    <div style="width: 250px">
                      <el-image
                        :src="product.productImage"
                        style="width: 50px"
                      />
                      <span>{{ product.productName }}</span>
                    </div>
                    <div style="width: 100px">
                      {{ getBrandData(product.brandId) }}
                    </div>
                    <div style="width: 100px">{{ product.productModel }}</div>
                    <div style="width: 100px">{{ product.productSpec }}</div>
                    <div style="width: 100px">{{ product.productPrice }}</div>
                    <div style="width: 150px">
                      <el-input-number
                        size="mini"
                        :min="1"
                        :precision="0"
                        style="width: 100px; padding: 0; margin: 0 auto"
                        v-model="product.productNumber"
                        @change="(val) => changeProductNumber(val, product)"
                      />
                    </div>
                    <div style="width: 100px">
                      {{ product.productNumber * product.productPrice }}
                    </div>
                    <div style="width: 100px">--</div>
                    <div style="width: 100px">
                      <el-button
                        size="mini"
                        type="danger"
                        @click="deleteTablueProduct(product)"
                        >删除</el-button
                      >
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>

          <div v-else class="table_box">
            <div class="table_header_box">
              <div style="width: 250px">商品名称</div>
              <div style="width: 100px">品牌</div>
              <div style="width: 100px">型号</div>
              <div style="width: 100px">规格</div>
              <div style="width: 100px">价格</div>
              <div style="width: 150px">数量</div>
              <div style="width: 100px">小计</div>
              <div style="width: 100px">区域</div>
              <div style="width: 100px">操作</div>
            </div>

            <div class="full_box">
              <el-scrollbar style="height: 100%">
                <div
                  v-for="(type, index) in saveDataForm.typeData"
                  :key="index"
                >
                  <div class="room_label">{{ type.label }}</div>
                  <div
                    class="table_body_box"
                    v-for="(product, indey) in type.products"
                    :key="indey"
                  >
                    <div style="width: 250px">
                      <el-image
                        :src="product.productImage"
                        style="width: 50px"
                      />
                      <span>{{ product.productName }}</span>
                    </div>
                    <div style="width: 100px">
                      {{ getBrandData(product.brandId) }}
                    </div>
                    <div style="width: 100px">{{ product.productModel }}</div>
                    <div style="width: 100px">{{ product.productSpec }}</div>
                    <div style="width: 100px">{{ product.productPrice }}</div>
                    <div style="width: 150px">
                      <el-input-number
                        size="mini"
                        :min="1"
                        style="width: 100px; padding: 0; margin: 0 auto"
                        :precision="0"
                        v-model="product.productNumber"
                        @change="(val) => changeProductNumber(val, product)"
                      />
                    </div>
                    <div style="width: 100px">
                      {{ product.productNumber * product.productPrice }}
                    </div>
                    <div style="width: 100px">{{ product.arae }}</div>
                    <div style="width: 100px">
                      <el-button
                        size="mini"
                        type="danger"
                        @click="deleteTablueProduct(product)"
                        >删除</el-button
                      >
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="operate_box">
          <div>
            <span>产品总额:</span>
            <span style="color: red; padding: 0 10px"
              >¥{{ saveDataForm.totalPrice }}</span
            >
          </div>
          <el-button type="primary" size="medium" @click="saveDataSubmit"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog width="500px" title="温馨提示" :visible.sync="tipVisible">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        "
      >
        <div>
          <img src="@/assets/power.png" width="50" style="margin-right: 20px" />
          <img src="@/assets/line.png" width="50" style="margin-left: 20px" />
        </div>
        <div style="margin: 20px 0">线材、电源产品，不要忘记添加呦</div>
        <div>
          <el-button type="primary" @click="tipKnowHandler">知道了</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 楼层结构图 -->
    <el-dialog title="楼层结构图" :visible.sync="floorNodeDialogVisible">
      <div class="floor_node_box">
        <div
          class="floor_item_box"
          v-for="(floor, index) in floorList"
          :key="index"
        >
          <div class="floor_name">
            <i class="el-icon-s-promotion" />{{ floor.name }}({{
              floor.rooms.length
            }})
          </div>
          <div class="room_node_box">
            <div
              class="room_item_box"
              v-for="(room, index) in floor.rooms"
              :key="index"
            >
              <div class="room_name">
                <i class="el-icon-s-home" />{{ room.name }}({{
                  sumRoomProductCount(room)
                }})
              </div>
              <div class="product_item_box">
                <div
                  class="product_item"
                  v-for="(product, index) in room.products"
                  :key="index"
                >
                  <img width="20" :src="product.productImage" />
                  <div>
                    {{ product.productName }}({{ product.productModel }}/{{
                      product.productSpec
                    }})x{{ product.productNumber }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pinyin from 'pinyin'

import {
  dragOrderAdd,
  dragOrderEdit,
  ImgUploadOSS,
  getDragOrderById,
  uploadeFloorFile,
  uploadpptFile,
  getpptFileList,
  delpptDataById,
  DownLoadPDF,
  DownLoadPDFByData,
  DownLoadExcel,
  DownLoadExcelByData,
  DownLoadPPT,
  DownLoadPPTByData,
} from '@/api/DragOrder/index'
import {
  getDrageOrderProduct,
  getAllBrand,
  getAllType,
  stratProduct,
  cancelStartProduct,
} from '@/api/product'

import { Graph } from '@antv/x6'
import { Dnd } from '@antv/x6-plugin-dnd'
import { Transform } from '@antv/x6-plugin-transform'
import { History } from '@antv/x6-plugin-history'
import { Export } from '@antv/x6-plugin-export'
import { Snapline } from '@antv/x6-plugin-snapline'
import { Clipboard } from '@antv/x6-plugin-clipboard'
import { Selection } from '@antv/x6-plugin-selection'
import { Keyboard } from '@antv/x6-plugin-keyboard'

import SketchRule from 'vue-sketch-ruler'
export default {
  components: {
    SketchRule,
  },
  data() {
    return {
      id: null,
      renamePageNameDialog: false,
      renamePageNameForm: {
        name: '',
      },
      renameFloorNameDialog: false,
      renameFloorForm: {
        id: null,
        name: '',
      },
      addFloorDialog: false,
      addFloorForm: {
        name: '',
        image: '',
      },
      // 楼层图纸
      // floorList: process.env.NODE_ENV === 'development' ? cachedData : [],
      floorList: [],
      createRoomDialog: false,
      createRoomForm: {
        id: null,
        name: '默认房间',
      },
      roomList: [],
      pageData: {
        name: '雪意®智能全宅设计方案',
        graph: null,
        dnd: null,
        rootNode: null,
      },
      // 页面类型/新增/编辑
      title: '新增拖拽图纸',
      // 尺寸侦听对象
      resizeObserver: null,
      // 标尺组件属性
      sketchRulerOptions: {
        show: true,
        lang: 'zh-CN',
        thick: 16,
        scale: 1,
        width: 0,
        height: 0,
        startX: 0,
        startY: 0,
        horLineArr: [],
        verLineArr: [],
        shadow: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        },
        cornerActive: false,
        palette: {
          bgColor: 'rgba(225,225,225, 0)',
          longfgColor: '#8c50ff',
          shortfgColor: '#8c50ff',
          fontColor: '#8c50ff',
          shadowColor: '#8c50ff',
          lineColor: '#8c50ff',
          borderColor: '#8c50ff',
          cornerActiveColor: '#8c50ff',
        },
      },
      // 是否显示缩放预设
      showScaleOptions: false,
      // 缩放预设
      scaleOptions: [
        {
          value: 0.25,
          label: '25%',
        },
        {
          value: 0.5,
          label: '50%',
        },
        {
          value: 0.75,
          label: '75%',
        },
        {
          value: 1,
          label: '100%',
        },
        {
          value: 2,
          label: '200%',
        },
        {
          value: 3,
          label: '300%',
        },
      ].reverse(),
      // 商品筛选表单
      productForm: {
        queryString: '',
        brand: null,
        type: null,
        pageIndex: 1,
        pageSize: 20,
        total: 0,
        isStart: false,
      },
      // 商品型号Options
      productBrandOptions: [],
      productGroupBrandOptions: [],
      // 商品类型Options
      productTypeOptions: [],
      loading: false,
      // 商品列表
      productList: [],
      // 保存表格
      saveDataDialog: false,
      // 保存表单数据
      saveDataForm: {
        type: 'room',
        roomData: [],
        typeData: [],
        totalPrice: 0,
      },
      //生成文件
      generateDialog: false,
      formatOptions: [
        {
          value: 'PDF',
          label: 'PDF',
        },
        {
          value: 'Excel',
          label: 'Excel',
        },
        {
          value: 'PPT',
          label: 'PPT',
        },
      ],
      fileFormat: 'PDF',
      ImgBaseList: [],
      copyNodeId: '',
      // fullscreenLoading: false,
      renderDonePromise: null,
      // 文字插入
      showTextDialog: false,
      txtForm: {
        text: '',
      },
      canUndoState: false,
      canRedoState: false,
      // 选择图纸尺寸 默认选择图片原本的尺寸
      sizeRadioSelect: '3',
      sizeState: true, //true 放大

      //上传ppt模板
      pptFileName: '',
      uploadPPTUrlList: [],
      uploadPPTVisible: false,
      nowSelectPPTData: {},
      selectedPPTIndex: null,
      hoverPPTIndex: null,
      uploadFileName: '',
      showCloseButton: false,
      loading2: false,
      // 是否是路由跳转
      isNavigate: false,
      // 是否提示了电源产品,线材
      isTip: false,
      tipVisible: false,
      type: 'save',
      showGrid: false,
      showFloor: true,
      showProduct: true,
      // 楼层结构图对话框
      floorNodeDialogVisible: false,
    }
  },
  computed: {
    // 图纸缩放比例值
    grapValue() {
      return (this.sketchRulerOptions.scale * 100).toFixed(0) + '%'
    },
    // 是否可以新建房间
    canCreateRoom() {
      return this.floorList.some((i) => i.isChecked)
    },
    pinyinGroupNames() {
      let code = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]

      let hasGroup = this.productGroupBrandOptions.map((item) => item.groupName)

      let result = code.map((item) => {
        return {
          label: item,
          disabled: !hasGroup.includes(item),
        }
      })

      console.log('result', result)

      return result
    },
  },
  created() {
    this.initProductOptions()
    this.getProductList()
    this.getpptFileListData()
  },
  mounted() {
    this.listenerResize()
    this.initGraph()
    // 监听页面是否是刷新
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeRouteLeave(to, from, next) {
    console.log('this.isNavigate', this.isNavigate)
    if (this.isNavigate) {
      // 如果正在退出页面，直接跳转
      next()
    } else {
      this.$confirm(
        '当前图纸尚未保存,退出将会丢失图纸信息是否确认退出?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnHashChange: false,
        }
      )
        .then(() => {
          next()
        })
        .catch(() => {
          next(false)
        })
    }
  },
  methods: {
    saveHandler() {
      this.type = 'save'
      if (this.isTip) {
        this.saveDataDialog = true
        return
      }
      this.tipVisible = true
    },
    createHandler() {
      this.type = 'create'
      if (this.isTip) {
        this.generateDialog = true
        return
      }
      this.tipVisible = true
    },
    tipKnowHandler() {
      this.isTip = true
      this.tipVisible = false
      if (this.type == 'save') {
        this.saveDataDialog = true
      } else {
        this.generateDialog = true
      }
    },
    // 加载数据
    async loadingData() {
      let id = this.$route.params.id

      if (!id) {
        return
      }

      this.id = parseInt(id)

      this.title = '编辑拖拽图纸'

      let { data } = await getDragOrderById(this.id)

      this.pageData.name = data.name

      let rederData = data.floors.map((f) => {
        let jsonData = JSON.parse(f.jsonData)

        let products = f.rooms.reduce((pre, cur) => {
          pre = pre.concat(
            cur.products.map((p) => {
              return {
                ...p,
                roomId: cur.id,
                roomName: cur.name,
              }
            })
          )
          return pre
        }, [])

        for (const cell of jsonData.cells) {
          let productItem = products.find((i) => i.cellId == cell.id)
          if (productItem) {
            cell.data.productId = parseInt(productItem.productId)
            cell.data.productImage = productItem.productImage
            cell.data.productName = productItem.productName
            cell.data.productSpec = productItem.productSpec
            cell.data.productSpec = productItem.productSpec
            cell.data.productNumber = productItem.productNumber
            cell.data.productModel = productItem.productModel
            cell.data.productPrice = productItem.productPrice
            cell.data.brandId = productItem.brandId
            cell.data.typeId = productItem.typeId
            cell.data.roomId = productItem.roomId
            cell.data.roomName = productItem.roomName
          }
        }

        let floor = {
          id: f.id,
          name: f.name,
          isChecked: false,
          jsonData: jsonData,
          fileUrl: f.fileUrl,
          rooms: f.rooms.map((r) => {
            let room = {
              id: r.id,
              isFolded: true,
              name: r.name,
              isChecked: false,
              products: r.products.map((p) => {
                let cell = jsonData.cells.find((c) => c.id == p.cellId)

                let product = {
                  productId: p.productId,
                  productImage: p.productImage,
                  productName: p.productName,
                  productSpec: p.productSpec,
                  productModel: p.productModel,
                  productPrice: p.productPrice,
                  productNumber: p.productNumber,
                  attributeOptions: cell?.data?.attributeOptions ?? [],
                  modelOptions: cell?.data?.modelOptions ?? [],
                  brandId: p.brandId,
                  typeId: p.typeId,
                  cellId: p.cellId,
                  roomId: r.id,
                  roomName: r.name,
                }
                return product
              }),
            }
            return room
          }),
        }

        return floor
      })

      this.floorList = rederData
      let firstFloor = this.floorList.find((i) => true)

      if (firstFloor) {
        this.changeFloor(firstFloor.id)
      }
    },
    // 打开方案名称
    openRenamePageNameDialog() {
      this.renamePageNameForm.name = this.pageData.name
      this.renamePageNameDialog = true
    },
    // 更改方案名称
    renamePageNameSubmit() {
      if (!this.renamePageNameForm.name) {
        return this.$message.error('请输入方案名称')
      }

      this.pageData.name = this.renamePageNameForm.name

      this.renamePageNameDialog = false
    },
    // 打开楼层更名
    openRenameFloorNameDialog(floor) {
      this.renameFloorForm.id = floor.id
      this.renameFloorForm.name = floor.name
      this.renameFloorNameDialog = true
    },
    // 楼层更名提交
    renameFloorNameSubmit() {
      if (!this.renameFloorForm.name) {
        return this.$message.error('请输入楼层名称')
      }

      let floor = this.floorList.find((i) => i.id == this.renameFloorForm.id)

      if (!floor) {
        return this.$message.error('该楼层不存在')
      }

      floor.name = this.renameFloorForm.name

      this.renameFloorNameDialog = false
    },
    // 初始化商品列表数据
    async initProductOptions() {
      let { data: brandData } = await getAllBrand()
      this.productBrandOptions = brandData
      // let pinyinData=

      let pinyinData = [] // {groupName:'A',items:[]}

      brandData.forEach((item) => {
        let data = pinyin(item.label.split(' ').join(''), {
          style: pinyin.STYLE_NORMAL,
        })[0][0]
        let code = data[0].toUpperCase()
        let filterData = pinyinData.find((i) => i.groupName == code)
        if (!filterData) {
          filterData = { groupName: code, items: [] }
          pinyinData.push(filterData)
        }
        filterData.items.push(item)
      })

      console.log('pinyinData', pinyinData)

      pinyinData = pinyinData.sort((a, b) =>
        a.groupName.localeCompare(b.groupName)
      )

      pinyinData.unshift({
        groupName: '推荐',
        items: [
          {
            label: '雪意(Snowyi)',
            value: 1,
          },
        ],
      })

      this.productGroupBrandOptions = pinyinData

      let { data: typeData } = await getAllType()
      this.productTypeOptions = typeData
    },
    async searchProduct() {
      this.productForm.pageIndex = 1
      this.productList = []
      await this.getProductList()
    },
    async loadingMoreProduct() {
      if (this.productForm.total == this.productList.length) {
        return this.$message.error('没有更多数据')
      }

      this.productForm.pageIndex += 1
      await this.getProductList()
    },
    async getProductList() {
      this.loading = true

      let { data } = await getDrageOrderProduct(this.productForm)
      console.log('商品列表', data.result)

      this.productForm.total = data.total

      let productData = data.result.map((i) => {
        let defaultAttr = i.attribute[0]
        let defaultModel = i.modelOptions[0]
        return {
          productId: i.id,
          isStart: i.isStart,
          productImage: defaultAttr.image,
          productName: i.productName,
          productSpec: defaultAttr.value,
          attributeOptions: i.attribute,
          modelOptions: i.modelOptions,
          productModel: defaultModel.value,
          productPrice: defaultAttr.price,
          productNumber: 1,
          brandId: i.brandId,
          typeId: i.typeId,
          cellId: null,
        }
      })
      productData = productData.map((product) => {
        ImgUploadOSS(product.productImage).then((ImgData) => {
          // console.log(ImgData.data)
          product.productImage = ImgData.data
        })
        return product
      })

      this.productList = [...this.productList, ...productData]
      this.loading = false
    },
    changeSpecHandel(val, data) {
      let findAttr = data.attributeOptions.find((u) => u.value == val)
      if (findAttr) {
        data.productPrice = findAttr.price
        data.productSpec = val
        // console.log("规格图片", findAttr.image)
        ImgUploadOSS(findAttr.image).then((ImgData) => {
          // console.log("规格图片修改", ImgData.data)
          data.productImage = ImgData.data
        })
        // data.productImage = findAttr.image
      }
    },
    changeGrapZoom(val) {
      this.graph.zoom(val)
    },
    // 标尺侦听
    listenerResize() {
      this.$nextTick(() => {
        this.resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            let { width, height } = entry.contentRect
            this.pageSizeChangeHandler({ width, height })
          }
        })

        this.resizeObserver.observe(this.$refs.pageRef)
      })
    },
    // 标尺更改
    pageSizeChangeHandler(size) {
      this.sketchRulerOptions.width = size.width
      this.sketchRulerOptions.height = size.height
    },
    // 初始化表单
    initGraph() {
      if (this.pageData.graph) {
        this.pageData.graph.dispose()
        this.pageData.graph = null
      }

      this.pageData.graph = new Graph({
        container: document.getElementById('container'),
        autoResize: true,
        grid: {
          size: 20,
          visible: this.showGrid,
          type: 'mesh',
        },
        panning: true,
        mousewheel: true,
        selecting: true,
        background: {
          color: '#FFFFFF',
        },
        translating: {
          restrict(view) {
            if (view) {
              const cell = view.cell
              if (cell.isNode()) {
                const parent = cell.getParent()
                if (parent) {
                  return parent.getBBox()
                }
              }
            }
          },
        },
      })

      const resizingOptions = {
        enabled: true,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: true,
      }

      const rotatingOptions = {
        enabled: false,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      // 拖拽逻辑
      this.pageData.dnd = new Dnd({
        target: this.pageData.graph,
        // 验证节点是否可以被拖拽
        validateNode: (node) => {
          if (!this.pageData.rootNode) {
            return false
          }

          if (!this.roomList.some((u) => u.isChecked)) {
            this.$message.error('请先创建/选中一个房间')
            return false
          }

          let { x: px, y: py } = this.pageData.rootNode.store.data.position
          let { width: pw, height: ph } = this.pageData.rootNode.store.data.size

          let { x: nx, y: ny } = node.store.data.position
          let { width: nw, height: nh } = node.store.data.size
          let isOk = !(
            nx < px ||
            nx + nw > px + pw ||
            ny < py ||
            ny + nh > py + ph
          )

          if (isOk) {
            // 父节点追加根节点
            this.pageData.rootNode.addChild(node)

            let productData = {
              ...node.data,
              cellId: node.id,
            }
            let room = this.roomList.find((i) => i.isChecked)
            room?.products.push(productData)
          }

          if (!isOk) {
            this.$message.error('请不要拖动到图纸之外')
          }

          return isOk
        },
        getDragNode: (node) => {
          return node.clone({ keepId: true })
        },
        getDropNode: (node) => {
          return node.clone({ keepId: true })
        },
      })

      // 鼠标抬起时保存数据
      this.pageData.graph.on('cell:mouseup', ({ cell }) => {
        if (cell.isNode()) {
          this.saveJsonData('cell:mouseup')
        }
      })

      // 侦听删除时间
      this.pageData.graph.on('cell:removed', ({ cell }) => {
        if (cell.isNode()) {
          let room = this.roomList.find((i) =>
            i.products.some((u) => u.cellId == cell.id)
          )

          if (room) {
            room.products = room.products.filter((i) => i.cellId != cell.id)
          }
        }
      })

      // 鼠标移入
      this.pageData.graph.on('cell:mouseenter', ({ cell }) => {
        if (cell.isNode()) {
          if (cell.id == this.pageData.rootNode.id) {
            return
          }
          cell.addTools([
            {
              name: 'boundary',
              args: {
                attrs: {
                  fill: '#7c68fc',
                  stroke: '#333',
                  'stroke-width': 1,
                  'fill-opacity': 0.2,
                },
              },
            },
            {
              name: 'button-remove',
              args: {
                x: 0,
                y: 0,
              },
            },
          ])
          this.pageData.graph.on('cell:dblclick', ({ cell }) => {
            if (cell.id == this.pageData.rootNode.id) {
              return
            }
            console.log('鼠标双击', cell)
            // 获取当前节点的缩放比例
            const currentSize = cell.size()
            console.log(currentSize)
            let width = 0
            let height = 0
            if (this.sizeState) {
              width = currentSize.width * 2 // 放大两倍
              height = currentSize.height * 2
            } else {
              width = currentSize.width / 2 // 缩小两倍
              height = currentSize.height / 2
            }
            this.sizeState = !this.sizeState

            // 更新节点的缩放比例
            cell.resize(width, height)
            this.saveJsonData('cell:dblclick')
          })
        }
      })

      // 鼠标移出
      this.pageData.graph.on('cell:mouseleave', ({ cell }) => {
        cell.removeTools()
        this.pageData.graph.off('cell:dblclick')
        this.sizeState = true
      })

      // 侦听历史信息
      this.pageData.graph.on('history:change', () => {
        // 历史更新时保存图纸信息
        // this.saveJsonData()
        //监听是否可以后退和前进
        this.canRedoState = this.pageData.graph.canRedo()
        this.canUndoState = this.pageData.graph.canUndo()
      })

      // 命令撤销时执行
      this.pageData.graph.on('history:undo', ({ cmds }) => {
        // 撤销指令集合
        for (const cmd of cmds) {
          let cmdType = {
            // 移动节点
            'cell:change:position': () => {
              this.saveJsonData('history:undo:cell:change:position')
            },
            // 删除节点
            'cell:removed': () => {
              let { data } = cmd
              if (data.props.shape === 'image') {
                let productData = {
                  ...data.props.data,
                  cellId: data.props.id,
                }

                let room = this.roomList.find((i) => i.id == productData.roomId)

                if (!room) {
                  room = {
                    id: productData.roomId,
                    name: productData.roomName,
                    isFolded: true,
                    isChecked: !this.roomList.some((i) => i.isChecked),
                    products: [{ ...productData }],
                  }

                  this.roomList.push(room)

                  let cell = this.pageData.graph.getCellById(data.props.id)
                  this.pageData.rootNode.addChild(cell)

                  return
                }

                room?.products.push(productData)
              }
              // 解决非图片节点父节点丢失问题
              let cell = this.pageData.graph.getCellById(data.props.id)
              this.pageData.rootNode.addChild(cell)
            },
            // 添加节点
            'cell:added': () => {},
          }
          var func = cmdType[cmd.event]
          if (func) {
            func()
          }
        }
      })

      // 命令重做时执行
      this.pageData.graph.on('history:redo', ({ cmds }) => {
        // 重做指令集合
        for (const cmd of cmds) {
          let cmdType = {
            // 移动节点
            'cell:change:position': () => {
              this.saveJsonData('history:redo:cell:change:position')
            },
            // 删除节点
            'cell:removed': () => {},
            // 添加节点
            'cell:added': () => {
              let { data } = cmd
              if (data.props.shape === 'image') {
                let productData = {
                  ...data.props.data,
                  cellId: data.props.id,
                }

                let room = this.roomList.find((i) => i.id == productData.roomId)

                if (!room) {
                  room = {
                    id: productData.roomId,
                    name: productData.roomName,
                    isFolded: true,
                    isChecked: !this.roomList.some((i) => i.isChecked),
                    products: [{ ...productData }],
                  }

                  this.roomList.push(room)

                  let cell = this.pageData.graph.getCellById(data.props.id)
                  this.pageData.rootNode.addChild(cell)

                  return
                }

                room?.products.push(productData)
              }
              let cell = this.pageData.graph.getCellById(data.props.id)
              this.pageData.rootNode.addChild(cell)
            },
          }
          var func = cmdType[cmd.event]
          if (func) {
            func()
          }
        }
      })

      // 插件导入
      this.pageData.graph
        // 导出插件
        .use(new Export())
        // 缩放插件
        .use(
          new Transform({
            resizing: resizingOptions,
            rotating: rotatingOptions,
          })
        )
        // 历史记录插件
        .use(
          new History({
            enabled: true,
            beforeAddCommand(event, args) {
              if (event === 'cell:change:*') {
                // 工具添加移除操作不加入历史
                if (args.key === 'tools') {
                  return false
                }
              }

              return true
            },
          })
        )
        //对齐线
        .use(
          new Snapline({
            enabled: true,
            className: 'custom_snapline',
            // className: {
            //   line: 'custom_snapline'
            // }
          })
        )
        //复制粘贴
        .use(new Clipboard({ enabled: true }))
        //选择
        .use(new Selection({ enabled: true }))
        //快捷键
        .use(
          new Keyboard({
            enabled: true,
            global: true,
          })
        )

      // 事件侦听
      this.pageData.graph.on('scale', this.graphScaleHandler)

      //复制
      this.pageData.graph.bindKey('ctrl+c', () => {
        this.pageData.graph.isClipboardEmpty()
        const cells = this.pageData.graph.getSelectedCells()
        let ishaverootNode = cells.some(
          (i) => i.id == this.pageData.rootNode.id
        )
        if (ishaverootNode) {
          return this.$message.error('图纸节点不能被复制')
        }
        // console.log('ctrl+c', cells)
        if (cells.length) {
          this.pageData.graph.copy(cells)
        }
        return false
      })
      //粘贴
      this.pageData.graph.bindKey('ctrl+v', () => {
        if (!this.pageData.graph.isClipboardEmpty()) {
          const cells = this.pageData.graph.paste({ offset: 32 })
          // let newNodeData = cells[0].store.data;
          // console.log('ctrl+v', cells)
          for (let cell of cells) {
            if (cell.id == this.pageData.rootNode.id) {
              return
            }
            this.pageData.rootNode.addChild(cell)
            let room = this.roomList.find((i) => i.id == cell.data.roomId)
            let productData = {
              ...cell.data,
              cellId: cell.id,
            }
            // console.log("复制粘贴的商品信息", productData)
            room?.products.push(productData)
          }

          this.pageData.graph.cleanSelection()
          // this.pageData.graph.select(cells)
        }
        return false
      })
    },
    // 表单拖拽
    startDrag(e, data) {
      let room = this.roomList.find((i) => i.isChecked)

      if (!room) {
        this.$message.error('请先创建/选中一个房间')
        return
      }

      data.roomId = room.id
      data.roomName = room.name

      let node = this.pageData.graph.createNode({
        shape: 'image',
        width: 40,
        height: 40,
        imageUrl: data.productImage,
        data,
      })

      this.pageData.dnd.start(node, e)
    },
    // 保存当前jsonData
    saveJsonData(funName = '') {
      let selectedFloor = this.floorList.find((i) => i.isChecked)
      if (selectedFloor) {
        let jsonData = this.pageData.graph.toJSON()
        selectedFloor.jsonData = jsonData
        selectedFloor.rooms = this.$deepCopy(this.roomList)
      }
    },
    // 表单缩放事件
    graphScaleHandler(val) {
      let scale = this.pageData.graph.zoom()
      this.sketchRulerOptions.scale = scale
    },
    changeGraphScaleHandler(val) {
      this.showScaleOptions = false
      this.pageData.graph.zoomTo(val)
    },
    stepGraphScallHandler(stepVal) {
      this.pageData.graph.zoom(stepVal)
    },
    // 获取画布json数据
    getGraphData() {
      let data = this.pageData.graph.toJSON()

      console.log('graph json', data)

      console.log(this.floorList)
    },
    // 导入json数据,并且设定父节点
    importJsonData(jsonData) {
      this.pageData.graph.clearCells()
      this.pageData.graph.cleanClipboard()

      this.pageData.graph.off('render:done') // 移除旧的监听器
      this.pageData.graph.fromJSON(jsonData)
      this.renderDonePromise = new Promise((resolve, reject) => {
        this.pageData.graph.on('render:done', () => {
          // 当事件触发时，调用 resolve
          console.log('渲染完成')
          resolve(true)
        })
      })

      let rootNode = this.pageData.graph.getCells()[0]
      this.pageData.rootNode = rootNode
    },
    openFileDialog() {
      this.$nextTick((_) => {
        this.$refs.fileInputRef.click()
      })
    },
    triggerFileInput() {
      this.$refs.uploadppt.click()
    },
    handleFileUpload() {
      const selectedFile = this.$refs.uploadppt.files[0]
      this.uploadFileName = selectedFile.name
      console.log('Selected File:', this.uploadFileName)
    },
    showCloseMessage(index) {
      this.showCloseButton = true
      this.hoverPPTIndex == index
    },
    hiddenCloseMsg() {
      this.showCloseButton = false
      this.hoverPPTIndex == null
    },
    // 楼层图纸上传
    async fileInputHandle(e) {
      let file = e.target.files[0]

      console.log('file', file)

      if (!file) {
        return this.$message.error('请选择一个文件进行上传')
      }

      let fileName = file.name
      let size = file.size

      let fileExtension = fileName
        .slice(fileName.lastIndexOf('.') + 1)
        .toLowerCase()

      // let imageFileMedieTypes = ['image/jpg', 'image/jpeg', 'image/png']

      let fileExtensionArr = ['jpg', 'jpeg', 'png', 'svg']

      let isFileType = fileExtensionArr.some((u) => u == fileExtension)

      if (!isFileType) {
        this.$refs.fileInputRef.value = ''
        return this.$message.error(
          '文件格式不正确,请上传' + fileExtensionArr.join(',') + '格式的文件'
        )
      }

      let maxFileSize = 20 // 500MB

      let isLt = size / 1024 / 1024 < maxFileSize

      if (!isLt) {
        this.$refs.fileInputRef.value = ''
        return this.$message.error('上传文件大小不能超过' + maxFileSize + 'MB!')
      }

      console.log('111111111111')

      let formData = new FormData()
      formData.append('file', file)
      console.log('2222222222')

      // 模拟文件上传
      let { data } = await uploadeFloorFile(formData)

      console.log('3333333', data)

      this.addFloorForm.image = data

      this.$refs.fileInputRef.value = ''
    },
    // 新增楼层图纸
    async addFloorSubmit() {
      var ImgWidth = 0
      var ImgHeight = 0
      console.log('新增楼层图纸')
      if (!this.addFloorForm.name || !this.addFloorForm.image) {
        return this.$message.error('请输入楼层名称和图纸')
      }
      let id = +new Date()

      // 获取图片大小
      var img = new Image()
      img.src = this.addFloorForm.image
      await new Promise((resolve, reject) => {
        img.onload = () => {
          resolve()
        }
      })
      console.log('尺寸选择', this.sizeRadioSelect)
      if (this.sizeRadioSelect == '1') {
        ImgWidth = 1754
        ImgHeight = 1240
      }
      if (this.sizeRadioSelect == '2') {
        ImgWidth = 1240
        ImgHeight = 1754
      }
      if (this.sizeRadioSelect == '3') {
        ImgWidth = img.width
        ImgHeight = img.height
      }
      // console.log("图片宽度", ImgWidth)
      // console.log("图片高度", ImgHeight)

      var rect = document.getElementById('container').getBoundingClientRect()

      // 清空画布之前先保存
      this.saveJsonData('addFloorSubmit')

      // 清空画布
      this.pageData.graph.clearCells()

      this.pageData.rootNode = this.pageData.graph.addNode({
        shape: 'image',
        x: (rect.width - ImgWidth) / 2,
        y: (rect.height - ImgHeight) / 2,
        width: ImgWidth,
        height: ImgHeight,
        imageUrl: this.addFloorForm.image,
      })

      let newFloorData = {
        id: +new Date(),
        name: this.addFloorForm.name,
        fileUrl: this.addFloorForm.image,
        jsonData: this.pageData.graph.toJSON(),
        isChecked: true,
        rooms: [],
      }

      this.floorList.forEach((i) => (i.isChecked = false))

      this.roomList = []

      this.floorList.push(newFloorData)

      // 清空历史缓存,防止图纸节点丢失
      this.pageData.graph.cleanHistory()

      this.addFloorDialog = false
    },
    // 切换楼层
    changeFloor(id) {
      // 切换前先保存数据
      // if (this.floorList.some((i) => i.isChecked && i.id == id)) {
      //   return
      // }

      this.floorList.forEach((i) => {
        if (i.id == id) {
          i.isChecked = true
          this.importJsonData(i.jsonData)
          this.roomList = i.rooms
        } else {
          i.isChecked = false
        }
      })

      // 切换房间时清空历史记录
      this.pageData.graph.cleanHistory()
    },
    openAddRoomDialog() {
      if (!this.canCreateRoom) {
        return this.$message.error('请先创建/选中一个楼层')
      }

      this.createRoomForm = {
        id: null,
        name: '默认房间',
      }

      this.createRoomDialog = true
    },
    openEditRoomDialog(room) {
      this.createRoomForm = {
        id: room.id,
        name: room.name,
      }
      this.createRoomDialog = true
    },
    openUploadPPTDialog() {
      this.pptFileName = ''
      this.uploadPPTVisible = true
    },
    // 创建或者编辑房间名称提交
    createRoomSubmit() {
      if (!this.createRoomForm.name) {
        return this.$message.error('请输入房间名称')
      }

      if (this.createRoomForm.id) {
        let room = this.roomList.find((i) => i.id == this.createRoomForm.id)
        if (!room) {
          this.$message.error('房间不存在')
          return
        }
        room.name = this.createRoomForm.name
      } else {
        this.roomList.forEach((i) => {
          i.isChecked = false
        })

        let id = +new Date()
        let newRoom = {
          id,
          isFolded: true,
          isChecked: true,
          name: this.createRoomForm.name,
          products: [],
        }
        this.roomList.push(newRoom)
      }

      this.createRoomDialog = false
    },
    // 切换选中房间
    changeRoom(id) {
      this.roomList.forEach((i) => {
        if (i.id == id) {
          i.isChecked = true
        } else {
          i.isChecked = false
        }
      })

      // 切换房间时清空历史记录
      // this.pageData.graph.cleanHistory()
    },
    // 统计房间商品数量
    sumRoomProductCount(room) {
      let sum = room.products.reduce((acc, cur) => acc + cur.productNumber, 0)
      return sum
    },
    // 删除楼层
    deleteFloor(id) {
      let floor = this.floorList.find((i) => i.id == id)
      if (!floor) {
        return this.$message.error('楼层不存在')
      }
      this.$confirm('确认删除该楼层?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 移除当前楼层
        this.floorList = this.floorList.filter((i) => i.id != id)

        // 如果楼层清空之后清除房间列表
        if (this.floorList.length == 0) {
          this.pageData.graph.clearCells()
          this.roomList = []
          this.pageData.graph.cleanHistory()
          return
        }

        // 如果存在选中楼层就单纯删除就行
        if (this.floorList.some((i) => i.isChecked)) {
          return
        }

        var firstfloor = this.floorList[0]

        this.changeFloor(firstfloor.id)
      })
    },
    // 删除房间内的设备
    deleteRoomProduct(cellId) {
      let room = this.roomList.find((i) =>
        i.products.some((u) => u.cellId == cellId)
      )

      if (!room) {
        return this.$message.error('房间不存在')
      }

      this.pageData.graph.removeCell(cellId)

      room.products = room.products.filter((i) => i.cellId != cellId)
    },
    // 删除房间
    deleteRoom(id) {
      if (!this.roomList.some((i) => i.id == id)) {
        return this.$message.error('房间不存在')
      }

      this.$confirm('确认删除该房间和房间内设备?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let cellIds =
          this.roomList
            .find((i) => i.id == id)
            ?.products?.map((i) => i.cellId) ?? []

        this.pageData.graph.removeCells(cellIds)

        this.roomList = this.roomList.filter((i) => i.id != id)

        // 如果清除后选中的房间数量大于0并且没有选中楼层就选中第一个房间
        if (
          this.roomList.length > 0 &&
          !this.roomList.some((i) => i.isChecked)
        ) {
          var lastRoom = this.roomList[this.roomList.length - 1]
          lastRoom.isChecked = true
        }
      })
    },
    // 添加矩形节点
    addRectNode() {
      if (this.pageData.rootNode == null) {
        return this.$message.error('请先创建/选择一张楼层图纸')
      }

      // 获取父节点坐标
      let position = this.pageData.rootNode.position()

      let node = this.pageData.graph.createNode({
        shape: 'rect',
        x: position.x,
        y: position.y,
        width: 100,
        height: 100,
        attrs: {
          body: {
            stroke: '#8c50ff',
            'fill-opacity': 0,
            'stroke-width': 2,
          },
        },
      })

      this.pageData.rootNode.addChild(node)
    },
    // 添加文字节点
    addTextNode() {
      if (this.pageData.rootNode == null) {
        return this.$message.error('请先创建/选择一张楼层图纸')
      }

      let text =
        '请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸请先创建/选择一张楼层图纸'

      let row = Math.ceil(text.length / 10)

      // 获取父节点坐标
      let position = this.pageData.rootNode.position()

      let node = this.pageData.graph.createNode({
        shape: 'text-block',
        text,
        x: position.x,
        y: position.y,
        width: (row > 10 ? 14 * 10 : 14 * text.length) + 40,
        height: row * 14 + 40,
        attrs: {
          body: {
            fontSize: '14px',
            color: '#8c50ff',
            stroke: '#8c50ff',
            'fill-opacity': 0,
            'stroke-width': 0,
          },
        },
      })

      this.pageData.rootNode.addChild(node)
    },
    // 文件生成
    async fileGenerate() {
      // console.log("this.floorList",this.floorList)
      if (this.floorList.length <= 0) {
        this.$message.error('未添加图纸，请先添加图纸')
        // this.generateDialog = false
        return
      }
      let changeData = {
        name: this.pageData.name,
        floors: this.floorList.map((i) => {
          let floor = {
            name: i.name,
            fileUrl: i.fileUrl,
            jsonData: JSON.stringify(i.jsonData),
            rooms: i.rooms.map((j) => {
              let room = {
                name: j.name,
                products: j.products.map((k) => {
                  let product = {
                    cellId: k.cellId,
                    productId: k.productId,
                    productName: k.productName,
                    productImage: k.productImage,
                    productModel: k.productModel,
                    brandId: k.brandId,
                    ProductSpec: k.productSpec,
                    ProductPrice: k.productPrice,
                    ProductNumber: k.productNumber,
                  }
                  return product
                }),
              }
              return room
            }),
          }
          return floor
        }),
      }
      // this.fullscreenLoading = true;
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)',
      })
      await this.getImgBaseList(changeData)
      if (this.fileFormat == 'PDF') {
        await this.downLoadDragOrder(changeData)
      }
      if (this.fileFormat == 'Excel') {
        await this.downLoadDragOrderExcel(changeData)
      }
      if (this.fileFormat == 'PPT') {
        await this.downLoadDragOrderPPT(changeData)
      }
      // console.log("this.floorList",this.floorList)
      var firstfloor = this.floorList[0]
      // console.log("firstfloor",firstfloor)
      this.changeFloor(firstfloor.id)
      // this.fullscreenLoading = false
      // this.generateDialog = false
      loading.close()
    },
    //生成ImgBaseList
    async getImgBaseList(data) {
      this.ImgBaseList = []
      // console.log(data)
      try {
        if (data.name !== '') {
          for (let floor of this.floorList) {
            this.changeFloor(floor.id)
            // console.log(floor.jsonData)
            // this.importJsonData(JSON.parse(floor.jsonData))
            await this.renderDonePromise

            const options = {
              // 根据需要设置导出选项
              width: 1754,
              height: 1240,
              quality: 1,
              backgroundColor: 'rgba(0,0,0,0)',
              // 其他选项
            }
            const dataUri = await new Promise((resolve) => {
              this.pageData.graph.toPNG((dataUri) => {
                if (dataUri) {
                  resolve(dataUri)
                }
              }, options)
            })
            let fileName = data.name + '_' + floor.name
            console.log(fileName, dataUri)

            let imgData = {
              fileName: fileName,
              fileUrl: dataUri,
            }
            this.ImgBaseList.push(imgData)
          }
        }
      } catch (error) {
        console.error('Error in openDownLoadPdfDialog:', error)
      }
    },
    //下载当前设计方案 Pdf
    async downLoadDragOrder(changeData) {
      // this.fullscreenLoading = true
      // const loading = this.$loading({
      //   lock: true,
      //   text: '文件正在生成中，请耐心等待~',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255, 0.9)'
      // });
      // await this.getImgBaseList(data)
      console.log('模拟下载成功！', this.ImgBaseList)
      // console.log(changeData)
      await DownLoadPDFByData(changeData, this.ImgBaseList)
        .then((res) => {
          // 创建一个隐藏的<a>标签
          console.log(res)
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      // loading.close();
      this.generateDialog = false
    },
    //下载当前设计方案 Excel
    async downLoadDragOrderExcel(changeData) {
      // this.fullscreenLoading = true
      // const loading = this.$loading({
      //   lock: true,
      //   text: '文件正在生成中，请耐心等待~',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255, 0.9)'
      // });
      // await this.getImgBaseList(changeData)
      console.log('模拟下载成功！', changeData)

      await DownLoadExcelByData(changeData, this.ImgBaseList)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      // loading.close();
      this.generateDialog = false
    },

    async pptFileUpload() {
      // const uploadComponent = this.$refs.upload;
      if (this.pptFileName == '') {
        this.$message.error('请输入PPT模板文件名')
        return
      }
      const file = this.$refs.uploadppt.files[0]
      console.log(file.type)
      if (
        file &&
        file.type ===
          'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ) {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('newFileName', this.pptFileName)

        let { data } = await uploadpptFile(formData)
        console.log('上传后返回数据', data)
        if (data == '') {
          this.$message.error('上传PPT模板文件失败')
          return
        }
        this.getpptFileListData()
        // this.selectedPPTIndex = this.uploadPPTUrlList[this.uploadPPTUrlList.length-1];
        // this.nowSelectPPTData = this.uploadPPTUrlList[this.selectedPPTIndex];
        this.$refs.uploadppt.value = ''
        this.uploadFileName = ''
        this.uploadPPTVisible = false
        // this.uploadPPTUrl = data;
      } else {
        this.$message.error('请上传pptx文件！')
      }
    },
    async getpptFileListData() {
      let { data } = await getpptFileList()
      console.log('获取当前用户上传的文件数据', data)
      this.uploadPPTUrlList = data
      this.nowSelectPPTData = this.uploadPPTUrlList[0]
      this.selectedPPTIndex = 0
    },
    changeSelectPPT(pptData, index) {
      console.log('当前选中pptData', pptData.id)
      console.log('当前nowSelectPPTData', this.nowSelectPPTData.id)
      if (this.nowSelectPPTData.id == pptData.id) {
        this.nowSelectPPTData = {}
        this.selectedPPTIndex = null
        return
      }
      this.nowSelectPPTData = pptData
      console.log('nowSelectPPTData', this.nowSelectPPTData)
      this.selectedPPTIndex = index
    },
    handleCloseClick(id) {
      // 在这里处理点击关闭按钮时的事件
      console.log('Close button clicked', id)
      this.$confirm('当前操作将会删除当前PPT模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.loading2 = true
          await delpptDataById(id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getpptFileListData()
          this.loading2 = false
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
          this.loading2 = false
        })
    },
    // 下载当前设计方案 PPT
    async downLoadDragOrderPPT(changeData) {
      // this.fullscreenLoading = true
      // const loading = this.$loading({
      //   lock: true,
      //   text: '文件正在生成中，请耐心等待~',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255, 0.9)'
      // });
      // await this.getImgBaseList(changeData)
      console.log('模拟下载成功！')

      await DownLoadPPTByData(
        changeData,
        this.ImgBaseList,
        this.nowSelectPPTData
      )
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      // loading.close();
      this.generateDialog = false
    },
    // 文字内容提交
    addTextSubmit() {
      if (!this.txtForm.text) {
        return this.$message.error('请输入文字内容')
      }

      if (this.pageData.rootNode == null) {
        return this.$message.error('请先创建/选择一张楼层图纸')
      }

      let text = this.txtForm.text

      let row = Math.ceil(text.length / 10)

      // 获取父节点坐标
      let position = this.pageData.rootNode.position()

      let node = this.pageData.graph.createNode({
        shape: 'text-block',
        text,
        x: position.x,
        y: position.y,
        width: (row > 10 ? 14 * 10 : 14 * text.length) + 40,
        height: row * 14 + 40,
        attrs: {
          body: {
            fontSize: '14px',
            color: '#8c50ff',
            stroke: '#8c50ff',
            // stroke: 'red',
            'fill-opacity': 0,
            'stroke-width': 0,
          },
        },
      })

      this.pageData.rootNode.addChild(node)

      this.txtForm.text = ''
      this.showTextDialog = false
    },
    // 撤销
    pageUndo() {
      if (!this.pageData.graph.canUndo()) {
        return this.$message.error('没有可撤销的操作')
      }

      this.pageData.graph.undo()
    },
    // 重做
    pageRedo() {
      if (!this.pageData.graph.canRedo()) {
        return this.$message.error('没有可重做的操作')
      }

      this.pageData.graph.redo()
    },
    // 渲染存储数据
    renderSaveData() {
      let totaPrice = this.floorList.reduce((fpre, fcur) => {
        fpre += fcur.rooms.reduce((rpre, rcur) => {
          rpre += rcur.products.reduce((ppre, pcur) => {
            ppre += pcur.productPrice * pcur.productNumber
            return ppre
          }, 0)
          return rpre
        }, 0)
        return fpre
      }, 0)
      this.saveDataForm.totalPrice = totaPrice

      let roomData = this.floorList.reduce((fpre, fcur) => {
        let rooms = fcur.rooms.map((room) => {
          return {
            label: fcur.name + ' ' + room.name,
            products: room.products.map((product) => {
              return {
                ...product,
                floorId: fcur.id,
                roomId: room.id,
                arae: fcur.name + ' ' + room.name,
              }
            }),
          }
        })
        fpre = [...fpre, ...rooms]
        return fpre
      }, [])

      this.saveDataForm.roomData = roomData

      let typeData = []
      for (const floor of this.floorList) {
        for (const room of floor.rooms) {
          for (const product of room.products) {
            let typeId = product.typeId

            let productQueryData = typeData.find((i) => i.typeId == typeId)

            if (productQueryData) {
              productQueryData.products.push({
                ...product,
                floorId: floor.id,
                roomId: room.id,
                arae: floor.name + ' ' + room.name,
              })
            } else {
              typeData.push({
                typeId,
                label:
                  this.productTypeOptions.find((i) => i.value == typeId)
                    ?.label ?? '未知类型' + typeId,
                products: [
                  {
                    ...product,
                    floorId: floor.id,
                    roomId: room.id,
                    arae: floor.name + ' ' + room.name,
                  },
                ],
              })
            }
          }
        }
      }

      this.saveDataForm.typeData = typeData
    },
    // 获取品牌数据
    getBrandData(brandId) {
      return (
        this.productBrandOptions.find((i) => i.value == brandId)?.label ?? ''
      )
    },
    openNewPage() {
      window.open('https://www.snowyimall.com/UserAdmin/Main', '_blank') // 在新标签页中打开链接
    },
    // 保存表单中更改商品数量
    changeProductNumber(val, product) {
      let floor = this.floorList.find((i) => i.id == product.floorId)
      let room = floor.rooms.find((i) => i.id == product.roomId)
      let productIndex = room.products.find((i) => i.cellId == product.cellId)
      // 更改房间中的上平数量
      productIndex.productNumber = val

      let cell = floor.jsonData.cells.find((i) => i.id == product.cellId)
      // 更改节点中的商品数量
      cell.data.productNumber = val

      this.changeFloor(floor.id)
    },
    deleteTablueProduct(product) {
      let cellId = product.cellId

      let floor = this.floorList.find((i) => i.id == product.floorId)
      let room = floor.rooms.find((i) => i.id == product.roomId)
      room.products = room.products.filter((i) => i.cellId != cellId)

      floor.jsonData.cells = floor.jsonData.cells.filter((i) => i.id != cellId)

      for (const cell of floor.jsonData.cells) {
        if (cell.children) {
          cell.children = cell.children.filter((i) => i.id != cellId)
        }
      }

      this.changeFloor(floor.id)
    },
    // 保存
    async saveDataSubmit() {
      let data = {
        name: this.pageData.name,
        floors: this.floorList.map((i) => {
          let floor = {
            name: i.name,
            fileUrl: i.fileUrl,
            jsonData: JSON.stringify(i.jsonData),
            rooms: i.rooms.map((j) => {
              let room = {
                name: j.name,
                products: j.products.map((k) => {
                  let product = {
                    cellId: k.cellId,
                    productId: k.productId,
                    productImage: k.productImage,
                    productModel: k.productModel,
                    ProductSpec: k.productSpec,
                    ProductPrice: k.productPrice,
                    ProductNumber: k.productNumber,
                  }
                  return product
                }),
              }
              return room
            }),
          }
          return floor
        }),
      }

      const loading = this.$loading({
        lock: true,
        text: '图纸上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,.7)',
      })
      this.isNavigate = true
      if (this.id) {
        // 更新图纸
        data.id = this.id

        await dragOrderEdit(data)

        this.$router.push({ path: '/Solution/SlnDesignSuccess/' + this.id })
      } else {
        // 新加图纸

        await dragOrderAdd(data)

        this.$router.push({ path: '/Solution/DesignIndex' })
      }

      loading.close()
    },
    // 退出图纸生成页面
    exitPage() {
      this.isNavigate = true
      this.$confirm(
        '当前图纸尚未保存,退出将会丢失图纸信息是否确认退出?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.$router.push({ path: '/Solution/DesignIndex' })
        })
        .catch(() => {
          this.isNavigate = false // 如果用户取消，重置标志
        })
    },
    // 页面刷新
    handleBeforeUnload(event) {
      const message = '当前图纸尚未保存,退出将会丢失图纸信息是否确认退出?'
      event.returnValue = message
      // 对于某些浏览器，可能需要直接返回该消息
      return message
    },
    // 展示网格
    toggleGrid() {
      this.showGrid
        ? this.pageData.graph.hideGrid()
        : this.pageData.graph.showGrid()

      this.showGrid = !this.showGrid
    },
    async startProduct(item) {
      try {
        console.log('', item)
        await stratProduct(item.productId)
        item.isStart = true
      } catch (error) {
        item.isStart = false
      }
    },
    async cancelStartProduct(item) {
      try {
        console.log('', item)
        await cancelStartProduct(item.productId)
        item.isStart = false
      } catch (error) {
        item.isStart = true
      }
    },
    // 点击字母滚动到对应省份位置
    scrollToProvince(letter) {
      this.selectedLetter = letter
      console.log(letter)
      const targetProvince = this.productGroupBrandOptions.find(
        (province) => province.groupName === letter
      )
      if (targetProvince) {
        let val = targetProvince.items.find((i) => true)

        const targetRef = this.$refs[`brand${val.value}Ref`] // 获取对应的 ref

        console.log('targetRef', targetRef)

        if (targetRef) {
          targetRef[targetRef.length - 1].$el.scrollIntoView()
        }
      }
    },
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  watch: {
    $route: {
      handler(to, from) {
        this.loadingData()
      },
      immediate: true,
    },
    productTypeOptions: {
      handler(oldValue, newValue) {
        this.renderSaveData()
      },
    },
    floorList: {
      handler(oldValue, newValue) {
        this.renderSaveData()
      },
      deep: true,
      immediate: true,
    },
    roomList: {
      handler(oldValue, newValue) {
        this.saveJsonData('roomList')
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/dragorder.scss';

::v-deep .el-scrollbar__wrap {
  height: 100%;
  overflow-x: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
  width: 20px;
}

.app-content {
  color: #333;
  --h90: 90px;
  --h220: 220px;
  height: 100%;
  width: 100%;
  background-color: #f6f9fd;

  #container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .header {
    font-size: 14px;
    color: #333539;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 20px;
    // height: var(--h90);
    height: 60px;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 10px;
      flex: 1;
      .tools_box {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
      }
      .name {
        margin-right: 10px;
      }
      .tools_btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        font-family: PingFangSC, PingFang SC;
        font-size: 10px;
        color: #666666;
        padding: 5px;
        margin: 10px;
        font-weight: 400;
        div {
          padding: 2px 0;
        }
      }
    }
  }
}

.flex-box {
  display: flex;
  flex-direction: column;
  .full_box {
    height: 0;
    flex: 1;
  }
}

.p10 {
  padding: 10px;
}

.w280 {
  width: 280px;
}

.clo_flex_box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page_box {
  display: flex;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  .page {
    display: flex;
    flex-direction: column;
    flex: 1;
    .page_content {
      position: relative;
      flex: 1;
      background: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
    margin: 0 10px;
    .page_tools {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 30px;
      margin-top: 10px;
      .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
        border-radius: 4px;
        background: white;
        height: 28px;
        margin-left: 10px;
        padding: 0 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

        .presuppose_box {
          width: 100%;
          z-index: 200;
          position: absolute;
          box-sizing: border-box;
          background: white;
          top: -10px;
          left: 0;
          transform: translateY(-100%);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

          .presuppose_item {
            font-size: 12px;
            text-align: center;
            padding: 5px;
            &:hover {
              background: rgba(140, 80, 255, 0.12);
              color: #8c50ff;
            }
          }
        }
        .scale_value {
          text-align: center;
          width: 30px;
          font-size: 12px;
          font-weight: bold;
          padding: 5px;
        }
      }
    }
  }
}

.br4 {
  border-radius: 4px;
}

.floor_box {
  display: flex;
  flex-direction: column;
  height: var(--h220);
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

  .floor_title {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    .title {
      font-weight: bold;
      user-select: none;
    }
    .add_floor_btn {
      font-size: 12px;
      color: #8c50ff;
      cursor: pointer;
    }
  }

  .floor_items {
    flex: 1;
    height: 0;
    .floor_empty {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }

    .item {
      color: #333333;

      &.active {
        background: rgba(140, 80, 255, 0.12);
        color: #8c50ff;
      }

      display: flex;
      padding: 5px 20px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: rgba(140, 80, 255, 0.12);
        color: #8c50ff;
      }
      img {
        vertical-align: middle;
      }
      .name {
        padding: 0 5px;
        flex: 1;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        .delete_btn {
          visibility: visible;
        }
      }
      .delete_btn {
        visibility: hidden;
      }
    }
  }
}

.room_box {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: calc(100% - var(--h220) - 10px);
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

  .room_title {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    .title {
      font-weight: bold;
      user-select: none;
    }
    .add_room_btn {
      font-size: 12px;
      color: #8c50ff;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: gray;
      }
    }
  }

  .room_items {
    flex: 1;
    height: 0;
    .room_empty {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
    .item {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      font-size: 14px;
      display: flex;
      color: #333333;

      .room_name {
        .hover_show {
          visibility: hidden;
        }

        &.active {
          background: rgba(140, 80, 255, 0.12);
          color: #8c50ff;
          i {
            // transform: rotate(90deg);
            visibility: visible;
          }
        }

        &:hover {
          background: rgba(140, 80, 255, 0.12);
          color: #8c50ff;
        }

        &:hover .hover_show {
          visibility: visible;
        }

        display: flex;
        align-items: center;
        padding: 2px 20px;
        .name {
          flex: 1;
          padding: 0 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      & .room_name:hover {
        background: rgba(140, 80, 255, 0.12);
      }

      .room_list {
        padding: 0 0 0 10px;
        max-height: 500px;
        overflow: hidden;

        .product_item {
          padding: 2px 5px;
          display: flex;
          align-items: center;
          margin-left: 20px;

          color: #333;

          &:hover {
            background: rgba(140, 80, 255, 0.12);
            color: #8c50ff;
          }

          .hover_show {
            visibility: hidden;
          }

          &:hover .hover_show {
            visibility: visible;
          }

          .product_name {
            padding: 0 5px;
            font-size: 12px;
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.product_box {
  position: relative;
  .flod_btn {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 10000;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .product_items {
    flex: 1;
    height: 0;

    .product_item:hover {
      background: rgba(140, 80, 255, 0.12);
    }

    .item {
      &:last-child {
        border-bottom: none;
      }
      display: flex;
      align-items: center;
      padding: 2px 10px;
      border-bottom: 1px solid #ddd;
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        .price {
          user-select: none;
          font-size: 12px;
          font-weight: bold;
          padding-top: 5px;
        }

        .name {
          width: 100%;
          font-size: 12px;
          font-weight: bold;
          padding: 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .model {
          margin: 5px 0;
        }
        .number {
          width: 100%;
          text-align: left;
        }
      }
      .oper_box {
        flex: 1;
        width: 0;
        padding: 5px;
      }
    }
  }

  .more_btn {
    text-align: center;
    font-size: 14px;
    color: #8c50ff;
    cursor: pointer;
    padding: 10px;
  }
}

.add_floor_box {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
}
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
}
.uploadlist_box {
  width: 150px;
  height: 150px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    border-color: #8c50ff;
  }
}
.pptdata_changed {
  /* 点击后的样式 */
  border: 2px solid #8c50ff;
}
.uploadfile_box {
  width: 150px;
  height: 150px;
  margin-left: 20px;
  border: 1px dashed #dddddd;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    border-color: #8c50ff;
  }
}

.floor_node_box {
  padding: 10px;
  box-sizing: border-box;
  // border: 1px solid #ddd;
  user-select: none;

  .floor_item_box {
    position: relative;
    padding: 5px;
    .floor_name {
      padding: 5px;
      &:hover {
        background: rgba(140, 80, 255, 0.12);
        color: #8c50ff;
      }
    }
  }

  .room_node_box::before {
    position: absolute;
    content: '';
    border-left: 1px solid #ddd;
    height: calc(100% - 20px);
    top: 30px;
    left: 15px;
  }

  .room_node_box {
    padding-left: 20px;
    box-sizing: border-box;
    .room_name {
      position: relative;
      padding: 5px;
      &:hover {
        background: rgba(140, 80, 255, 0.12);
        color: #8c50ff;
      }
      &::before {
        content: '';
        width: 15px;
        border-top: 1px solid #ddd;
        position: absolute;
        left: -10px;
        top: 50%;
      }
    }
    .product_item_box {
      position: relative;

      padding-left: 20px;
      box-sizing: border-box;

      &::before {
        position: absolute;
        content: '';
        border-left: 1px solid #ddd;
        height: calc(100% + 10px);
        top: -5px;
        left: 10px;
      }

      .product_item {
        position: relative;
        display: flex;
        padding: 5px;
        &:hover {
          background: rgba(140, 80, 255, 0.12);
          color: #8c50ff;
        }
        &::before {
          content: '';
          width: 15px;
          border-top: 1px solid #ddd;
          position: absolute;
          left: -10px;
          top: 50%;
        }
      }
    }
  }
}

.custom-select .el-input__inner {
  //  padding-right: 30px;
  /* 增加右侧间距 */
}

.alphabet-column {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 240px;
  /* 调整位置 */
  top: 0;
  bottom: 0;
  /* 调整上下内边距 */
  pointer-events: none;
  /* 不影响下拉框的点击事件 */
  z-index: 1;
  /* 设置 z-index，确保在选项之上 */
  font-size: 10px;
  background-color: white;
}

.alphabet-column div {
  margin: 0;
  padding: 2px 5px;
  cursor: pointer;
  pointer-events: auto;
  line-height: 14px;
  /* 恢复点击事件 */
}

.cu_disabled {
  color: #ddd;
  cursor: no-drop !important;
}

/deep/.el-select-dropdown__wrap {
  min-height: 500px !important;
  text-align: start;
}

/* 调整选项的样式，确保不遮挡右侧字母列 */
.el-select-dropdown .el-scrollbar__wrap {
  overflow: visible !important;
}

.el-select-dropdown .el-option {
  overflow: visible !important;
}

.el-select-dropdown .el-option__content {
  overflow: visible !important;
}

.el-select-dropdown .el-option__content:hover {
  background-color: transparent !important;
  /* 鼠标悬停时取消背景色 */
}
</style>

<style lang="scss">
@import '@/styles/dragorder.scss';
.save_data_box {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    padding: 10px;
    cursor: pointer;
    user-select: none;
    border: 2px solid transparent;
    &:hover {
      border-color: #8c50ff;
      background: #f8f9fb;
    }
  }

  .list_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    margin: 20px 0;

    .tabs_title {
      display: flex;
      font-size: 16px;
      color: #333;
      margin-bottom: 10px;
      .item {
        padding: 10px 20px;
        cursor: pointer;
        user-select: none;
        border-bottom: 2px solid transparent;

        &.active {
          font-weight: bold;
          color: #8c50ff;
          border-bottom-color: #8c50ff;
        }

        &:hover {
          color: #8c50ff;
          border-bottom-color: #8c50ff;
        }
      }
    }

    .table_box {
      flex: 1;
      height: 0;
      display: flex;
      flex-direction: column;
      .full_box {
        flex: 1;
        height: 0;
      }
    }

    .table_header_box {
      height: 60px;
      color: black;
      // background: #3a84ff44;
      background: rgba(58, 132, 255, 0.05);
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      div {
        text-align: center;
        &:last-child {
          flex: 1;
        }
      }
    }

    .room_label {
      position: sticky;
      top: 0px;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0;
      z-index: 1;
      background: #f8f9fb;
    }

    .table_body_box {
      border-top: 1px solid #e5e9eb;
      min-height: 60px;
      color: black;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:hover {
        background-color: #8c50ff1a;
      }

      div {
        font-size: 12px;
        text-align: center;
        &:first-child {
          display: flex;
          align-items: center;
          text-align: left;
          padding: 0 5px;
          box-sizing: border-box;
        }
        &:last-child {
          flex: 1;
        }
      }
    }
  }

  .operate_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .custom_snapline {
    stroke: red !important;
    stroke-width: 1 !important; /* 线条宽度 */
  }
  // .x6-widget-snapline-vertical,
  // .x6-widget-snapline-horizontal {
  //   stroke: #ff0000;
  //   stroke-width: 1px;
  // }
}
</style>
