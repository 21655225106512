<template>
  <div class="center_box">
    <div class="left_box">
      <p style="margin-top:10px;margin-bottom:20px;">筛选器</p>
      <div class="p_box" :class="{ active: listType==='未完成' }" @click="workListChange('未完成')">
        <p class="p_list">
          我未完成的 ({{listCount.nofinishListCount}})
        </p>
      </div>
      <div class="p_box" :class="{ active: listType==='分配的' }" @click="workListChange('分配的')">
        <p class="p_list">
          分配给我的 ({{listCount.processListCount}})
        </p>
      </div>
      <div class="p_box" :class="{ active: listType==='创建的' }" @click="workListChange('创建的')">
        <p class="p_list">
          我创建的 ({{listCount.createListCount}})
        </p>
      </div>
      <div class="p_box" :class="{ active: listType==='关注的' }" @click="workListChange('关注的')">
        <p class="p_list">
          我关注的 ({{listCount.starListCount}})
        </p>
      </div>
      <div class="p_box" :class="{ active: listType==='审核的' }" @click="workListChange('审核的')">
        <el-badge is-dot class="item" v-if="listCount.reviewListCount>0">
          <p class="p_list">
            需我审核的 ({{listCount.reviewListCount}})
          </p>
        </el-badge>
        <p class="p_list" v-else>
          需我审核的 ({{listCount.reviewListCount}})
        </p>
      </div>
      <div class="p_box" :class="{ active: listType==='全部' }" @click="workListChange('全部')">
        <p class="p_list">
          全部任务
        </p>
      </div>
    </div>
    <div class="right_box">
      <div class="header">
        <el-form size="mini" style="width:110vh;height:30px;margin-left:20px;">
          <el-row>
            <el-col :span="5" style="margin-right: 10px;">
              <el-form-item label="项目">
                <el-select v-model="projectValue" clearable placeholder="请选择" style="width:16vh;" size="mini">
                  <div style="width:100%;display:flex;align-items:center;justify-content:center;margin-bottom:5px;">
                    <el-button icon="el-icon-plus" @click="openCreateProjectDialog">新建项目</el-button>
                  </div>
                  <el-option
                    v-for="item in projectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                  
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-right: 10px;">
              <el-form-item label="状态类型">
                <el-select v-model="stateValue" clearable placeholder="请选择" style="width:16vh;" size="mini">
                  <el-option
                    v-for="item in stateOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="处理人">
                <template>
                  <el-select
                    v-model="puserValue"
                    filterable
                    remote
                    clearable
                    reserve-keyword
                    placeholder="请输入关键词"
                    :remote-method="remoteMethod"
                    :loading="loading"
                    style="width:16vh;" size="mini">
                    <el-option
                      v-for="item in puserOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item style="display:flex;align-items: center;">
                <el-dropdown @command="handleCommand($event)">
                  <span class="el-dropdown-link">
                    {{slotMethod}}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="time">按创建时间</el-dropdown-item>
                    <el-dropdown-item command="level">按优先级</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                
              </el-form-item>
              
            </el-col>
            <el-col :span="1">
              <el-form-item>
                <img
                  fit="contain"
                  style="width: 20px; height:18px;margin-left: 10px;margin-top: 5px;"
                  :src="require('@/assets/work_images/reverseorder.png')"
                  @click="changeSort"
                />
              </el-form-item>
              
            </el-col>
          </el-row>
        </el-form>
        <div style="width:270px;">
          <el-input
            placeholder="请输入内容搜索"
            suffix-icon="el-icon-search"
            v-model="searchValue"
            style="width:150px;margin-right:2vh;"
            size="mini">
          </el-input>
          <el-button type="primary" size="mini" @click="addNewWork">创建任务</el-button>
        </div>
        </div>
        <div class="main">
          <div class="table_body_box" :loading="loading1">
            <!-- <el-scrollbar style="height: 100%"> -->
              <template v-if="workListData.length > 0">
                <el-table
                  :data="workListData"
                  style="width: 96%; margin: 3vh auto;" height="100%">
                    <el-table-column type="index" label="ID" width="60" show-header>
                    </el-table-column>
                    <el-table-column
                      min-width="240"
                      prop="textData"
                      label="标题任务/正文"
                    >
                      <template slot-scope="{ row }">
                        <div style="display: flex; align-items: center">
                          <span style="font-size: 12px">[{{ row.title }}]</span>
                          <el-tooltip effect="dark" :content="row.textData" placement="top">
                            <span style="font-size: 12px; overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              max-width: 200px;">{{ row.textData }}</span>
                          </el-tooltip>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column type="expand">
                      <template slot-scope="scope">
                        <div class="expand_box">
                          <div v-html="scope.row.jsonData" class="content_box"></div>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column prop="workLevel" label="优先级" width="80">
                      <template slot-scope="{ row }">
                        <div style="display: flex; align-items: center">
                          <el-select 
                            :value="row.workLevel" 
                            placeholder="请选择" 
                            style="width:16vh;"
                            @change="changeLevel(row,$event)"
                            size="mini">
                            <el-option
                              v-for="item in levelOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="workState" label="状态" width="105">
                      <template slot-scope="{ row }">
                        <div style="display: flex; align-items: center">
                          <el-tag :type="getTagType(row)" size="mini">{{
                            getWorkStateData(row.workState)
                          }}</el-tag>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="puserName" label="处理人" width="120">
                      <template slot-scope="{ row }">
                        <div style="display: flex; align-items: center">
                          <el-select
                            v-model="row.puserName"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请输入关键词"
                            :remote-method="remoteMethod"
                            :loading="loading"
                            style="width:16vh;" size="mini">
                            <el-option
                              v-for="item in puserOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" width="80">
                    </el-table-column>
                    <el-table-column prop="projectName" label="所属项目" width="90">
                    </el-table-column>
                    <el-table-column prop="isStar" label="关注" width="50">
                      <template slot-scope="{ row }">
                        <div style="display: flex; align-items: center">
                          <img
                            :src="require('@/assets/work_images/star.png')"
                            style="width: 21px; margin-right: 6px"
                            @click="changeStar(row)"
                            v-if="!row.isStar"
                          />
                          <img
                            :src="require('@/assets/work_images/staring.png')"
                            style="width: 21px; margin-right: 6px"
                            @click="changeStar(row)"
                            v-else
                          />
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="{ row }">
                        <div style="display: flex; align-items: center">
                          <el-button
                            v-if="listType==='审核的'"
                            type="text"
                            style="color:#8896B3"
                            icon="el-icon-edit"
                            @click="pushReview(row.id)"
                            >审核</el-button
                          >
                          <el-button
                          v-if="listType==='分配的'"
                            type="text"
                            style="color:#8896B3"
                            icon="el-icon-edit"
                            @click="pushProcess(row.id)"
                            >处理</el-button
                          >
                          <el-button
                            type="text"
                            style="color:red"
                            icon="el-icon-delete"
                            @click="deleteWorkManage(row.id)"
                            >删除</el-button
                          >
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
              </template>
            <!-- </el-scrollbar> -->
          </div>

          <el-pagination
            style="margin: 20px; text-align: right"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryFilter.pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryFilter.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="queryFilter.total"
          >
          </el-pagination>
        </div>
    </div>
    <el-dialog
      title="新建项目"
      :visible.sync="createProjectState"
      width="30%">
      <el-input v-model="newProjectName" placeholder="新建项目名"></el-input>
      <el-checkbox v-model="isChecked">创建后自动选择</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createProjectState = false">取 消</el-button>
        <el-button type="primary" @click="addNewProject()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { 
  getWorkList,
  getWorkProjectList,
  createNewProject, 
  EditWork,
  DeleteWork,
  getWorkListCount
} from '../../api/workManage'
import { getUserOptions } from '../../api/user'
  export default {
    name:'',
    props:[''],
    data () {
      return {
        loading: false,
        loading1: false,
        queryFilter: {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
          projectId: null,
          workState: null,
          puserId: null,
          selectType: 0,
          sortType: 0, // 0为默认排序 从高到低 从近到远
          keyWorlds: '',
          selectKeyWorlds: '',
        },
        workListData: [],
        stateOptions: [{
          value: 1,
          label: '未开始'
        }, {
          value: 2,
          label: '处理中'
        }, {
          value: 3,
          label: '待审核'
        }, {
          value: 4,
          label: '已完成'
        }],
        levelOptions: [{
          value: 1,
          label: '低'
        }, {
          value: 2,
          label: '中'
        }, {
          value: 3,
          label: '高'
        }],
        puserOptions: [],
        projectOptions: [],
        stateValue: '',
        puserValue: '',
        projectValue: '',
        newProjectName: '',
        isChecked: false,
        slotMethod: '按创建时间',
        createProjectState: false,
        searchValue: '',
        listType: '全部',
        listCount: [],
        ascSort: false,
        levelOldValue: null,
      };
    },
    created(){
      this.getWorkProjectOptions();
      this.getWorkListData();
      this.getListCount();
      this.oldSessionData();
    },

    components: {},

    computed: {},

    beforeMount() {},

    mounted() {},

    methods: {
      oldSessionData(){
        let puserLabel = sessionStorage.getItem("puserLabel")
        let puserValue = sessionStorage.getItem("puserValue")
        let pvalue = sessionStorage.getItem("projectValue");
        console.log(pvalue)
        if(pvalue!=null&&pvalue!=""){
          this.projectValue = parseInt(pvalue)
        }
        if(puserLabel!=null&&puserValue!=null&&puserLabel!=""&&puserValue!=""){
          this.puserValue = puserValue;
          let puserData = {};
          puserData.value = puserValue;
          puserData.label = puserLabel;
          this.puserOptions.push(puserData)
        }
      },
      // 分页大小更改
      handleSizeChange(value) {
        this.queryFilter.pageSize = value
        this.getWorkListData()
      },
      // 当前索引更改
      handleCurrentChange(val) {
        this.queryFilter.pageIndex = val
        this.getWorkListData()
      },
      getWorkStateData(state){
        console.log(state)
        let stateData = this.stateOptions.filter(u=>u.value==state)[0];
        console.log(stateData)
        if(stateData&&stateData!=null){
          return stateData.label
        }
        
      },
      getTagType(row) {
        console.log(row.workState)
        if (row.workState == 1) {
          return 'primary'
        }

        if (row.workState == 2) {
          return 'danger'
        }

        if (row.workState == 3) {
          return 'warning'
        }

        if (row.workState == 4) {
          return 'success'
        }
      },
      async getListCount(){
        let { data } = await getWorkListCount();
        console.log("getWorkListCount", data)
        this.listCount = data;
      },
      async getWorkListData(){
        this.loading1 = true;
        this.listType = '全部';
        this.workListData = [];
        let { data } = await getWorkList(this.queryFilter);
        console.log('getWorkListData', data)
        console.log(data)
        this.workListData = data.data
        console.log(this.workListData)
        this.queryFilter.total = data.total
        this.loading1 = false;
      },
      async getWorkProjectOptions(){
        this.projectOptions = [];
        let { data } = await getWorkProjectList();
        // console.log(data)
        data.forEach(element => {
          let projectData = {};
          projectData.value = element.id;
          projectData.label = element.projectName;
          this.projectOptions.push(projectData);
        });
        if(this.isChecked){
          this.projectValue = this.projectOptions[this.projectOptions.length-1].value;
          // console.log(this.projectValue)
        }
      },
      async workListChange(selectdata){
        console.log(selectdata)
        this.queryFilter.SelectKeyWorlds = selectdata;
        if(selectdata=='全部'){
          this.queryFilter.SelectKeyWorlds = '';
          console.log(this.workListData)
        }
        this.getWorkListData()
        this.listType = selectdata;
        
      },
      async getOptions(query){
        this.puserOptions = [];
        let key = query;
        let { data } = await getUserOptions(key);
        // console.log('getOptions', data)
        data.forEach(element => {
          let userData = {};
          userData.value = element.id;
          userData.label = element.userName;
          this.puserOptions.push(userData);
        });
        // console.log('this.puserOptions', this.puserOptions)
      },
      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          this.getOptions(query).then(()=>{
            this.loading = false;
            this.options = this.puserOptions.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          })
        } else {
          this.options = [];
        }
      },
      handleCommand($event) {
        console.log($event)
        if ($event == 'time') {
          this.slotMethod = '按创建时间';
          this.workListData = this.workListData.sort((a ,b)=>new Date(b.createTime)-new Date(a.createTime));
        }
        else {
          this.slotMethod = '按优先级';
          this.workListData.sort((a, b) => {
              const dateComparison = b.workLevel - a.workLevel;
              // 如果任务等级相同，则按创建时间排序
              if (dateComparison === 0) {
                  return new Date(b.createTime)-new Date(a.createTime);
              }
              return dateComparison;
          });
        }
      },
      changeSort(){
        this.ascSort = !this.ascSort;
        if(this.slotMethod=="按创建时间"){
          if(this.ascSort){
            // 升序
            this.workListData = this.workListData.sort((a ,b)=>new Date(a.createTime)-new Date(b.createTime));
          } else {
            // 降序
            this.workListData = this.workListData.sort((a ,b)=>new Date(b.createTime)-new Date(a.createTime));
          }
        } else{
          if(this.ascSort){
            // 升序
            this.workListData.sort((a, b) => {
              const dateComparison = a.workLevel - b.workLevel;
              // 如果任务等级相同，则按创建时间排序
              if (dateComparison === 0) {
                  return new Date(a.createTime)-new Date(b.createTime);
              }
              return dateComparison;
            });
          } else {
            // 降序
            this.workListData.sort((a, b) => {
              const dateComparison = b.workLevel - a.workLevel;
              // 如果任务等级相同，则按创建时间排序
              if (dateComparison === 0) {
                  return new Date(b.createTime)-new Date(a.createTime);
              }
              return dateComparison;
            });
          }
        }
      },
      addNewWork(){
        this.$router.push({ path: '/workManageMent/addNewWork' })
      },
      async addNewProject(){
        console.log('创建新项目', this.newProjectName)
        if(this.newProjectName==''){
          this.$message.error('新项目的名字不能为空')
          return
        }
        let data = {
          projectName: this.newProjectName
        }
        await createNewProject(data)
        this.getWorkProjectOptions();
        this.createProjectState = false;
      },
      openCreateProjectDialog(){
        this.isChecked = false;
        this.newProjectName = '';
        this.createProjectState = true;
      },
      changeProject(item){
        console.log(item)
      },
      pushReview(id){
        this.$router.push({ path: '/workManageMent/reviewWork/' + id })
      },
      pushProcess(id){
        this.$router.push({ path: '/workManageMent/processWork/' + id })
      },
      // 关注状态改变
      async changeStar(data){
        console.log(data)
        if(data.isStar){
          this.$confirm('是否取消关注当前工作任务?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info',
          })
          .then(async () => {
            data.isStar = !data.isStar;
            let result = await EditWork(data);
            console.log(result)
            if(result.code!=200){
              data.isStar = !data.isStar;
            }
            if(!data.isStar){
              this.$message({
                type: 'success',
                message: '取消关注成功!',
              })
            }
          })
          .catch((error) => {
            this.$message({
              type: 'info',
              message: '取消关注失败',
            })
          })
        }
        else{
          this.$confirm('是否关注当前工作任务?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info',
          })
          .then(async () => {
            data.isStar = !data.isStar;
            let result = await EditWork(data);
            console.log(result)
            if(result.code!=200){
              data.isStar = !data.isStar;
            }
            if(data.isStar){
              this.$message({
                type: 'success',
                message: '关注成功!',
              })
            }
          })
          .catch((error) => {
            this.$message({
              type: 'info',
              message: '关注失败',
            })
          })
        }
        
      },
      async changeLevel(data, newLevel){
        console.log("changeLevel", data.workLevel, newLevel)
        var newData = {...data};
        newData.workLevel = newLevel;
        console.log("newData", newData)
        this.$confirm('是否确认修改当前工作任务的优先级?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        })
        .then(async () => {
          let result = await EditWork(newData);
          if(result.code==200){
            this.$message({
            type: 'success',
            message: '修改工作任务优先级成功!',
          })
          }
          this.getWorkListData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '修改工作任务优先级失败',
          })
        })
      },
      deleteWorkManage(id){
        // console.log(id)
        this.$confirm('是否确认删除当前工作任务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        })
        .then(async () => {
          await DeleteWork(id);
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getWorkListData()
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
      },
    },

    watch: {
      'row.workLevel'(newVal, oldVal) {
        console.log('workLevel changed from', oldVal, 'to', newVal);
      },
      projectValue(newValue,oldValue){
        // console.log('newValue', newValue)
        if(newValue!=null){
          sessionStorage.setItem("projectValue", newValue);
          this.queryFilter.projectId = newValue;
          this.getWorkListData()
        }
      },
      stateValue(newValue,oldValue){
        // console.log('newValue', newValue)
        this.queryFilter.workState = newValue;
        this.getWorkListData()
      },
      puserValue(newValue,oldValue){
        // console.log('newValue', newValue)
        sessionStorage.setItem("puserValue", newValue);
        const selectedItem = this.puserOptions.find(option => option.value === newValue);
        if (selectedItem) {
            console.log('Selected Label:', selectedItem.label);
            sessionStorage.setItem("puserLabel", selectedItem.label);
        } else{
          sessionStorage.setItem("puserLabel", "");
        }
        this.queryFilter.puserId = newValue;
        this.getWorkListData()
      },
      searchValue(newValue,oldValue){
        // console.log('newValue', newValue)
        this.queryFilter.keyWorlds = newValue;
        this.getWorkListData()
      }
    }
  }

</script>
<style lang='scss' scoped>
@import '@/styles/dragorder.scss';
.center_box{
  height: 100%;
  display:flex;
  // background-color: red;
  .left_box{
    // width:200px;
    width: 21vh;
    height:93%;
    border-radius: 10px;
    background-color:#ffffff;
    margin-right:20px;
    padding: 20px;
  }
  .right_box{
    height:93%;
    width: 100%;
    .header{
      width:100%;
      height:10%;
      border-radius: 10px;
      background-color:#ffffff;
      margin-bottom:20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .main{
      // width:150vh;
      width: 100%;
      height:93%;
      border-radius: 10px;
      background-color:#ffffff;
    }
  }
}
.p_box {
  // .hover_show {
  //   visibility: hidden;
  // }

  &.active {
    background: rgba(140, 80, 255, 0.12);
    color: #8c50ff;
    i {
      // transform: rotate(90deg);
      visibility: visible;
    }
  }

  &:hover {
    background: rgba(140, 80, 255, 0.12);
    color: #8c50ff;
    cursor:pointer;
    transform: scale(1.05);
  }

  // &:hover .hover_show {
  //   visibility: visible;
  // }

  display: flex;
  align-items: center;
  // padding: 2px 20px;

  .p_list {
    flex: 1;
    // padding: 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 9px;
    margin-bottom: 9px;
    font-size: 14px;
  }
}

.table_header_box {
  height: 9%;
  color: black;
  // background: rgba(58, 132, 255, 0.05);
  display: flex;
  align-items: center;
  div {
    text-align: center;
    &:last-child {
      flex: 1;
    }
  }
}
.table_body_box{
  height: 87%;
}
.content_box ::v-deep img{
  // width: 100px;
  height: 100px;
}
.el-dropdown-link{
  display: flex;
  align-items: center;
}
::v-deep .el-table thead th .cell {
  font-size: 14px; /* 设置字体大小为 16 像素 */
  font-weight:normal;
  color: #333;     /* 设置字体颜色为蓝色 */
}
::v-deep .el-badge__content.is-fixed.is-dot{
  left: -20px !important;
  top: 20px !important;
} 
::v-deep .el-badge__content.is-dot{
  height: 6px !important;
  width:6px !important;
}

.custom-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}
</style>